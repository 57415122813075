import { useEffect, useState } from "react";

import { makeStyles } from "tss-react/mui";
import { Add } from "@mui/icons-material";
import { Box, Button, Drawer, Toolbar, Typography } from "@mui/material";
// import Table from '../../components/Seller/SellerTable';

import { ToastContainer, toast } from "react-toastify";

import _ from "lodash";
import WaitlistTable from "../../components/Waitlist/WaitlistTable";
import { getAllWaitlistUser } from "../../apis/waitlist";
import { EditWaitlistStatus } from "../../components/Waitlist/EditWaitlist";

const useStyles = makeStyles()((theme) => ({
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
}));

export const Waitlist = () => {
  const { classes } = useStyles();
  const [editOpen, setEditOpen] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [rows, setRows] = useState([]);
	const [waitlistUser, setWaitlistUser] = useState();
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsCount, setRowsCount] = useState(10);
	const [count, setCount] = useState(0);

//   useEffect(() => {
//     const handleData = async () => {
//       const data = await getAllWaitlistUser();
//       console.log("TTTTTTTTTTTTTTTTTTTTTTTTTT", data.results);
//     };
//     handleData();
//   }, []);

useEffect(() => {
	setLoading(true);
	_.debounce(async () => {
		const data = await getAllWaitlistUser(rowsCount);
		setRows(data.results);
		console.log("aaaaaaaaaaaaaaaaaaaaaaaaaa",data);
	
		setCount(data.count);
	}, 500)();
	setLoading(false);
}, [])

const handleEditorClose= async()=>{
	setEditOpen(false)
	const data = await getAllWaitlistUser(rowsCount,page+1);

	setRows(data.results);
}

const notify = () => toast.error(`Some unexpected error occured`, {
	position: "bottom-right",
	autoClose: 2500,
	hideProgressBar: false,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	progress: undefined,
});

const handleAdd = () => {
	setIsEdit(false);
	
	setEditOpen(true);
}

const handleEdit= (id) => {
	setIsEdit(true)
	setWaitlistUser(rows.find(row=>row.id===id));
	setEditOpen(true);
}



const handlePage = async (_, i) => {
	const data = await getAllWaitlistUser(rowsCount, i + 1);
	setRows(data.results);
	setPage(i);
};

const handleRowsCount = async (i) => {
	setRowsCount(i);
	setLoading(true);
	const data = await getAllWaitlistUser(i);
	setRows(data.results);
	setLoading(false);
};
  return (
    <>
      <Toolbar>
        <Typography className={classes.title} variant="h4" noWrap />
      </Toolbar>
      <ToastContainer />

      <Box sx={{ px: 5 }}>
        <WaitlistTable   page={page} rowsCount={rowsCount} handlePage={handlePage} handleRowsCount={handleRowsCount} count={count} rows={rows} loading={loading} handleEdit={handleEdit} />
        <Drawer anchor='right' open={editOpen} onClose={handleEditorClose}>
				<EditWaitlistStatus   setEditOpen={setEditOpen} waitlistUser={waitlistUser}  setRows={setRows} />
			</Drawer>
      </Box>
    </>
  );
};
