import { Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import { UpdateCuration } from "../../apis/Curation";

function PinToTopSwitch({ value, curation }) {
 
  const [pinToTop, setPinToTop] = useState(curation.pin_to_top)
  const handleClick = async () => {
   
  curation = { ...curation, pin_to_top: !pinToTop };
    await UpdateCuration(
      { title: curation.title, pin_to_top: !pinToTop },
      curation.id
    );
    setPinToTop(!pinToTop)   
  };
 

  useEffect(() => {
    setPinToTop(curation.pin_to_top)
  
  }, [curation])
  
  return (
    <Switch
      checked={pinToTop}
      onChange={handleClick}
      inputProps={{
        "aria-label": "controlled",
      }}
    />
  );
}

export default PinToTopSwitch;
