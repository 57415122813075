import {
	Autocomplete,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	createFilterOptions,
} from "@mui/material";
import React, { Fragment, useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { createAesthetic, fetchAestheticList } from "../../apis/Playoff";

const filter = createFilterOptions();

export const AestheticInput = ({ field }) => {
	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm();

	const [value, setValue] = useState(null);
	const [open, setOpen] = useState(false);
	const [dialogOpen, toggleOpen] = useState(false);
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [title, setTitle] = useState("");

	const handleRegistration = async (data) => {
		setLoading(true);
		createAesthetic(data)
			.then((res) => {
				setValue(res);
			})
			.finally(() => {
				handleClose();
				setLoading(false);
			});
	};

	const handleClose = () => {
		setTitle("");
		toggleOpen(false);
	};

	const handleInputChange = (newInputValue) => {
		setLoading(true);
		// Fetch data from your API based on the new input value
		fetchAestheticList(newInputValue)
			.then((data) => {
				setOptions(data);
			})
			.catch((error) => {
				console.error("Error fetching data:", error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	React.useEffect(() => {
		if (!open) {
			setOptions([]);
		} else {
			handleInputChange("");
		}
	}, [open]);
	const onSubmit = (data) => {
		console.log(data);
	};

	return (
		<Fragment>
			<Autocomplete
				{...field}
				onChange={(event, newValue) => {
					console.log(newValue);
					if (typeof newValue === "string") {
						// timeout to avoid instant validation of the dialog's form.
						setTimeout(() => {
							toggleOpen(true);
							setTitle(newValue);
						});
					} else if (newValue && newValue.inputValue) {
						toggleOpen(true);
						setTitle(newValue.inputValue);
					} else {
						field.onChange(newValue);
					}
				}}
				sx={{
					"& .MuiAutocomplete-inputRoot": {
						padding: 0.75,
					},
				}}
				open={open}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={() => {
					setOpen(false);
				}}
				isOptionEqualToValue={(option, value) =>
					option.title === value.title
				}
				getOptionLabel={(option) => option.title}
				options={options}
				loading={loading}
				filterOptions={(options, params) => {
					const filtered = filter(options, params);
					if (filtered.length <= 0 && params.inputValue !== "") {
						filtered.push({
							inputValue: params.inputValue,
							title: `Add "${params.inputValue}"`,
						});
					}
					return filtered;
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						label="Aesthetic"
						InputProps={{
							...params.InputProps,
							onChange: (event) => {
								handleInputChange(event.target.value);
							},
							endAdornment: (
								<React.Fragment>
									{loading ? (
										<CircularProgress
											color="inherit"
											size={20}
										/>
									) : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							),
						}}
					/>
				)}
			/>

			{/* <Autocomplete
				id="asynchronous-demo"
				
				onChange={(event, newValue) => {
					console.log(newValue);
					if (typeof newValue === "string") {
						// timeout to avoid instant validation of the dialog's form.
						setTimeout(() => {
							toggleOpen(true);
							setTitle(newValue);
						});
					} else if (newValue && newValue.inputValue) {
						toggleOpen(true);
						setTitle(newValue.inputValue);
					} else {
						setValue(newValue);
					}
				}}
				sx={{ "& .MuiAutocomplete-inputRoot": { padding: 0.75 } }}
				open={open}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={() => {
					setOpen(false);
				}}
				isOptionEqualToValue={(option, value) =>
					option.title === value.title
				}
				getOptionLabel={(option) => option.title}
				options={options}
				loading={loading}
				filterOptions={(options, params) => {
					const filtered = filter(options, params);
					if (filtered.length <= 0 && params.inputValue !== "") {
						filtered.push({
							inputValue: params.inputValue,
							title: `Add "${params.inputValue}"`,
						});
					}
					return filtered;
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						onChange={(event) =>
							handleInputChange(event.target.value)
						}
						label="Aesthetic"
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loading ? (
										<CircularProgress
											color="inherit"
											size={20}
										/>
									) : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							),
						}}
					/>
				)}
			/> */}
			<Dialog
				open={dialogOpen}
				// onClose={() => {
				// 	toggleOpen(false);
				// }}
				fullWidth
				maxWidth={"xs"}
			>
				<form onSubmit={handleSubmit(handleRegistration)}>
					{/* <Box
						// noValidate
						// component="form"
						sx={{
							//   display: 'flex',
							//   flexDirection: 'column',
							m: "auto",
							width: "fit-content",
						}}
					> */}
					<DialogTitle>Add a new </DialogTitle>
					<DialogContent>
						<TextField
							fullWidth
							autoFocus
							margin="dense"
							value={title}
							label="Title"
							type="text"
							variant="outlined"
							{...register("title", {
								required: "title is required",
								onChange: (e) => {
									setTitle(e.target.value);
								},
							})}
							error={Boolean(errors.title)}
							helperText={errors.title?.message}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose}>Cancel</Button>
						<Button
							color="primary"
							variant="contained"
							type="submit"
						>
							Add
						</Button>
					</DialogActions>
					{/* </Box> */}
				</form>
			</Dialog>
		</Fragment>
	);
};
