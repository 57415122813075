import React, { useEffect, useState } from "react";
import { Close, Delete, Upload } from "@mui/icons-material";
import {
	Avatar,
	Box,
	Button,
	FormControl,
	FormHelperText,
	IconButton,
	TextField,
	Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";

import { ToastContainer, toast } from "react-toastify";
import { Create, Update } from "../../apis/Prize";

export const AddPrizeDrawer = ({ title, setEditOpen, prize, setPrize }) => {
	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm();
	const [name, setName] = useState();
	const [description, setDescription] = useState();
	const [url, setUrl] = useState();
	const [image, setImage] = useState();

	useEffect(() => {
		if (prize) {
			setName(prize.title);
			setDescription(prize.description);
			setUrl(prize.url)
			setImage(prize.image);
			setValue("title", prize.title);
			setValue("description", prize.description);
			setValue("url",prize.url)
		}
	}, [prize]);
	const handleChange = (event) => {
		console.log("fe");
		setName(event.target.value);
	};

	const handleOnChange = (event) => {
		const newImage = event.target?.files?.[0];

		if (newImage) {
			setImage(URL.createObjectURL(newImage));
		}
	};
	const onSubmit = async (data) => {
		// let image = URL.createObjectURL(data.file[0]);

		let formData = new FormData();
		console.log("====================================");
		console.log(data);
		console.log("====================================");

		for (let i in data) {
			if (i === "file") {
				if (data[i].length) {
					formData.append("image", data[i][0]);
				}
			} else formData.append(i, data[i]);
		}

		let res = prize
			? await Update(formData, prize.id)
			: await Create(formData);

		if (res.success) {
			setPrize(res);
			setEditOpen(false);
		}
		// data.image = image;
	};

	const notify = (m) =>
		toast.error(m, {
			position: "top-right",
			autoClose: 2500,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});

	// const handleURL = async (e) => {

	// 	setUrl(e.target.value);
	// 	if(e.target.value){
	// 	const data = await getProductPreview(e.target.value);
	// 	setProductPreview(data.data);
	// 	}
	// }

	return (
		<div
			style={{
				width: 600,
				padding: 5,
				height: "100vh",
				overflowY: "scroll",
			}}
		>
			<IconButton
				style={{ position: "fixed" }}
				onClick={() => setEditOpen(false)}
			>
				<Close />
			</IconButton>
			<ToastContainer />
			<div style={{ padding: "45px 55px 0px" }}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Box display="flex" justifyContent="space-between">
						<Typography
							variant="h6"
							gutterBottom
						>{`Give Away`}</Typography>
						<Button
							type="submit"
							// onClick={handleSave}
							color="primary"
							variant="contained"
						>
							{prize ? "Update" : "Add"}
						</Button>
					</Box>

					<Box my={3}>
						<TextField
							fullWidth
							name="title"
							// style={{ width: "100%" }}
							label="Enter Give Away Name"
							variant="outlined"
							value={name}
							error={Boolean(errors.title)}
							helperText={errors.title?.message}
							{...register("title", {
								required: "Enter Prize title",
							})}
							onChange={handleChange}
						/>
					</Box>
					<Box mb={3}>
						<TextField
							fullWidth
							name="description"
							multiline
							value={description}
							rows={4}
							// style={{ width: "100%" }}
							label="Enter description"
							variant="outlined"
							{...register("description", {
								required: "Enter description",
							})}
							onChange={(e) => {
								setDescription(e.target.value);
							}}
							error={Boolean(errors.description)}
							helperText={errors.description?.message}
						/>
					</Box>
					<Box mb={3}>
						<TextField
							fullWidth
							name="url"
							multiline
							value={url}
							
							// style={{ width: "100%" }}
							label="Enter URL"
							variant="outlined"
							{...register("url", {
								required: "Enter url",
							})}
							onChange={(e) => {
								setUrl(e.target.value);
							}}
							error={Boolean(errors.url)}
							helperText={errors.url?.message}
						/>
					</Box>

					{/* <Box my={3}>
						<TextField
							fullWidth
							name="website"
							// style={{ width: "100%" }}
							label="Enter Website url"
							variant="outlined"
							{...register("website", {
								required: "Enter seller website url",
								pattern: {
									value: /^((http|https):\/\/)?([A-z]+)\.([A-z]{2,})/,
									message: "Please enter a valid url",
								},
							})}
							error={Boolean(errors.name)}
							helperText={errors.name?.message}
						/>
					</Box> */}
					<Box
						my={3}

						// alignItems="center"
						// minHeight="100vh"
					>
						{image && (
							<Avatar
								variant="square"
								src={image}
								sx={{
									width: 150,
									height: 150,
									objectFit: "fill",
								}}
							/>
							// <Box
							// 	component={"img"}
							// 	alt="text"
							// 	src={image}
							// 	sx={{
							// 		// height: 233,
							// 		width: 350,
							// 		// maxHeight: { xs: 233, md: 167 },
							// 		maxWidth: { xs: 350, md: 250 },
							// 	}}
							// />
						)}

						<FormControl error={errors.file}>
							<Button
								variant="contained"
								component="label"
								onChange={handleOnChange}
							>
								{!image ? "Upload " : "Change "}image
								<input
									hidden
									accept="image/*"
									id="file-upload"
									type="file"
									{...register("file", {
										required: {
											value: image === null,
											message: "Image required",
										},
									})}
									error={Boolean(errors.file)}
								/>
							</Button>
							{errors.file && (
								<FormHelperText>
									{errors.file?.message}
								</FormHelperText>
							)}
						</FormControl>
					</Box>
				</form>
			</div>
		</div>
	);
};
