import {
	ContentCopyRounded,
	DeleteForever,
	EditOutlined,
	RateReview,
	Star,
} from "@mui/icons-material";
import {
	Avatar,
	Box,
	Checkbox,
	FormControl,
	IconButton,
	InputLabel,
	ListItemText,
	MenuItem,
	Rating,
	Select,
	Stack,
	Switch,
	Typography,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { Fragment, useEffect, useState } from "react";

import { getAllActivePlayoffs, getAllCurations } from "../../apis/Curation";
import { getAllUsers } from "../../apis/user";
import StatusSwitch from "./StatusSwitch";
import DeleteModal from "./DeleteModal";
import { axiosInstance } from "../../config/axios";
import { values } from "lodash";
import CopyToClipboard from "react-copy-to-clipboard";
import { notify } from "../Playoff/PlayListTable";
import PinToTopSwitch from "./PinToTopSwitch";

export const CurationListTable = ({ handleDrawerOpen }) => {
	const [data, setData] = useState([]);
	const [page, setPage] = useState(0);
	const [filters, setFilters] = useState({});
	const [playoffs, setPlayoffs] = useState([]);
	const [owner, setOwner] = useState({});
	const [rowsCount, setRowsCount] = useState(10);
	const [count, setCount] = useState(0);
	const [sortingValues, setSortingValues] = useState(null);
	const [curation, setCuration] = useState(null);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		getAllCurations(rowsCount, page + 1, filters, sortingValues).then(
			(res) => {
				setData(res.results);
				setCount(res.count);
			}
		);
	}, [page, rowsCount, filters, sortingValues]);
	useEffect(() => {
		getAllActivePlayoffs().then((res) => {
			setPlayoffs(res);
		});
		getAllUsers().then((res) => {
			setOwner(res.results);
		});
	}, []);

	const handleDelete = async () => {
		await axiosInstance.delete(`/communitylist/${curation?.id}`);
		getAllCurations(rowsCount, page + 1, filters, sortingValues).then(
			(res) => {
				setData(res.results);
				setCount(res.count);
			}
		);
		setOpen(false);
	};

	const columns = [
		{
			name: "id",
			label: "Id",
			options: {
				filter: false,
				sort: true,
				setCellProps: () => ({ sx: { textAlign: "right" } }),
				// customBodyRender: (value, tableMeta, updateValue) => {
				// 	// console.log("yes", tableMeta);
				// 	return tableMeta.rowIndex + 1;
				// },
			},
		},
		{
			name: "slug",
			label: "Slug",
			options: {
				filter: false,
				sort: true,
				// setCellProps: () => ({ sx: { textAlign: "right" } }),
				// customBodyRender: (value, tableMeta, updateValue) => {
				// 	// console.log("yes", tableMeta);
				// 	return tableMeta.rowIndex + 1;
				// },
				customBodyRender: (value) => {
					return (
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
							}}
						>
							<Typography variant="p" component={"p"}>
								{value}
							</Typography>

							<CopyToClipboard
								text={value}
								onCopy={() => notify("copied")}
							>
								<IconButton size="small" color="primary">
									<ContentCopyRounded />
								</IconButton>
							</CopyToClipboard>
						</Box>
					);
				},
			},
		},
		{
			name: "title",
			label: "Title",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value) => {
					return (
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
							}}
						>
							<Typography variant="p" component={"p"}>
								{value}
							</Typography>

							<CopyToClipboard
								text={value}
								onCopy={() => notify("copied")}
							>
								<IconButton size="small" color="primary">
									<ContentCopyRounded />
								</IconButton>
							</CopyToClipboard>
						</Box>
					);
				},
			},
		},
		{
			name: "image",
			label: "Collage",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					// console.log("yes", value);
					return (
						<Avatar
							alt="List Logo"
							variant="square"
							sx={{
								width: 150,
								height: 150,
								objectFit: "fill",
							}}
							src={value?.file}
						/>
					);
				},
			},
		},
		{
			name: "owner",
			label: "Curator",
			options: {
				filter: true,
				sort: true,
				filterType: "custom",
				// customFilterListOptions: {
				// 	update: (filterList, filterPos, index) => {
				// 		// console.log("update");
				// 		// console.log(filterList, filterPos, index);
				// 		return filterList;
				// 	},
				// },
				filterOptions: {
					logic: (playoff, filters, row) => {
						if (filters.length) return !filters.includes(playoff);
						return false;
					},
					display: (filterList, onChange, index, column) => {
						return (
							<FormControl fullWidth variant="standard">
								<InputLabel>Owner</InputLabel>
								<Select
									multiple
									value={filterList[index]}
									renderValue={(selected) =>
										selected.join(", ")
									}
									onChange={(event) => {
										filterList[index] = event.target.value;
										onChange(
											filterList[index],
											index,
											column
										);
									}}
								>
									{owner.map((owner) => (
										<MenuItem
											key={owner.id}
											value={owner.name}
										>
											<Checkbox
												checked={
													filterList[index].indexOf(
														owner.name
													) > -1
												}
											/>
											<ListItemText
												primary={owner.name}
											/>
										</MenuItem>
									))}
								</Select>
							</FormControl>
						);
					},
				},
				customBodyRender: (value, tableMeta, updateValue) => {
					// console.log("yes", value);
					return (
						<Stack direction={"row"} alignItems={"center"}>
							<Avatar
								alt="List Logo"
								// variant="square"
								// sx={{
								//     width: 150,
								//     height: 150,
								//     objectFit: "fill",
								// }}
								src={value.image?.file}
							/>{" "}
							<Typography variant="p" ml={1}>
								{" "}
								{value.name}
							</Typography>
						</Stack>
					);
				},
			},
		},
		{
			name: "tag_in_insta",
			label: "Tag in Insta",
			options: {
				filter: false,
				sort: false,
				// display: "true",
				// filterType: "custom",
				// // customFilterListOptions: {
				// // 	update: (filterList, filterPos, index) => {
				// // 		// console.log("update");
				// // 		// console.log(filterList, filterPos, index);
				// // 		return filterList;
				// // 	},
				// // },

				customBodyRender: (value, tableMeta, updateValue) => {
					// console.log("yes", value);
					return (
						<Switch
							checked={value}
							onChange={(event) => {
								event.preventDefault();
							}}
							inputProps={{
								"aria-label": "controlled",
							}}
						/>
					);
				},
			},
		},
		{
			name: "",
			label: "Insta Handle",
			options: {
				filter: false,
				sort: false,
				// display: "true",
				// filterType: "custom",
				// // customFilterListOptions: {
				// // 	update: (filterList, filterPos, index) => {
				// // 		// console.log("update");
				// // 		// console.log(filterList, filterPos, index);
				// // 		return filterList;
				// // 	},
				// // },

				customBodyRender: (value, tableMeta, updateValue) => {
					let row = data[tableMeta.rowIndex].owner;
					return row.insta_handle;
					// return <Switch
					// checked={value}
					// onChange={(event) => {
					// 	event.preventDefault();
					// }}
					// inputProps={{
					// 	"aria-label": "controlled",
					// }}
					// />
				},
			},
		},
		{
			name: "playoff",
			label: "Playoff",
			options: {
				filter: true,
				sort: true,
				display: "true",
				filterType: "custom",
				// customFilterListOptions: {
				// 	update: (filterList, filterPos, index) => {
				// 		// console.log("update");
				// 		// console.log(filterList, filterPos, index);
				// 		return filterList;
				// 	},
				// },
				filterOptions: {
					logic: (playoff, filters, row) => {
						if (filters.length) return !filters.includes(playoff);
						return false;
					},
					display: (filterList, onChange, index, column) => {
						return (
							<FormControl fullWidth variant="standard">
								<InputLabel>Playoff</InputLabel>
								<Select
									multiple
									value={filterList[index]}
									renderValue={(selected) =>
										selected.join(", ")
									}
									onChange={(event) => {
										filterList[index] = event.target.value;
										onChange(
											filterList[index],
											index,
											column
										);
									}}
								>
									{playoffs.map((name) => (
										<MenuItem
											key={name.id}
											value={name.title}
										>
											<Checkbox
												checked={
													filterList[index].indexOf(
														name.title
													) > -1
												}
											/>
											<ListItemText
												primary={name.title}
											/>
										</MenuItem>
									))}
								</Select>
							</FormControl>
						);
					},
				},
				customBodyRender: (value, tableMeta, updateValue) => {
					// console.log("yes", value);
					return value.title;
				},
			},
		},
		{
			name: "products",
			label: "Products",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					// console.log("yes", value);
					return value.length;
				},
			},
		},
		{
			name: "avg_rating",
			label: "Ratings",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<Stack
							direction={"row"}
							spacing={1}
							alignItems="center"
						>
							<Rating
								name="text-feedback"
								value={value.avg_rating / 2}
								readOnly
								// size="large"
								// precision={0.5}
								emptyIcon={
									<Star
										style={{ opacity: 0.55 }}
										fontSize="inherit"
									/>
								}
							/>
							<Typography
								variant="body"
								display="block"
								gutterBottom
								mb={0}
								// ml={1}
							>
								(
								{data[tableMeta.rowIndex].ratings
									? data[tableMeta.rowIndex].ratings
									: 0}
								)
							</Typography>
						</Stack>
					);
				},
			},
		},
		{
			name: "created_at",
			label: "Created At",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return new Date(value).toString().substring(4, 24);
				},
			},
		},
		{
			name: "active",
			label: "Hide/Show",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					let curation = data[tableMeta.rowIndex];
					return <StatusSwitch value={value} curation={curation} />;
				},
			},
		},
		{
			name: "pin_to_top",
			label: "Pin to Top",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					
					let curation = data[tableMeta.rowIndex];
					return <PinToTopSwitch value={curation.pin_to_top} curation={curation} />;
				},
			},
		},
		{
			name: "",
			label: "Action",
			options: {
				filter: false,
				sort: true,
				viewColumns: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<Box>
							<IconButton
								color="primary"
								href={`/w86zjruk6q/curation/${
									data[tableMeta.rowIndex].id
								}/edit/`}
							>
								<EditOutlined />
							</IconButton>
							<IconButton
								color="primary"
								onClick={() =>
									handleDrawerOpen(data[tableMeta.rowIndex])
								}
							>
								<RateReview />
							</IconButton>
							<IconButton
								color="secondary"
								onClick={() => {
									setCuration(data[tableMeta.rowIndex]);
									setOpen(true);
								}}
							>
								<DeleteForever />
							</IconButton>
							<CopyToClipboard
								text={`https://www.grape.town/curation/${
									data[tableMeta.rowIndex].slug
								}`}
								onCopy={() => notify("copied")}
							>
								<IconButton size="small" color="primary">
									<ContentCopyRounded />
								</IconButton>
							</CopyToClipboard>
						</Box>
					);
				},
			},
		},
	];

	const options = {
		jumpToPage: true,
		selectableRows: "none",
		download: false,
		filterType: "multiselect",
		serverSide: true,
		print: false,
		count: count,
		page: page,
		rowsPerPage: rowsCount,
		rowsPerPageOptions: [10, 25, 100],
		onChangePage(currentPage) {
			setPage(currentPage);
		},
		onChangeRowsPerPage(numberOfRows) {
			setRowsCount(numberOfRows);
		},
		onSearchChange: (searchText) => {
			setFilters({ ...filters, title: searchText });
		},
		onColumnSortChange: (changedColumn, direction) => {
			setSortingValues(
				direction === "desc" ? `-${changedColumn}` : `${changedColumn}`
			);
			// setPage(0)
			// setSortFields(temp);
		},
		onFilterChange: (columnChanged, filterList, type, index) => {
			setPage(0);
			let value = filterList[index];
			let filterdata = {};
			let newar = [];
			if (columnChanged === "playoff") {
				newar = playoffs
					.filter(({ title }) => value.includes(title))
					.map((play) => play.id);

				filterdata[columnChanged] = newar;
				setFilters({ ...filters, ...filterdata });
			} else if (columnChanged === "owner") {
				newar = owner
					.filter(({ name }) => value.includes(name))
					.map((play) => play.id);

				filterdata[columnChanged] = newar;
				setFilters({ ...filters, ...filterdata });
			}
		},

		// onTableChange: (action, tableState) => {
		// 	switch (action) {
		// 		case "sort":
		// 			sortTable(tableState.page, tableState.sortOrder);
		// 			break;
		// 		default:
		// 			console.log("action not handled.");
		// 	}
		// },
	};

	return (
		<Fragment>
			<MUIDataTable
				title={"Curation List"}
				data={data}
				columns={columns}
				options={options}
			/>
			<DeleteModal
				curation={curation}
				setCuration={setCuration}
				setOpen={setOpen}
				open={open}
				handleDelete={handleDelete}
			/>
		</Fragment>
	);
};
