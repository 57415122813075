import { BaseURLwithEndPoint } from "./config";
import axios from "axios";

let base = BaseURLwithEndPoint();

const endpoint = base + "prize/";

export const Create = async (params) => {
	console.log("====================================");
	console.log(params);
	console.log("====================================");
	try {
		const res = await axios.post(endpoint, params);
		console.log("====================================");
		console.log(res);
		console.log("====================================");
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};

export const Update = async (params, id) => {
	try {
		const res = await axios.put(`${endpoint}${id}/`, params);
		console.log("====================================");
		console.log(res);
		console.log("====================================");
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		console.log("====================================");
		console.log(e);
		console.log("====================================");
		let data = {
			success: false,
		};
		return data;
	}
};

export const getAllSeller = async () => {
	try {
		const res = await axios.get(endpoint);

		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};

export const getStylistUsers = async () => {
	try {
		let url = `${base}stylist/`;
		const res = await axios.get(url);

		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};
