import React, { useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import { Add } from "@mui/icons-material";
import { Box, Button, Drawer, Toolbar, Typography } from "@mui/material";

import { EditTags } from "../../components/Tag/EditTags";
import TagsTable from "../../components/Tag/TagsTable";
import { getTags, setActiveInactiveTag } from "../../apis/Tags";
import { useState } from "react";

const useStyles = makeStyles()((theme) => ({
	title: {
		flexGrow: 1,
		display: "none",
		[theme.breakpoints.up("sm")]: {
			display: "block",
		},
	},
}));

export const Tags = () => {
	const { classes } = useStyles();
	const [isEdit, setIsEdit] = useState(false);
	const [editOpen, setEditOpen] = useState(false);
	const [rows, setRows] = useState([]);
	const [row, setRow] = useState({});
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsCount, setRowsCount] = useState(10);
	const [count, setCount] = useState(0);

	useEffect(() => {
		setLoading(true);
		(async () => {
			const data = await getTags(rowsCount);
			setRows(data.results);
			setCount(data.count);
		})();
		setLoading(false);
	}, []);

	const handleAddRole = () => {
		setRow({ name: "", active: true });
		setEditOpen(true);
		setIsEdit(false);
	};

	const handleEditRole = (id) => {
		setRow(rows.find((row) => row.id === id));
		setEditOpen(true);
		setIsEdit(true);
	};

	const handlePage = async (_, i) => {
		const data = await getTags(rowsCount, i + 1);
		setRows(data.results);
		setPage(i);
	};

	const handleRowsCount = async (i) => {
		setRowsCount(i);
		setLoading(true);
		const data = await getTags(i);
		setRows(data.results);
		setLoading(false);
	};

	const handleActive = async (id) => {
		let list = rows.find((row) => row.id === id);
		let tagData = { ...list, active: !list.active };
		await setActiveInactiveTag(tagData);
		const newContent = await getTags(rowsCount, page+1);
		setRows(newContent.results);
	};

	const reloadTable = async() =>{
		const data = await getTags(rowsCount, page+1);
		setRows(data.results);
	}

	return (
		<>
			<Toolbar>
				<Typography
					className={classes.title}
					variant="h4"
					noWrap
				></Typography>
				<Button
					onClick={() => handleAddRole()}
					startIcon={<Add />}
					variant="contained"
					color="primary"
				>
					Add List Category
				</Button>
			</Toolbar>
			<Box sx={{ px: 5 }}>
				<TagsTable
					handleEdit={handleEditRole}
					handleActive={handleActive}
					rows={rows}
					loading={loading}
					page={page}
					rowsCount={rowsCount}
					handlePage={handlePage}
					handleRowsCount={handleRowsCount}
					count={count}
				/>
			</Box>
			<Drawer
				anchor="right"
				open={editOpen}
				onClose={() => setEditOpen(false)}
			>
				<EditTags
					edit={isEdit}
					setEditOpen={setEditOpen}
					row={row}
					setRow={setRow}
					setRows={reloadTable}
					handleClose={() => setEditOpen(false)}
				/>
			</Drawer>
		</>
	);
};
