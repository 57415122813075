import { Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import { UpdateCuration } from "../../apis/Curation";

export default function StatusSwitch({ value, curation }) {
	const [active, setActive] = useState(value);
	const handleClick = async () => {
		setActive((pre) => !pre);
		curation = { ...curation, active: !active };
		await UpdateCuration(
			{ title: curation.title, active: !active },
			curation.id
		);
		// if (res.success) {
		// 	navigate("/w86zjruk6q/curation");
		// }
	};
	useEffect(() => {
		setActive(value);
	}, [curation, value]);

	return (
		<Switch
			checked={active}
			onChange={handleClick}
			inputProps={{
				"aria-label": "controlled",
			}}
		/>
	);
}
