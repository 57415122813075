import { Box, Stack, Typography, Button } from "@mui/material";
import React, { useState } from "react";

import { getPlayoffShareCountDetails } from "../../../apis/Api";

function CustomeCellDataPlayoff({row,filter}) {
    const [displaying, setDisplaying] = useState(false); 
    const [name, setName] = useState([]);
     
  

    const getPlayoffShareDetails = async () => {   
        console.log(row)
     let data = await getPlayoffShareCountDetails(filter, row.playoff.id)
     console.log('====================================');
     console.log(data);
     console.log('====================================');
      setDisplaying(true)
      setName(data)
    };
  return <>
     <>
      <Stack direction="row" justifyContent="center" sx={{marginBottom:"10px"}}>
        <Box sx={{paddingX:2}}>       
            <Typography >
            {row.total_share}           
            </Typography>         
        </Box>        
      </Stack>
      <Button onClick={getPlayoffShareDetails} variant="text">View details</Button>
 
	  <Box sx={{display:displaying? "block":"none", marginTop:"15px"}}>				
									
      {name?.map((p) => {
       
        return (         
            <Box sx={{textAlign:"center"}}>
             
              <Typography variant="body2" sx={{ textAlign: "center" }}>
             {p.username}
              </Typography>          
          </Box>
        );      
        })}  
      </Box>
    </>
  </>;
}

export default CustomeCellDataPlayoff;
