import React, { useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import { Autocomplete } from "@mui/lab";
import {
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	IconButton,
	Input,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Radio,
	RadioGroup,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";


import { ToastContainer, toast } from "react-toastify";
import { getAllSeller } from "../../apis/Seller";

import { getStylistUsers, Create } from "../../apis/GrapeList";
import _ from "lodash";
import { getAllProduct } from "../../apis/Product";
import { getTags } from "../../apis/Tags";

export const EditListDrawer = ({ title, setEditOpen, seller, setRows }) => {
	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm();
	const [name, setName] = useState("");
	const [listOptions, setListOptions] = useState([
		{
			value: "grape_list",
			title: "Grape List",
		},
		{
			value: "stylist_list",
			title: "Stylist List",
		},
	]);
	const [showStylelist, setShowStylelist] = useState(false);
	const [stylelist, setStylelist] = useState([]);
	const [list, setList] = useState([]);
	const [products, setProducts] = useState([]);

	const onSubmit = async (data) => {
		console.log(data);
		// let formData = new FormData();
		let formData = new FormData();
		let pproducts;
		if (Array.isArray(data["products"])) {
			pproducts = data["products"].map((p) => parseInt(p));
		} else {
			pproducts = parseInt(data["products"]);
		}
		if (!data.owner) {
			data.owner = 2;
		}
		for (let i in data) {
			if (i === "image") {
				formData.append(i, data[i][0]);
			} else formData.append(i, data[i]);
		}
		formData["products"] = [pproducts];
		formData["list_category"] = [data["list_category"]];
		let res = await Create(formData);
		if (res.success) {
			let newSellers = await getAllSeller();
			setRows(newSellers.results);
			setEditOpen(false);
		}
	};

	useEffect(() => {
		_.debounce(async () => {
			let users = await getStylistUsers();
			setStylelist(users);
			let tags = await getTags();
			console.log("====================================");
			console.log(tags);
			console.log("====================================");
			setList(tags.results);
			const data = await getAllProduct();
			console.log("====================================");
			console.log(data.results);
			console.log("====================================");
			setProducts(data.results);
		}, 200)();
	}, []);

	const notify = (m) =>
		toast.error(m, {
			position: "top-right",
			autoClose: 2500,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});

	// const handleURL = async (e) => {

	// 	setUrl(e.target.value);
	// 	if(e.target.value){
	// 	const data = await getProductPreview(e.target.value);
	// 	setProductPreview(data.data);
	// 	}
	// }

	return (
		<div
			style={{
				width: 600,
				padding: 5,
				height: "100vh",
				overflowY: "scroll",
			}}
		>
			<IconButton
				style={{ position: "fixed" }}
				onClick={() => setEditOpen(false)}
			>
				<Close />
			</IconButton>
			<ToastContainer />
			<div style={{ padding: "45px 55px 0px" }}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Box display="flex" justifyContent="space-between">
						<Typography
							variant="h6"
							gutterBottom
						>{`${title} List`}</Typography>
						<Button
							type="submit"
							// onClick={handleSave}
							color="primary"
							variant="contained"
						>
							{title === "Edit" ? "Update" : "Save"}
						</Button>
					</Box>

					<Box my={3}>
						<TextField
							fullWidth
							name="name"
							// style={{ width: "100%" }}
							label="Enter List Name"
							variant="outlined"
							// value={seller ? seller.name : ""}
							error={Boolean(errors.title)}
							helperText={errors.title?.message}
							{...register("title", {
								required: "Enter List name",
							})}
						/>
					</Box>
					<Box my={3}>
						<FormControl
							required
							error={Boolean(errors.list_category)}
							// component="outlined"
							// sx={{ m: 3 }}
							variant="outlined"
						>
							<FormLabel>List Category</FormLabel>
							<FormGroup row>
								{list.map((list) => {
									return (
										<FormControlLabel
											control={
												<Checkbox
													{...register(
														"list_category",
														{
															required:
																"Please Pick atleast one",
														}
													)}
													value={list.id}
													// onChange={handleChange}
													name="list_category"
												/>
											}
											label={list.name}
										/>
									);
								})}
							</FormGroup>
							<FormHelperText></FormHelperText>
						</FormControl>
					</Box>
					<Box my={3}>
						<FormControl
							required
							error={Boolean(errors.listcategory)}
							// component="outlined"
							// sx={{ m: 3 }}
							variant="outlined"
						>
							<FormLabel>Product List</FormLabel>
							<FormGroup row>
								{products.map((list) => {
									return (
										<FormControlLabel
											control={
												<Checkbox
													{...register("products", {
														required:
															"Please Pick atleast one",
													})}
													value={list.id}
													// onChange={handleChange}
													name="products"
												/>
											}
											label={list.name}
										/>
									);
								})}
							</FormGroup>
							<FormHelperText></FormHelperText>
						</FormControl>
					</Box>
					<Box my={3}>
						<TextField
							fullWidth
							name="description"
							multiline
							rows={4}
							// style={{ width: "100%" }}
							label="Enter description url"
							variant="outlined"
							{...register("description", {
								required: "Enter description url",
							})}
							error={Boolean(errors.description)}
							helperText={errors.description?.message}
						/>
					</Box>
					<Box my={3}>
						<FormControl error={Boolean(errors.image)} fullWidth>
							<OutlinedInput
								type="file"
								id="file-upload"
								name="image"
								fullWidth
								accept="image/*"
								{...register("image", {
									required: "Image required",
								})}
								error={Boolean(errors.image)}
							/>
							{errors.image && (
								<FormHelperText>
									{errors.image?.message}
								</FormHelperText>
							)}
						</FormControl>
					</Box>

					<Box my={3}>
						<FormControl error={Boolean(errors.list_type)}>
							<FormLabel id="demo-row-radio-buttons-group-label">
								List Type
							</FormLabel>
							<RadioGroup
								row
								aria-labelledby="demo-row-radio-buttons-group-label"
								name="list_type"
							>
								{listOptions.map((option) => (
									<FormControlLabel
										value={option.value}
										control={
											<Radio
												{...register("list_type", {
													required:
														"Please select list",
												})}
												onChange={(e) => {
													if (
														e.target.value ==
														"stylist_list"
													) {
														setShowStylelist(true);
													} else {
														setShowStylelist(false);
													}
												}}
											/>
										}
										label={option.title}
									/>
								))}
							</RadioGroup>
							<FormHelperText>
								{errors.list_type && errors.list_type?.message}
							</FormHelperText>
						</FormControl>
					</Box>
					<Box my={3}>
						{showStylelist && (
							<FormControl
								fullWidth
								error={Boolean(errors.owner)}
							>
								<InputLabel htmlFor="owner">Stylist</InputLabel>
								<Select
									{...register("owner", {
										required: "Select stylist",
									})}
									displayEmpty
									input={<OutlinedInput label="Stylist" />}
									label="Stylist"
									// onChange={(e) => {
									// 	//setSelectedSeller(e.target.value);
									// }}
								>
									{stylelist.map((product, i) => {
										return (
											<MenuItem
												key={`seller-${i}`}
												value={product.id}
											>
												{product.name}
											</MenuItem>
										);
									})}
								</Select>
								<FormHelperText>
									{errors.owner && errors.owner?.message}
								</FormHelperText>
							</FormControl>
						)}
					</Box>
				</form>
			</div>
		</div>
	);
};
