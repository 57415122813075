import { axiosInstance } from "../config/axios";

export const CheckUserExist=async (data)=>{
    console.log(data);
    let res = await axiosInstance.post("check-user-exist",data)
    console.log(res);
    return !res.data.users_exist
}

const handlerEnabled = true

export const getAllUsers = async (row,page=1) => {
	const api = "createstylist/";
	try {
		const res = await axiosInstance.get(api,{params:{page_size:row,page:page},handlerEnabled});

		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}

};


const api = "createstylist/";
export const addUser = async (data) => {
	
	const res = await axiosInstance.post(api, data);
    console.log(res);
	return res.data;
};


export const editUser = async (params,id) => {
	try{
	const res = await axiosInstance.put(`${api}${id}/`, params);
	const { data } = res;
	console.log('====================================');
	console.log(res);
	console.log('====================================');
		data["success"] = true;
		return res.data;
	}
	catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};



// export const checkUserHandle = async (userHandele) => {
	
// 	if (userHandele) {
// 		const api = `check_handle_availability/?user_handle=${userHandele}`;
		
// 		const res = await axios
// 			.get(base + ex + api)
// 			.then((res) => res)
// 			.catch((err) => err.response);
// 		// console.log("res", res)
// 		return !res.data.users_exist
// 	}
// };

export const getUserDetails = async (row,page=1,filters={},order_by=null) => {
	try {
		const res =  await axiosInstance.get(api,{params:{page_size:row, page: page,order_by,...filters},handlerEnabled});
		console.log(res);
		return res.data;
	} catch (e) {
		console.log('====================================');
		console.log(e);
		console.log('====================================');
		let data = {
			success: false,
		};
		return data;
	}
};


export const getAllUserFavourites = async (row,page=1,filters={},order_by=null) => {
	try {
		const res =  await axiosInstance.get('/user-favourites',{params:{page_size:row, page: page,order_by,...filters},handlerEnabled});
		console.log(res);
		return res.data;
	} catch (e) {
		console.log('====================================');
		console.log(e);
		console.log('====================================');
		let data = {
			success: false,
		};
		return data;
	}
};