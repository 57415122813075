import axios from "axios";

// import { BASE_URL, TOKEN } from "../../utils/Constants";


//add your BASE_URL to Constants file

// console.log(BASE_URL);

const AuthHeader = () => {
	const user = JSON.parse(localStorage.getItem("user"));
	let headers = {  };
	if (user && user.accessToken) {
		headers.Authorization = "Bearer " + user.accessToken;
	}
	
	return headers;
};

let baseURL=process.env.REACT_APP_BASE_URL

const axiosInstance = axios.create({
	baseURL: `${baseURL}/web`,
	headers: AuthHeader(),
});


axiosInstance.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axiosInstance.defaults.xsrfCookieName = "csrftoken";

export  {axiosInstance}

// Axios API instance ================X=================
// const axiosInstance2 = axios.create({
// 	baseURL: `${baseURL}/api`,
// 	headers: AuthHeader(),
// });

// axiosInstance2.defaults.xsrfHeaderName = "X-CSRFTOKEN";
// axiosInstance2.defaults.xsrfCookieName = "csrftoken";

// export  {axiosInstance2}
// =================XXXXXXX==========================



// Handle request process
axiosInstance.interceptors.request.use((request) => requestHandler(request));

// Handle response process
axiosInstance.interceptors.response.use(
	(response) => successHandler(response),
	(error) => errorHandler(error)
);

export const isHandlerEnabled = (config = {}) => {
	// console.log(
	// 	config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled
	// );
	// return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled

	return true;
};

const requestHandler = (request) => {
	if (isHandlerEnabled(request)) {
		// Modify request here
		// store.dispatch(loader(true));
	}
	return request;
};

const successHandler = (response) => {
	if (isHandlerEnabled(response)) {
		// Hanlde Response
		// store.dispatch(loader(false));
	}
	return response;
};

const errorHandler = async (error) => {
	const originalConfig = error.config;

	if (originalConfig.url !== "/auth/signin" && error.response) {
		// Access Token was expired
		if (error.response.status === 401 && !originalConfig._retry) {
			originalConfig._retry = true;

			// try {
			// 	const rs = await axiosInstance.post("/auth/refreshtoken", {
			// 		refreshToken: TokenService.getLocalRefreshToken(),
			// 	});

			// 	const { accessToken } = rs.data;

			// 	dispatch(refreshToken(accessToken));
			// 	TokenService.updateLocalAccessToken(accessToken);

			// 	return axiosInstance(originalConfig);
			// } catch (_error) {
			// 	return Promise.reject(_error);
			// }
		}
	}
	if (isHandlerEnabled(error.config)) {
		// store.dispatch(loader(false));
		// You can decide what you need to do to handle errors.
		// here's example for unautherized user to log them out .
		// error.response.status === 401 && Auth.signOut();
		// console.log("==========================")
		// console.log(error.response)
		// console.log("==========================")
	}
	return Promise.reject({ ...error });
};


