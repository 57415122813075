import { Box, Card, CardActions, CardContent, MenuItem, Select, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { getProductViewCount } from "../../../apis/Api";
import moment from "moment-timezone";

function GrapeListGraphCard({title, fetchDataFunc,type,id}) {
    const [selcetedValue, setSelcetedValue] = useState(0);
    const [graphData, setGraphData] = useState({});
    const [productViewCountValue, setProductViewCountValue] = useState(0);
    useEffect(() => {
        productViewCount('all time')
    }, []);
    

    const handleChange = (event) => {
        setSelcetedValue(event.target.value);
        if (event.target.value == 1) {
          productViewCount("day");
        } else if (event.target.value == 2) {
          productViewCount("week");
        } else if(event.target.value == 3) {
          productViewCount("month");
        }else{
          productViewCount("all time");
        }
      };
      const productViewCount = async (
        filter,
        date = moment().format("YYYY-MM-DD HH:mm:ss")
      ) => { 
        const data = id!==''?await fetchDataFunc(id,filter, date):await fetchDataFunc(filter, date);
        let sum = data.reduce((a, b) => a + b.count, 0);
        setProductViewCountValue(sum);
        setGraphData(data);
      };
      const tickFormatter = (tick) => {
        if(selcetedValue == 0 ){
          return moment(tick).tz("Asia/Kolkata").format("MMM");
        }
        else if (selcetedValue == 1) {
          return moment(tick).tz("Asia/Kolkata").format("hh:mm a");
        } else if (selcetedValue == 2) {
          return moment(tick).format("ddd");
        } else {
          return 'Week '+Math.ceil(moment(tick).date() / 7);
        }
      };
      const formatTooltipLabel = (value) => {
        return moment(value).tz("Asia/Kolkata").format("DD-MM-YYYY");
      };
    
  return (<>
   <Card
              sx={{               
                margin: 2,
                padding: 2.5,
                "&:hover": { backgroundColor: "none" },
              }}
            >
              <CardContent>
                <Box display="flex" justifyContent="space-evenly">
                  <Typography
                    gutterBottom
                    variant="h4"
                    component="div"
                    sx={{ width: "55%" }}
                  >
                    {title}
                  </Typography>

                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={selcetedValue}
                    onChange={handleChange}
                    sx={{
                      border: "none",
                      minWidth: "125px",
                      padding: 0,
                      height: "40px",
                      borderRadius: "20px",
                      ".MuiSelect-select": {
                        paddingRight: "20px !important",
                      },
                      ".MuiSelect-outlined": {
                        border: "none",
                        outline: "none",
                      },
                      "&:focus": {
                        backgroundColor: "red",
                      },
                    }}
                    variant="outlined"
                  >
                    <MenuItem value="0">All Time</MenuItem>
                    <MenuItem value="1">Day</MenuItem>
                    <MenuItem value="2">Week</MenuItem>
                    <MenuItem value="3">Month</MenuItem>
                  </Select>
                </Box>

                <Typography
                  variant="h1"
                  sx={{
                    margin: 2,
                    textAlign: "center",
                  }}
                >
                  {productViewCountValue}
                </Typography>
                <ResponsiveContainer height={300}>
                  <LineChart
                    data={graphData}
                    height="100%"
                    width="100%"
                    margin={{
                      top: 5,
                      right: 0,
                      left: -25,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={"dateTime"} tickFormatter={tickFormatter} />
                    <YAxis dataKey="count" />
                    <Tooltip labelFormatter={formatTooltipLabel} />
                    <Legend />
                    <Line dataKey="count" />
                  </LineChart>                
                </ResponsiveContainer>
              </CardContent>
              <CardActions>
                <Box sx={{ marginTop: 3, marginX: "auto" }}>
                  <Link
                    to="/w86zjruk6q/product-view-details"
                    state={{ value: selcetedValue,type:type,id:id}}
                    underline="hover"
                  >
                    View details
                  </Link>
                </Box>
              </CardActions>
            </Card>
  </>);
}

export default GrapeListGraphCard;
