import { Button, TextField, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import _ from "lodash";
import { useEffect, useState } from "react";

import { getAllCategory, getMasterCategory, getMasterCategoryById } from "../../apis/ProductCategory";
import { ProductCategoryAccordion } from "../Menu/SubmenuItem";

export const CategoryAutocomplete = ({ title,category,setCategory,showCategory, setShowCategory,selectedCategory=null }) => {
	
	const [categories, setCategories] = useState([]);
	
	// const [showMasterCategory, setShowMasterCategory] = useState(
	// 	edit ? category.master !== null : true
	// );
	
	useEffect(() => {
		_.debounce(async () => {
			if  (selectedCategory!==null){
				let categories = await getMasterCategoryById(selectedCategory.id,1000, 1)
				setCategories([categories]);
			}else{
			let categories =  await getMasterCategory(1000, 1);
			console.log(categories);
			setCategories(categories.results);
			}
			
		}, 0)();
	}, []);

	return (
		<>
			<Box mb={2} sx={{ p: 1, border: '1px solid rgba(0, 0, 0, 0.23)',borderRadius:"4px" }}>
				<Stack
					direction={"row"}
					justifyContent="space-between"
					alignItems={"center"}
				>
					<Typography
						variant="h6"
						component={"h4"}
						onClick={() => {
							setShowCategory(true);
						}}
					>
						{category?.name}
					</Typography>
					<Button
						variant="text"
						onClick={() => {
							setShowCategory(true);
						}}
					>
						{category ? "Change" : "Select"} {title}
					</Button>
				</Stack>
			</Box>

			<ProductCategoryAccordion
				open={showCategory}
				setOpen={setShowCategory}
				categories={categories}
				setCategory={setCategory}
			></ProductCategoryAccordion>
		</>
	);
};
