import { axiosInstance } from "../config/axios";
const endpoint =  "persistent-list/";
const handlerEnabled = true


export const getPersistentList = async (row,page=1) => {
	
	try {
		const res = await axiosInstance.get(endpoint,{params:{page_size:row,page:page},handlerEnabled});

		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};
export const refreshMostPopular = async () => {	
	try {
		const res = await axiosInstance.get('refresh-most-popular',{handlerEnabled});

		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};
export const refreshBestDeals = async () => {	
	try {
		const res = await axiosInstance.get('refresh-best-deals',{handlerEnabled});

		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};