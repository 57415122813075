import { makeStyles } from "tss-react/mui";
import {
	ContentCopyRounded,
	EditOutlined,
	RemoveRedEye,
} from "@mui/icons-material";
import {
	Box,
	CircularProgress,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Button,
	tableCellClasses,
	styled,
	TablePagination,
	Typography,
	Avatar,
	Drawer,
} from "@mui/material";
import { base } from "../../apis/Api";
import { green, red } from "@mui/material/colors";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";

import { getAllUsers } from "../../apis/user";
import { EditUserDrawer } from "./EditUserDrawer";
import StatusSwitch from "./StatusSwitch";
import CopyToClipboard from "react-copy-to-clipboard";
import { notify } from "../Playoff/PlayListTable";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.action.hover,
		fontWeight: 600,
		userSelect: "none",
		borderBottom: `1px solid ${theme.palette.background.divider}`,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
		userSelect: "none",
		borderBottom: `1px solid ${theme.palette.background.divider}`,
	},
}));

const useStyles = makeStyles({
	table: {
		minWidth: 1280,
	},
	tableHead: {
		position: "sticky",
		top: 0,
		zIndex: 1,
	},
});

export default function DataTable({
	rows,

	loading,
	handlePage,
	handleRowsCount,
}) {
	const [data, setData] = useState(rows);
	const [page, setPage] = useState(0);
	const [filters, setFilters] = useState({});
	const [sortingValues, setSortingValues] = useState(null);
	const [count, setCount] = useState(0);
	const [rowsCount, setRowsCount] = useState(10);
	const columns = [
		{
			name: "id",
			label: "Id",
			options: {
				filter: false,
				sort: true,
				setCellProps: () => ({ sx: { textAlign: "right" } }),
				// customBodyRender: (value, tableMeta, updateValue) => {
				// 	// console.log("yes", tableMeta);
				// 	return tableMeta.rowIndex + 1;
				// },
			},
		},
		{
			name: "slug",
			label: "Slug",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					// console.log("yes", value);
					return (
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
							}}
						>
							<Typography variant="p" component={"p"}>
								{value}
							</Typography>

							<CopyToClipboard
								text={value}
								onCopy={() => notify("copied")}
							>
								<IconButton size="small" color="primary">
									<ContentCopyRounded />
								</IconButton>
							</CopyToClipboard>
						</Box>
					);
				},
			},
		},
		{
			name: "name",
			label: "Name",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
							}}
						>
							<Typography variant="p" component={"p"}>
								{value}
							</Typography>

							<CopyToClipboard
								text={value}
								onCopy={() => notify("copied")}
							>
								<IconButton size="small" color="primary">
									<ContentCopyRounded />
								</IconButton>
							</CopyToClipboard>
						</Box>
					);
				},
			},
		},
		{
			name: "phone",
			label: "Phone",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					// console.log("yes", value);
					return <Typography variant="p">{value}</Typography>;
				},
			},
		},
		{
			name: "email",
			label: "Email",
			options: {
				filter: false,
				sort: true,

				customBodyRender: (value, tableMeta, updateValue) => {
					// console.log("yes", value);
					return <Typography variant="p">{value}</Typography>;
				},
			},
		},
		{
			name: "role",
			label: "User Type",
			options: {
				filter: true,
				sort: true,

				customBodyRender: (value, tableMeta, updateValue) => {
					// console.log("yes", value);
					return (
						<Typography
							variant="p"
							sx={{ textTransform: "capitalize" }}
						>
							{value}
						</Typography>
					);
				},
			},
		},
		{
			name: "image",
			label: "Profile Pic",
			options: {
				filter: false,
				sort: false,

				customBodyRender: (value, tableMeta, updateValue) => {
					// console.log("yes", value);
					return (
						<Avatar
							// variant="square"
							src={value?.file}
							alt=""
							sx={{ width: 70, height: 70 }}
							// style={{ margin: 5, borderRadius: 5 }}
						/>
					);
				},
			},
		},
		{
			name: "insta_handle",
			label: "Insta Handle",
			options: {
				filter: false,
				sort: true,
				// customBodyRender: (value, tableMeta, updateValue) => {
				// 	let user=data[tableMeta.rowIndex]
				// 	return (
				// 		<StatusSwitch value={value} user={user}/>
				// 	);
				// },
			},
		},
		{
			name: "allow_notification",
			label: "Notification",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					let user = data[tableMeta.rowIndex];
					return <StatusSwitch value={value} user={user} />;
				},
			},
		},

		{
			name: "created_at",
			label: "Created At",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return new Date(value).toString().substring(4, 24);
				},
			},
		},
		{
			name: "updated_at",
			label: "Update At",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return new Date(value).toString().substring(4, 24);
				},
			},
		},

		{
			name: "",
			label: "Action",
			options: {
				filter: false,
				sort: true,
				viewColumns: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<>
							<IconButton
								color="primary"
								onClick={() => {
									handleEdit(data[tableMeta.rowIndex].id);
								}}
							>
								<EditOutlined />
							</IconButton>
							<IconButton
								color="primary"
								href={`/w86zjruk6q/user/${
									data[tableMeta.rowIndex].slug
								}`}
								// onClick={() => {
								// 	handleEdit(data[tableMeta.rowIndex].id)
								// }}
							>
								<RemoveRedEye />
							</IconButton>
							<CopyToClipboard
								text={`https://www.grape.town/community/${
									data[tableMeta.rowIndex].slug
								}`}
								onCopy={() => notify("copied")}
							>
								<IconButton size="small" color="primary">
									<ContentCopyRounded />
								</IconButton>
							</CopyToClipboard>
						</>
					);
				},
			},
		},
	];
	const options = {
		jumpToPage: true,
		selectableRows: "none",
		download: false,
		// filterType: "multiselect",
		serverSide: true,
		print: false,
		count: count,
		page: page,
		rowsPerPage: rowsCount,
		rowsPerPageOptions: [10, 25, 100],
		onChangePage(currentPage) {
			setPage(currentPage);
		},
		onChangeRowsPerPage(numberOfRows) {
			setRowsCount(numberOfRows);
		},
		onSearchChange: (searchText) => {
			setFilters({ ...filters, title: searchText });
		},
		onColumnSortChange: (changedColumn, direction) => {
			setSortingValues(
				direction === "desc" ? `-${changedColumn}` : `${changedColumn}`
			);
			// setPage(0)
			// setSortFields(temp);
		},

		// onTableChange: (action, tableState) => {
		// 	switch (action) {
		// 		case "sort":
		// 			sortTable(tableState.page, tableState.sortOrder);
		// 			break;
		// 		default:
		// 			console.log("action not handled.");
		// 	}
		// },
	};
	const [isEdit, setIsEdit] = useState(false);
	const [user, setUser] = useState({});
	const [editOpen, setEditOpen] = useState(false);

	const handleEdit = (id) => {
		setIsEdit(true);

		setUser(data.find((row) => row.id === id));

		setEditOpen(true);
	};
	useEffect(() => {
		getAllUsers(rowsCount, page + 1, filters, sortingValues).then((res) => {
			console.log("====================================");
			console.log(res);
			console.log("====================================");
			setData(res.results);
			setCount(res.count);
		});
	}, [page, rowsCount, filters, sortingValues]);
	const classes = useStyles();
	//console.log("row", rows)
	return (
		<>
			<MUIDataTable
				title={"User List"}
				data={data}
				columns={columns}
				options={options}
			/>

			<Drawer
				anchor="right"
				open={editOpen}
				onClose={() => setEditOpen(false)}
			>
				<EditUserDrawer
					edit={isEdit}
					setEditOpen={setEditOpen}
					user={user}
					setUser={setUser}
					setRows={() => {}}
					loading={loading}
				/>
			</Drawer>
		</>
	);
}
