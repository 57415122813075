import { useEffect, useState } from "react";

import { makeStyles } from "tss-react/mui";
import { Add } from "@mui/icons-material";
import { Box, Button, Drawer, Toolbar, Typography } from "@mui/material";
import Table from "../../components/ProductCategory/CategoryTable";

import { ToastContainer, toast } from "react-toastify";

import _ from "lodash";
import { getAllCategory,deleteCategory } from "../../apis/ProductCategory";
import { AddCategoryDrawer } from "../../components/ProductCategory/AddCategoryDrawer";

const useStyles = makeStyles()((theme) => ({
	title: {
		flexGrow: 1,
		display: "none",
		[theme.breakpoints.up("sm")]: {
			display: "block",
		},
	},
}));

export const Category = ({ searchText }) => {
	const { classes } = useStyles();

	const [editOpen, setEditOpen] = useState(false);
	const [addOpen, setAddOpen] = useState(false);
	
	const [rows, setRows] = useState([]);
	const [category, setCategory] = useState();

	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsCount, setRowsCount] = useState(10);
	const [count, setCount] = useState(0);
	const [isEdit, setIsEdit] = useState(false);

	useEffect(() => {
		setLoading(true);
		_.debounce(async () => {
			const data = await getAllCategory(rowsCount);
			setRows(data.results);
			console.log(data);
			setCount(data.count);
		}, 500)();
		setLoading(false);
	}, []);
	

	
	const reloadTable = async() =>{
		const data = await getAllCategory(rowsCount, page+1);
		setRows(data.results);
	}

	const notify = () =>
		toast.error(`Some unexpected error occured`, {
			position: "bottom-right",
			autoClose: 2500,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});

	const handleAdd = () => {
		
		setCategory({name:""});
		setAddOpen(true);
		setIsEdit(false)
	};

	const handleEdit = (id) => {
		
		let category= rows.find(row=>row.id===id)
		setIsEdit(true)
		setCategory(category);
		setAddOpen(true);
	};

	const handleDeleteProduct = async (id) => {
		setLoading(true);
		const res = await deleteCategory(id);
		if (res.status !== 200) {
			notify();
			return;
		}
		const data = await getAllCategory(
			rowsCount,page+1
		);
		setRows(data.results);
		setLoading(false);
	};

	const handlePage = async (_, i) => {
		const data = await getAllCategory(rowsCount, i + 1);
		setRows(data.results);
		setPage(i);
	};

	const handleRowsCount = async (i) => {
		setRowsCount(i);
		setLoading(true);
		const data = await getAllCategory(i);
		setRows(data.results);
		setLoading(false);
	};
	return (
		<>
			<Toolbar>
				<Typography className={classes.title} variant="h4" noWrap />
				<Button
					onClick={() => handleAdd()}
					startIcon={<Add />}
					variant="contained"
					color="primary"
				>
					Add Category
				</Button>
			</Toolbar>
			<ToastContainer />
			<Box sx={{ px: 5 }}>
				<Table
					handleEdit={handleEdit}
					handleDelete={handleDeleteProduct}
					page={page}
					rowsCount={rowsCount}
					handlePage={handlePage}
					handleRowsCount={handleRowsCount}
					count={count}
					rows={rows}
					loading={loading}
				/>
				<Drawer
					anchor="right"
					open={editOpen}
					onClose={() => setEditOpen(false)}
				>
					
				</Drawer>

				<Drawer
					anchor="right"
					open={addOpen}
					onClose={() => setAddOpen(false)}
				>
					<AddCategoryDrawer
						rows={rows}
						
						setAddOpen={setAddOpen}
						setRows={setRows}
						category={category}
						setCategory={setCategory}
						edit={isEdit}
						reloadTable={reloadTable}
					/>
				</Drawer>
			</Box>
		</>
	);
};
