import {
    Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import { axiosInstance } from "../../config/axios";


export default function DeleteModal({open,setOpen,curation,setCuration,handleDelete}) {
	

	
	const handleClose = () => {
		setOpen(false)
	};

	return (
		<div>
			
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Confirmation"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{`Are you sure? Do you want to delete Curation ${curation?.title} `}
					</DialogContentText>
				</DialogContent>
				<DialogActions  sx={{paddingX:3, paddingBottom:3}}>
					<Button variant="contained" color="primary" onClick={handleClose}>No</Button>
					<Button variant="contained" color="secondary" onClick={handleDelete} autoFocus>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
