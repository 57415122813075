import { Paper, Box, createTheme, Typography } from "@mui/material";
import { Link, Outlet, useLocation } from "react-router-dom";
import bg1 from "../assets/bg1.jpg";
import logo from "../assets/newlogo.svg";
import { ToastContainer, toast } from "react-toastify";


export const Authlayout = () => {
	const location = useLocation();
	const endpoint = location.pathname.split("/");
	const loginpage = endpoint[endpoint.length - 1] === "login";
	const theme = createTheme({
		spacing: 32,
	});
	const notify = (m) => {
		toast.error(m, {
			position: "bottom-right",
			autoClose: 2500,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};
	return (
		<Box
			m={0}
			display="flex"
			flexDirection="row-reverse"
			height="100vh"
			sx={{
				backgroundSize: "cover",
				backgroundImage: `url(${bg1})`,
			}}
		>
			<Paper
				component={Box}
				display="flex"
				flexDirection="column"
				px={theme.spacing(2)}
				py={2}
			>
				<Box>
					<img
						src={logo}
						alt="logo"
						style={{
							// backgroundColor: "#161c2e",
							// padding: 10,
							width: 80,
						}}
					/>
				</Box>
				<Box display="flex" flexGrow={1} alignItems="center">
					<Box>
						<Box pb={3}>
							<Typography
								style={{
									width: 500,
									marginBottom: 25,
									minWidth: 300,
								}}
								variant="h3"
							>
								{loginpage ? "Login" : "Register"}
							</Typography>
							<Typography color="textSecondary">
								{loginpage
									? "Enter your email and password to login our dashboard"
									: "Enter the details to register to our dashboard"}
							</Typography>
							{/* <Typography color="secondary">
								{loginpage ? "Doesn't" : "Already"} have an
								account{" "}
								<Link
									style={{ cursor: "pointer" }}
									to={!loginpage ? "login" : "register"}
								>
									{!loginpage ? "Login" : "Register"}
								</Link>{" "}
							</Typography> */}
						</Box>
						<Outlet context={[notify]}/>
						<ToastContainer />
					</Box>
				</Box>
			</Paper>
		</Box>
	);
};
