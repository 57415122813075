import { Box, Button, Link, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getGrapeListProductsCount, getPersistentListProductDetails, getPlayoffProductDetails } from "../../../apis/Api";


function CustomeCellData({ row, filter, type}) {
  const [products, setProducts] = useState([]);
  
  useEffect(() => {
    setProducts([])
  }, [row]);
  
  

  const getProductDetails = async () => {
    let data=''
   
    if(type =='persistent_list'){
      data= await getPersistentListProductDetails(filter, row.id)
    }
    else if(type =='grape_list'){
     
      data = await getGrapeListProductsCount(filter, row.id)
    }
    else{
      data = await getPlayoffProductDetails(filter, row.id);
    }
    
    setProducts(data[0]?.products);
    console.log("products",products);
  };
  return (
    <>
      <Stack direction="row" justifyContent="center">
        <Box sx={{paddingX:2}}>
          <Typography>Total</Typography>
         
            <Typography >
              {row.product_views}
            </Typography>
         
        </Box>
        <Box sx={{paddingX:2}}>
          <Typography>Unique</Typography>
         
            <Typography >
              {row.unique_product_views}
            </Typography>
         
        </Box>
      </Stack>
      <Button onClick={getProductDetails} variant="text">View details</Button>

      {products?.map((product, i) => {
        console.log("================")
        console.log(product)
        console.log(product.product.url)
        console.log("================")
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingX: 1,
              marginY: 1,
              alignItems:"center",
            }}
            
            key={`cell-${i}`}
          >
            <Box sx={{width:"60%"}}>
              <Link href={product.product.url} target="_blank">
              <Typography variant="body2" sx={{ textAlign: "left" }}>
                {product.product.name}
              </Typography>
              </Link> 
            </Box>
            <Box>
            <Typography variant="body2">{product.views}</Typography>
            </Box>
                       
           <Box sx={{width:"30%"}}>
           <Link href={product?.viewed_by?.deeplink}>
              <Typography variant="body2">{product?.viewed_by?.name}</Typography>
            </Link>
           </Box>
           
          </Box>
        );
      })}
    </>
  );
}

export default CustomeCellData;
