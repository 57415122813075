import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
	Collapse,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { NavLink, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// React runtime PropTypes
export const AppMenuItemPropTypes = {
	name: PropTypes.string.isRequired,
	link: PropTypes.string,
	Icon: PropTypes.elementType,
	items: PropTypes.array,
};


// // TypeScript compile-time props type, infered from propTypes
// // https://dev.to/busypeoples/notes-on-typescript-inferring-react-proptypes-1g88
// type AppMenuItemPropTypes = PropTypes.InferProps<typeof AppMenuItemPropTypes>;
// type AppMenuItemPropsWithoutItems = Omit<AppMenuItemPropTypes, "items">;

// Improve child items declaration
// export type AppMenuItemProps = AppMenuItemPropsWithoutItems & {
// 	items?: AppMenuItemProps[],
// };

const AppMenuItem = (props) => {
	const navigate = useNavigate();
	useEffect(() => {
		if (location.pathname.includes(name.toLowerCase()) && items.length) {
			setOpen(true);
		}
	}, []);

	const location = useLocation();
	const { name, Icon, link, items = [] } = props;
	const { classes } = useStyles();
	const isExpandable = items && items.length > 0;
	const [open, setOpen] = React.useState(false);

	function handleClick(items) {
		if(!open){
			if(items.length){
				console.log(items[0].link);
				navigate(items[0].link);
			}
		}
		setOpen(!open);
		
	}

	const MenuItemRoot = (
		<ListItem
			button
			component={NavLink}
			className={classes.menuItem}
			to={link}
			selected={link === location.pathname}
			onClick={()=>{handleClick(items)}}
		>
			{/* Display an icon if any */}
			{!!Icon && (
				<ListItemIcon className={classes.menuItemIcon}>
					<Icon />
				</ListItemIcon>
			)}
			<ListItemText primary={name} inset={!Icon} />
			{/* Display the expand menu if the item has children */}
			{isExpandable && !open && <ExpandMore />}
			{isExpandable && open && <ExpandLess />}
		</ListItem>
	);

	const MenuItemChildren = isExpandable ? (
		<Collapse in={open} timeout="auto" unmountOnExit>
			<Divider />
			<List component="div" disablePadding>
				{items.map((item, index) => (
					<AppMenuItem {...item} key={index} />
				))}
			</List>
		</Collapse>
	) : null;

	return (
		<>
			{MenuItemRoot}
			{MenuItemChildren}
		</>
	);
};

AppMenuItem.propTypes = AppMenuItemPropTypes;

const useStyles = makeStyles()((theme) => ({
	menuItem: {
		margin: 10,
		borderRadius: 10,
		width: "auto",
	},
	menuItemIcon: {
		// color: "#97c05c",
	},
}));

export default AppMenuItem;
