


import { axiosInstance } from '../config/axios'

const handlerEnabled = true

const endpoint = "seller/";

export const createSeller = async (params) => {
	try {
		const res = await axiosInstance.post(endpoint, params,{handlerEnabled});
		
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};

export const getAllSeller = async (row,page=1) => {
	try {
		const res = await axiosInstance.get(endpoint,{params:{page_size:row,page:page},handlerEnabled});

		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};


export const editSeller = async (params,id) => {
	try{
	const res = await axiosInstance.put(`${endpoint}${id}/`, params);
	const { data } = res;
		data["success"] = true;
		return res.data;
	}
	catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};


export const deleteSeller = async (id) => {
	try {
		const res = await axiosInstance.delete(`${endpoint}${id}`);

		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};