import { BaseURLwithEndPoint } from "./config";

import { axiosInstance } from "../config/axios";

let base = BaseURLwithEndPoint();

const endpoint = base + "product-category/";

export const Create = async (params) => {
	try {
		const res = await axiosInstance.post(endpoint, params);
		console.log("====================================");
		console.log(res);
		console.log("====================================");
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};

export const getAllCategory = async (row,page=1) => {
	try {
		const res = await axiosInstance.get(endpoint,{params:{page_size:row,page:page}});

		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};

export const getMasterCategory = async (row,page=1) => {
	try {
		const res = await axiosInstance.get("product-category-menu");

		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};
export const getMasterCategoryById = async (id,row,page=1) => {
	try {
		const res = await axiosInstance.get("product-category-group-menu/"+id+"/");

		const { data } = res;
		data["success"] = true;
		console.log([res.data]);
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};

export const deleteCategory = async (id) => {
	try {
		const res = await axiosInstance.delete(`${endpoint}${id}`);

		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};

export const UpdateCategoty = async (params,id) => {
	try {
		const res = await axiosInstance.put(`${endpoint}${id}/`, params);
		console.log("====================================");
		console.log(res);
		console.log("====================================");
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};
