import React from "react";
import { makeStyles } from "tss-react/mui";
import {
	alpha,
	InputBase,
	Paper,
	Toolbar,
	Typography,
	IconButton,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { UserMenu } from "./UserMenu";
import { appMenuItems } from "../utils/routes";
//
const useStyles = makeStyles()((theme) => ({
	paper: {
		backgroundColor: "white",
		boxShadow: "rgba(17, 12, 46, 0.05) 0px 4px 10px 0px",
		position: "sticky",
		top: 0,
		right: 0,
		zIndex: 1,
		borderRadius: 0,
	},
	title: {
		flexGrow: 1,
		display: "none",
		textTransform: "capitalize",
		[theme.breakpoints.up("sm")]: {
			display: "block",
		},
	},
	search: {
		position: "relative",
		borderRadius: theme.shape.borderRadius,
		backgroundColor: alpha(theme.palette.primary.light, 0.15),
		"&:focus-within": {
			backgroundColor: alpha(theme.palette.primary.light, 0.05),
		},
		marginLeft: 0,
		marginRight: theme.spacing(1),
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			marginLeft: theme.spacing(1),
			width: "auto",
		},
	},
	searchIcon: {
		padding: theme.spacing(0, 2),
		height: "100%",
		position: "absolute",
		pointerEvents: "none",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	inputRoot: {
		color: "inherit",
	},
	inputInput: {
		padding: theme.spacing(1.25),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create("width"),
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			width: "24ch",
			"&:focus": {
				width: "40ch",
			},
		},
	},
}));

export const Navbar = ({ user, searchText, setSearchText }) => {
	const { classes } = useStyles();
	const location = useLocation();
	const path = location.pathname.split("/").slice(0).join("/");
	const routes = appMenuItems;

	const getBrandText = () => {
		if (path === "") return "Dashboard";

		//  console.log(routes);
		//  console.log(path);
		let midRoute = location.pathname.split("/", 3).slice(0).join("/");
		// console.log(location.pathname.split("/", 3).slice(0).join("/"));
		let route = routes.find((route) => route.slu === midRoute);
		// console.log(route);
		if (route !== undefined) {
			// console.log(route);
			const { items } = route;
			if (items) {
				let index = items.findIndex((item) => item.link === path);
				if(index>=0){
					return items[index].name;
				}else{
					let endpoint= path.split("/")
					return `${endpoint[endpoint.length-1]} ${route.name}`;
				}

				
			} else {
				return route.name;
			}
		}

		for (let i = 0; i < routes.length; i++) {
			if (location.pathname.indexOf(routes[i].path) !== -1) {
				return routes[i].name;
			}
		}
		return "Dashboard";
	};
	let title = getBrandText(path);
	return (
		<Paper className={classes.paper}>
			<Toolbar>
				<Typography className={classes.title} variant="h5" noWrap>
					{title}
				</Typography>
				<div className={classes.search}>
					<InputBase
						value={searchText}
						onChange={(e) => setSearchText(e.target.value)}
						classes={{
							root: classes.inputRoot,
							input: classes.inputInput,
						}}
						placeholder="Search…"
						inputProps={{
							autoComplete: "new-password",
							"aria-label": "search",
						}}
					/>
					<IconButton
						type="button"
						color="primary"
						sx={{ p: "10px" }}
						aria-label="search"
					>
						<Search color="primary" />
					</IconButton>
				</div>

				{/* <div className={classes.search}>
          <div className={classes.searchIcon}>
            <Search color="primary" />
          </div>
        <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            endAdornment={
              searchText === "" ? null : (
                <InputAdornment position="end">
                  <IconButton
                    style={{ marginRight: 5 }}
					sx={{ p: '10px' }}
                    onClick={() => setSearchText("")}
                    size="small"
                  >
                    <Close />
                  </IconButton>
                </InputAdornment>
              )
            }
            inputProps={{
              autoComplete: "new-password",
              "aria-label": "search",
            }}
          />
        </div> */}
				<UserMenu user={user} />
			</Toolbar>
		</Paper>
	);
};
