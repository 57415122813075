import { Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import { UpdateCuration } from "../../apis/Curation";
import { pinToTopPlayoff } from "../../apis/Playoff";

function PinToTopPlayoff({ row }) {
 
  const { pin_to_top: value, id} = row;
  
  const [pinToTop, setPinToTop] = useState(value);
  const handleClick = async () => {
    await pinToTopPlayoff({ id:id, pin_to_top: !pinToTop });
    setPinToTop(!pinToTop);
  };
  useEffect(() => {
    setPinToTop(value);
  }, [value]);

  
  
  return (
    <Switch
      checked={pinToTop}
      onChange={handleClick}
      inputProps={{
        "aria-label": "controlled",
      }}
    />
  );
}

export default PinToTopPlayoff;
