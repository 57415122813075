import { Addchart, Category, DashboardOutlined, Inventory, LocalMallOutlined, Loyalty, PeopleAlt, ShoppingBasket, Store } from "@mui/icons-material";
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import Edit from "@mui/icons-material/Edit";

export const appMenuItems = [
    {
        name: "Dashboard",
        link: "/w86zjruk6q",
        slu:"/w86zjruk6q",
        Icon: DashboardOutlined,
    },
    {
        name: "Curator count",
        link: "/w86zjruk6q/details",
        slu:"/w86zjruk6q/details",
        Icon: Addchart,
    },
    {
        name: "Users",
        link: "/w86zjruk6q/user",
        slu: "/w86zjruk6q/user",
        Icon: PeopleAlt,
    },
    {
        name: "Sellers",
        link: "/w86zjruk6q/seller",
        slu: "/w86zjruk6q/seller",
        Icon: Store,
    },
    {
        name: "Brand",
        link: "/w86zjruk6q/brand",
        slu: "/w86zjruk6q/brand",
        Icon: LocalMallOutlined,
    },
    {
        name: "Product Categories",
        link: "/w86zjruk6q/product-category",
        slu: "/w86zjruk6q/product-category",
        Icon: Category,
    },
    {
        name: "List Categories",
        link: "/w86zjruk6q/listcategories",
        slu: "/w86zjruk6q/listcategories",
        Icon: Loyalty,
    },
    {
        name: "Product",
        link: "/w86zjruk6q/product",
        slu: "/w86zjruk6q/product",
        Icon: LocalMallOutlined,
    },
    {
        name: "Waitlist",
        link: "/w86zjruk6q/waitlist",
        slu: "/w86zjruk6q/waitlist",
        Icon: AccessTimeOutlinedIcon,
    },
    {
        name: "Grapelist",
        Icon: ShoppingBasket,
        slu:"/w86zjruk6q/grapelist",
        items: [
            {
                name: " Grape Lists/Stylist Lists",
                link: "/w86zjruk6q/grapelist",
                Icon: LocalMallOutlined,
            },
            {
                name: "Add Grapelist",
                link: "/w86zjruk6q/grapelist/add",
                Icon: LocalMallOutlined,
            },
        ],
    },
    {
        name: "Pesistant List",
        Icon: ShoppingBasket,
        slu:"/w86zjruk6q/persistent-list",
        items: [
            {
                name: "Persistant Lists",
                link: "/w86zjruk6q/persistent-list",
                Icon: LocalMallOutlined,
            },
            {
                name: "Add Persistant List",
                link: "/w86zjruk6q/persistent-list/add",
                Icon: LocalMallOutlined,
            },
        ],
    },
    {
        name: "Playoff",
        Icon: ShoppingBasket,
        slu:"/w86zjruk6q/playoff",
        items: [
            {
                name: "Playoffs",
                link: "/w86zjruk6q/playoff",
                Icon: LocalMallOutlined,
            },
            {
                name: "Add Playoff",
                link: "/w86zjruk6q/playoff/add",
                Icon: LocalMallOutlined,
            }
        ],
    },
    {
        name: "Curation",
        Icon: ShoppingBasket,
        slu:"/w86zjruk6q/curation",
        items: [
            {
                name: "Curations",
                link: "/w86zjruk6q/curation",
                Icon: LocalMallOutlined,
            },
            {
                name: "Add Curation",
                link: "/w86zjruk6q/curation/add",
                Icon: LocalMallOutlined,
            }
        ],
    },
   
];

export const appMenuItemsForRestrictedUser = [
    {
        name: "Product",
        link: "/upload-playoff/product",
        slu: "/upload-playoff/product",
        Icon: Inventory,
    },
    {
        name: "Playoff",
        Icon: ShoppingBasket,
        slu:"/upload-playoff/playoff",
        items: [
            {
                name: "Playoffs",
                link: "/upload-playoff/playoff",
                Icon: LocalMallOutlined,
            },
            {
                name: "Add Playoff",
                link: "/upload-playoff/playoff/add",
                Icon: LocalMallOutlined,
            }
        ],
    },
    

]