import { styled } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import {
	ContentCopyRounded,
	DeleteOutline,
	Description,
	EditOutlined,
} from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";
import {
	Avatar,
	AvatarGroup,
	Box,
	Chip,
	CircularProgress,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
} from "@mui/material";
import moment from "moment";
import LoopSharpIcon from "@mui/icons-material/LoopSharp";
import { Link } from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import {
	getPersistentList,
	refreshBestDeals,
	refreshMostPopular,
} from "../../apis/PersistentList";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { notify } from "../Playoff/PlayListTable";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.action.hover,
		fontWeight: 600,
		userSelect: "none",
		borderBottom: `1px solid ${theme.palette.background.divider}`,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
		userSelect: "none",
		borderBottom: `1px solid ${theme.palette.background.divider}`,
	},
}));

const useStyles = makeStyles()({
	table: {
		minWidth: 1280,
	},
	tableHead: {
		position: "sticky",
		top: 0,
		zIndex: 1,
		"& : last-child": {
			width: "10%",
		},
	},
});

export default function DataTable({
	rows,
	handleDelete,
	loading,
	handlePage,
	handleRowsCount,
	count,
	page,
	rowsCount,
	setRows,
}) {
	const { classes } = useStyles();
	console.log(page * rowsCount, (page + 1) * rowsCount);
	const [open, setOpen] = useState(false);
	const [activeSelection, setActiveSelection] = useState(null);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	const refresh = (row) => {
		handleClickOpen();
		setActiveSelection(row.title);
	};

	const refreshHandler = async () => {
		let res;
		if (activeSelection === "Community Favourites") {
			res = await refreshMostPopular();
		} else {
			res = await refreshBestDeals();
		}
		if (res.success) {
			const data = await getPersistentList(0);
			setRows(data.results);
			setOpen(false);
		}
	};
	return (
		<>
			<TableContainer component={Paper}>
				<Table className={classes.table} aria-label="customized table">
					<TableHead className={classes.tableHead}>
						<TableRow>
							{[
								"S No.",
								"id",
								"Slug",
								"Name",
								"List Type",
								"Collage",
								"Description",
								"products",
								"listCategories",
								"Action",
							].map((header, i) => (
								<StyledTableCell key={`table-head-${i}`}>
									{header}
								</StyledTableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row, i) => (
							<TableRow key={i + 1}>
								<StyledTableCell>
									{page * rowsCount + i + 1}
								</StyledTableCell>
								<StyledTableCell>{row.id}</StyledTableCell>
								<StyledTableCell>
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
										}}
									>
										<Typography variant="p" component={"p"}>
											{row.slug}
										</Typography>

										<CopyToClipboard
											text={row.slug}
											onCopy={() => notify("copied")}
										>
											<IconButton
												size="small"
												color="primary"
											>
												<ContentCopyRounded />
											</IconButton>
										</CopyToClipboard>
									</Box>
								</StyledTableCell>
								<StyledTableCell
									style={{ minWidth: 70, maxWidth: 150 }}
								>
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
										}}
									>
										<Typography variant="p" component={"p"}>
											{row.title}
										</Typography>

										<CopyToClipboard
											text={row.title}
											onCopy={() => notify("copied")}
										>
											<IconButton
												size="small"
												color="primary"
											>
												<ContentCopyRounded />
											</IconButton>
										</CopyToClipboard>
										</Box>
								</StyledTableCell>
								<StyledTableCell
									style={{ minWidth: 70, maxWidth: 100 }}
								>
									{row.list_type}
								</StyledTableCell>
								<StyledTableCell
									style={{ minWidth: 120, maxWidth: 150 }}
								>
									<Avatar
										alt="List Logo"
										variant="square"
										sx={{
											width: 150,
											height: 150,
											objectFit: "fill",
										}}
										src={row.image?.file}
									>
										S
									</Avatar>
								</StyledTableCell>
								<StyledTableCell
									style={{ minWidth: 120, maxWidth: 150 }}
								>
									{row.description}
								</StyledTableCell>
								<StyledTableCell
									style={{ minWidth: 120, maxWidth: 150 }}
								>
									<AvatarGroup
										sx={{
											justifyContent: "center",
										}}
										total={row.products?.length}
									>
										{row.products.slice(0, 3).map((p) => (
											<Avatar
												alt={p.name}
												src={p.image.file}
											/>
											//<p>{p.image.file}</p>
										))}
									</AvatarGroup>
								</StyledTableCell>
								<StyledTableCell
									style={{ minWidth: 120, maxWidth: 150 }}
								>
									{row.list_category.map((p) => (
										<p>{p.name}</p>
									))}
								</StyledTableCell>

								<StyledTableCell>
									<CopyToClipboard
										text={`https://www.grape.town/list/${row.slug}`}
										onCopy={() => notify("copied")}
									>
										<IconButton
											size="small"
											color="primary"
										>
											<ContentCopyRounded />
										</IconButton>
									</CopyToClipboard>
									<IconButton
										color="primary"
										href={`/w86zjruk6q/persistent-list/${row.id}/edit/`}
									>
										<EditOutlined />
									</IconButton>
									{(row.title === "Community Favourites" ||
										row.title === "Curated Deals") && (
										<>
											<Tooltip
												title={
													<Typography
														sx={{
															fontSize: "14px",
														}}
													>{`Refresh ${row.title}`}</Typography>
												}
												arrow
											>
												<Button
													color="primary"
													variant="contained"
													sx={{
														borderRadius: "50%",
														paddingX: 0,
														width: "40px",
														minWidth: "40px",
														height: "40px",
														marginX: 1,
													}}
													onClick={() => {
														refresh(row);
													}}
												>
													<LoopSharpIcon />
												</Button>
											</Tooltip>

											<Chip
												sx={{ marginY: 2 }}
												label={`Last Updated at: ${moment(
													row.updated_at
												)
													.utcOffset("+05:30")
													.format(
														"DD-MM-YYYY hh:mm:ss A"
													)}`}
												color="secondary"
											/>
										</>
									)}
									{/* <IconButton
										color="secondary"
										onClick={() =>
											handleDelete(page * rowsCount + i)
										}
									>
										<DeleteOutline />
									</IconButton> */}
								</StyledTableCell>

								{/* <StyledTableCell style={{ minWidth: 120, maxWidth: 150 }}>
									{row["logo"]}
								</StyledTableCell>
								
								<StyledTableCell style={{ minWidth: 100, maxWidth: 100 }}>{(new Date(row["created_time"])).toString().substring(4, 24)}</StyledTableCell>
								<StyledTableCell style={{ minWidth: 100, maxWidth: 100 }}>{(new Date(row["last_updated_time"])).toString().substring(4, 24)}</StyledTableCell>
								
								<StyledTableCell>
									{row["images"].slice(0, 2).map(image => (
										<img src={image} alt="" width='50px' style={{ margin: 5, borderRadius: 5 }} />))
									}
								</StyledTableCell> */}
								{/* <StyledTableCell>
									<IconButton color='primary' onClick={() => handleEdit(i)}>
										<EditOutlined />
									</IconButton>
									<IconButton color='secondary' onClick={() => handleDelete(page * rowsCount + i)}>
										<DeleteOutline />
									</IconButton>
								</StyledTableCell> */}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				sx={{
					"& p": {
						m: 0,
					},
				}}
				rowsPerPageOptions={[10, 25, 100]}
				component="div"
				count={count}
				rowsPerPage={rowsCount}
				page={page}
				onPageChange={handlePage}
				onRowsPerPageChange={(e) => handleRowsCount(e.target.value)}
			/>
			{loading && (
				<Box display="flex" width="100%" justifyContent="center" p={3}>
					<CircularProgress />
				</Box>
			)}

			<Dialog open={open} onClose={handleClose}>
				<DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Refreshing {activeSelection} will removed all previous
						products and update it with new recent products.
						<br></br>Do you wish to continue?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={refreshHandler} autoFocus>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
