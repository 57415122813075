import { Divider, Grid } from "@mui/material";
import React from "react";
import ProductCard from "./ProductCard";

function SingleList(props) {
  const { singleList, setProducts, products ,quantity_limit} = props;
  return (
    <>
      {" "}
      <Divider sx={{ marginTop: 6 }} />
      <Grid container spacing={2} sx={{ marginTop: 2.5 }}>
        {singleList.map((item) => {
          return (
            <Grid item md={3} key={item.id}>
              <ProductCard 
                product={item}
                setProducts={setProducts}
                products={products}
                quantity_limit={quantity_limit}
              ></ProductCard>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}

export default SingleList;
