import {
	Close,
	ExpandMore,
	Favorite,
	FavoriteBorder,
} from "@mui/icons-material";
import {
	Box,
	Button,
	Card,
	CardHeader,
	Grid,
	IconButton,
	Rating,
	Stack,
	Typography,
} from "@mui/material";

import { useEffect, useState } from "react";

import { getRatingsByList } from "../../apis/Curation";
import { RatingCard } from "./RatingCard";

export const CurationReview = ({ curation, reviews, setOpen }) => {
	const [avgRating, setAvgRating] = useState(0);
	const [totalRatings, setTotalRatings] = useState(0);
	const [ratings, setRatings] = useState([]);
	const [page, setPage] = useState(0);
	const [hasNext, setHasNext] = useState(false);
	useEffect(() => {
		setAvgRating(curation?.avg_rating.avg_rating);
		setTotalRatings(curation?.ratings);
		if (curation) {
			getRatings();
		}
	}, [curation]);
	let getRatings = async () => {
		let res = await getRatingsByList(curation.id, page + 1);
		setRatings([...ratings, ...res.data.results]);
		setHasNext(res.data.next != null);
		setPage(res.data.next != null ? page + 1 : page);
	};

	return (
		<Box sx={{ flexGrow: 1 }}>
			<Card sx={{ pl: 5 }}>
				<CardHeader
					title={`Rating for Curation ${curation?.title}`}
					action={
						<IconButton
							// style={{ position: "fixed" }}
							onClick={() => setOpen(false)}
						>
							<Close />
						</IconButton>
					}
					subheader={
						<Grid
							container
							direction="row"
							justifyContent="flex-start"
							alignItems="flex-end"
						>
							<Rating
								sx={{
									"& .MuiRating-iconFilled": {
										color: "#9650FA",
									},
									"& .MuiRating-iconHover": {
										color: "#ff3d47",
									},
								}}
								name="text-feedback"
								value={avgRating / 2}
								readOnly
								// max={10}
								// size="large"
								precision={0.1}
								icon={<Favorite fontSize="inherit" />}
								emptyIcon={
									<FavoriteBorder fontSize="inherit" />
								}
							/>
							<Typography
								variant="caption"
								display="block"
								gutterBottom
								mb={0}
								ml={1}
							>
								{totalRatings} Ratings
							</Typography>
						</Grid>
					}
				></CardHeader>
			</Card>
			<Box>
				{ratings.map((rating,i) => {
					return (
						
							<RatingCard rating={rating} key={`listrating-${i}`}/>
						
					);
				})}
				{hasNext && (
					<Stack direction={"row"} justifyContent={"center"}>
						<Button
							variant="text"
							color="primary"
							sx={{ fontWeight: "700" }}
							onClick={getRatings}
						>
							Load More <ExpandMore></ExpandMore>
						</Button>
					</Stack>
				)}
			</Box>
		</Box>
	);
};
