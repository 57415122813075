import { styled } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { DeleteOutline, Description, EditOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
  Button,
} from "@mui/material";
import { Link } from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { useState } from "react";
import MenuItem from "@mui/material/MenuItem";

import Select from "@mui/material/Select";
import { getPlayoffDetails } from "../../../apis/Playoff";
// import { getPlayoffProductDetails } from "../../../apis/Api";
import { getUserShareDetails } from "../../../apis/Api";
import CustomeCellData from "../CustomeCellData/CustomeCellData";
import UserCustomeCellData from "../CustomeCellData/UserCustomeCellData";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    fontWeight: 600,
    userSelect: "none",
    borderBottom: `1px solid ${theme.palette.background.divider}`,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    userSelect: "none",
    borderBottom: `1px solid ${theme.palette.background.divider}`,
  },
}));

const useStyles = makeStyles()({
  table: {
    minWidth: 1280,
  },
  tableHead: {
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
});

function UserViewTable({
  rows,
  loading,
  handlePage,
  handleRowsCount,

  count,
  page,
  rowsCount,
  setRows,
  filter,
}) {
  const { classes } = useStyles();
  console.log(page * rowsCount, (page + 1) * rowsCount);

  const getUserDetails = async (id) => {
    let data = await getUserShareDetails(filter, id);
    console.log("=============");
    console.log("data", data);
    console.log("=============");
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead className={classes.tableHead}>
            <TableRow>
              {["S No.", "Name", "User Share Count"].map((header, i) => (
                <StyledTableCell key={`table-head-${i}`}>
                  {header}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, i) => (
              <TableRow key={i + 1}>
                <StyledTableCell style={{ textAlign: "center" }}>
                  {page * rowsCount + i + 1}{" "}
                </StyledTableCell>
                <StyledTableCell
                  style={{ minWidth: 70, maxWidth: 150, textAlign: "center" }}
                >
                  <a href={`https://www.grape.town/community/${row.user.slug}`} target="_blank" rel="noopener noreferrer">
                  {row.user.name}
                </a>
                  
                </StyledTableCell>
                

                {/* <StyledTableCell
                  style={{ minWidth: 120, maxWidth: 150, textAlign: "center" }}
                >
                  {row.total_share}
                </StyledTableCell> */}
                {/* <StyledTableCell
                  style={{ minWidth: 120, maxWidth: 150, textAlign: "center" }}
                >
                  {row.shared_by.map((p) => (
										<p>{p}</p>
									))}
                  
                </StyledTableCell> */}
                <StyledTableCell style={{ minWidth: 120, maxWidth: 150 , textAlign:"center"}} >                  
                  <UserCustomeCellData row={row} filter={filter}  ></UserCustomeCellData>
                </StyledTableCell>
                
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{
          "& p": {
            m: 0,
          },
        }}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={count}
        rowsPerPage={rowsCount}
        page={page}
        onPageChange={handlePage}
        onRowsPerPageChange={(e) => handleRowsCount(e.target.value)}
      />
      {loading && (
        <Box display="flex" width="100%" justifyContent="center" p={3}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
}

export default UserViewTable;
