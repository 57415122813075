import  { useEffect, useState } from 'react';

import { makeStyles } from "tss-react/mui";
import { Add } from '@mui/icons-material';
import { Box, Button, Drawer, Toolbar, Typography } from '@mui/material';
import Table from '../../components/Seller/SellerTable';
import { ToastContainer, toast } from 'react-toastify';

import _ from 'lodash';
import { EditSellerDrawer } from '../../components/Seller/EditSellerDrawer';
import { deleteSeller, getAllSeller } from '../../apis/Seller';

const useStyles = makeStyles()((theme) => ({
	title: {
		flexGrow: 1,
		display: 'none',
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
	},
}));

export const Seller = ({ searchText }) => {
	const {classes} = useStyles();

	const [editOpen, setEditOpen] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [rows, setRows] = useState([]);
	const [seller, setSeller] = useState({name:"",website:"",locality:"",id:'' });
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsCount, setRowsCount] = useState(10);
	const [count, setCount] = useState(0);

	
	useEffect(() => {
		setLoading(true);
		_.debounce(async () => {
			const data = await getAllSeller(rowsCount);
			setRows(data.results);
            console.log(data);
			// const cnt = await getCount(searchText, 'product');
			setCount(data.count);
		}, 500)();
		setLoading(false);
	}, [searchText])

	const handleEditorClose= async()=>{
		setEditOpen(false)
		const data = await getAllSeller(rowsCount,page+1);
		setRows(data.results);
	}

	const notify = () => toast.error(`Some unexpected error occured`, {
		position: "bottom-right",
		autoClose: 2500,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});

	const handleAdd = () => {
		setIsEdit(false);
		setSeller({name:"",website:"",locality:"",id:'' });
		setEditOpen(true);
	}

	const handleEdit= (id) => {
		setIsEdit(true)
		setSeller(rows.find(row=>row.id===id));
		setEditOpen(true);
	}

	const handleDelete = async (id) => {
		setLoading(true);
		const res = await deleteSeller(id);
		if (res.status !== 200) {
			notify()
			return;
		}
		const data = await getAllSeller();
		setRows(data.results);
		setLoading(false);
	}

	const handlePage = async (_, i) => {
		const data = await getAllSeller(rowsCount, i + 1);
		setRows(data.results);
		setPage(i);
	};

	const handleRowsCount = async (i) => {
		setRowsCount(i);
		setLoading(true);
		const data = await getAllSeller(i);
		setRows(data.results);
		setLoading(false);
	};
	return (
		<>
            
			<Toolbar>
                
				<Typography className={classes.title} variant="h4" noWrap />
				<Button
					onClick={() => handleAdd()}
					startIcon={<Add />}
					variant='contained'
					color='primary'
				>
					Add Seller
				</Button>
			</Toolbar>
			<ToastContainer />
            <Box sx={{ px: 5 }}>
			<Table  handleEdit={handleEdit} handleDelete={handleDelete} page={page} rowsCount={rowsCount} handlePage={handlePage} handleRowsCount={handleRowsCount} count={count} rows={rows} loading={loading} />
			<Drawer anchor='right' open={editOpen} onClose={handleEditorClose}>
				<EditSellerDrawer  edit={isEdit} setEditOpen={setEditOpen} seller={seller} setSeller={setSeller} setRows={setRows} />
			</Drawer>
			
            </Box>
		</>
	)
}