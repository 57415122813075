import {
    Box,
    Card,
    IconButton,
    ImageListItem,
    ImageListItemBar,
    Typography,
  } from "@mui/material";
  import React, { useState } from "react";
  import ControlPointIcon from "@mui/icons-material/ControlPoint";
  import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
  import { useEffect } from "react";
  import { ToastContainer, toast } from "react-toastify";
  
  function ProductCardGroupList(props) {
    const { product, setProducts, products ,tempGroupList,setTempGroupList,quantity_limit} = props;
    const [button, setButton] = useState(products.includes(product.id)?<RemoveCircleIcon />:<ControlPointIcon />);
    const [added, setAdded] = useState(products.includes(product.id));

    const notify = (m) =>
    toast.error(m, {
      position: "bottom-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
   
    
  
  
    const handelClick = () => {     
      if(quantity_limit>0){       
            if (!added) {
                if(tempGroupList.length<quantity_limit){
                    setButton(<RemoveCircleIcon />);
                    setProducts((values) => [...values, product.id]);
                    setTempGroupList((values) => [...values, product.id]);

                }  else{
                    console.log("Limit Exceeded for group")
                    notify("Limit Exceeded for group");
                    return;
                }      
               
              } else {
                // console.log("Remove case:")
                // console.log(products)
                // console.log(tempGroupList)
                setButton(<ControlPointIcon />);
                let arr = [...products];
                var index = arr.indexOf(product.id);
                if (index !== -1) {
                  arr.splice(index, 1);
                  setProducts(arr);
                }
                let arr2=[...tempGroupList];
                var index1 = arr2.indexOf(product.id);
                if (index1 !== -1) {
                    arr2.splice(index1, 1);
                   
                    setTempGroupList(arr2);
                  }
              }
              setAdded(!added);         
          
      }
      if(quantity_limit===0){
        if (!added) {
            setButton(<RemoveCircleIcon />);
            setProducts((values) => [...values, product.id]);
          } else {
            setButton(<ControlPointIcon />);
            let arr = [...products];
            var index = arr.indexOf(product.id);
            if (index !== -1) {
              arr.splice(index, 1);
              setProducts(arr);
            }
          }
          setAdded(!added);

      }
      
    };
  
    return (
      <Box onClick={handelClick} sx={{ height: "100%" }}>
        <Card sx={{ height: "100%", borderRadius: "8px" }}>
          <ImageListItem
            sx={{
              "& .MuiImageListItem-img": {
                height: 216,
                borderRadius: "8px 8px 0 0",
              },
            }}
          >
            <img src={product.image.file} alt={"Product image"} loading="lazy" />
            <ImageListItemBar
              sx={{
                background: "transparent",
              }}
              position="top"
              actionIcon={
                <IconButton
                  size="small"
                  sx={{
                    "& .MuiIconButton-sizeSmall": {},
                    backgroundColor: "white",
                    padding: "6px",
                    margin: 1,
                    display: props.displayButton,
                  }}
                >
                  {button}
                </IconButton>
              }
            />
          </ImageListItem>
  
          <Typography sx={{ padding: 2, marginTop: "3px" }}>
            {product.name}
          </Typography>
        </Card>
      </Box>
    );
  }
  
  export default ProductCardGroupList;
  