import React, { useEffect, useState } from "react";
import { Close, Delete, Edit, Info } from "@mui/icons-material";
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Drawer,
	FormLabel,
	Grid,
	IconButton,
	TextField,
	Typography,
} from "@mui/material";
import {  useForm } from "react-hook-form";

import {  toast } from "react-toastify";

import { CategoryAutocomplete } from "../Form/CatetoryAutoComplete";
import { AddProductDrawer } from "../GrapeList/AddProductDrawer";
import { Create, UpdateGroup } from "../../apis/Group";

export const AddGroup = ({
	title,
	setEditOpen,
	seller,
	groups,
	edit,
	setGroups,
	group,
	setGroup,
}) => {
	const {
		register,
		handleSubmit,

		setValue,
		formState: { errors },
	} = useForm();

	const [category, setCategory] = useState(
		edit ? group.product_category[0] : null
	);

	const [showCategory, setShowCategory] = useState(false);
	const [loading, setLoading] = useState(false);
	const [deletingProduct, setDeletingProduct] = useState({});
	const [localGroup, setLocalGroup] = useState(group);
	// const [groupName, setGroupName] = useState(edit ? group.name : "");

	const [products, setProducts] = useState(edit ? group.products : []);
	const [addProduct, setAddProduct] = useState(false);
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setLocalGroup({ ...localGroup, [name]: value });
	};

	const [open, setOpen] = useState(false);

	const handleOpen = (item) => {
		setOpen(true);
		setDeletingProduct(item);
	};
	const handleClose = () => setOpen(false);
	
	const onSubmit = async (data) => {
		console.log(data);
		data["products"] = products.map((g) => g.id);
		if (category == null) {
			notify("Please Select Group Category");
			setShowCategory(true);
			return;
		}
		data.product_category = category.id;
		if (products.length === 0) {
			setAddProduct(true);
			notify("Please select Products");

			return;
		}
		console.log(data);
		let pproducts;
		if (Array.isArray(data["products"])) {
			pproducts = data["products"].map((p) => parseInt(p));
		} else {
			pproducts = parseInt(data["products"]);
		}
		let formData = new FormData();
		for (let i in data) {
			formData.append(i, data[i]);
		}

		formData["products"] = [pproducts];

		let res = edit
			? await UpdateGroup(formData, group.id)
			: await Create(formData);
		if (res.success) {
			if (edit) {
				
				let tempgroups=[...groups]
				let index = tempgroups.findIndex((grou) => grou.id === group.id);
				tempgroups[index] = res;
				setGroups(tempgroups);
			} else {
				setGroups([...groups, res]);
			}

			setEditOpen(false);
		}
		// form= new FormData()
	};

	const notify = (m) =>
		toast.error(m, {
			position: "top-right",
			autoClose: 2500,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});

	useEffect(() => {
		if (category) {
			setValue("name", category?.name);
			setLocalGroup({ ...localGroup, name: category?.name });
			// setGroupName(category.name);
		}
	}, [category]);

	// const handleURL = async (e) => {

	// 	setUrl(e.target.value);
	// 	if(e.target.value){
	// 	const data = await getProductPreview(e.target.value);
	// 	setProductPreview(data.data);
	// 	}
	// }

	return (
		<div
			style={{
				width: addProduct ? 800 : 600,
				padding: 5,
				height: "100vh",
				overflowY: "scroll",
			}}
		>
			<IconButton
				style={{ position: "fixed" }}
				onClick={() => setEditOpen(false)}
			>
				<Close />
			</IconButton>
			{/* <ToastContainer /> */}
			<div style={{ padding: "45px 55px 0px" }}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Box display="flex" justifyContent="space-between">
						<Typography
							variant="h6"
							gutterBottom
						>{`Group`}</Typography>
						<Button
							type="submit"
							// onClick={handleSave}
							color="primary"
							variant="contained"
						>
							{title === "Edit" ? "Update" : "Add"}
						</Button>
					</Box>
					<Box my={3}>
						<FormLabel>Group Category</FormLabel>
						<CategoryAutocomplete
							title="Group Category"
							showCategory={showCategory}
							setShowCategory={setShowCategory}
							category={category}
							setCategory={setCategory}
						/>
						{/* <Controller
							control={control}
							name="product_category"
							rules={{ required: true }}
							render={({ field: { onChange, value } }) => (
								<CategoryAutocomplete
									setName={setGroupName}
									name={"Product Category"}
									onChange={onChange}
									value={value}
									errors={errors}
									existingProduct={null}
									errortype={errors.product_category}
									errormessage={
										"Please select product category"
									}
								></CategoryAutocomplete>
							)}
						/> */}
					</Box>

					<Box mb={3}>
						<TextField
							{...register("name", {
								required: "Enter group name",
							})}
							onChange={handleInputChange}
							fullWidth
							label="Enter Group Name"
							variant="outlined"
							value={localGroup.name}
							error={Boolean(errors.name)}
							helperText={errors.name?.message}
						/>
					</Box>
					<Box my={3}>
						<TextField
							fullWidth
							name="description"
							multiline
							rows={4}
							// style={{ width: "100%" }}
							label="Enter description"
							variant="outlined"
							{...register("description", {
								required: "Enter description",
							})}
							value={localGroup.description}
							onChange={handleInputChange}
							error={Boolean(errors.description)}
							helperText={errors.description?.message}
						/>
					</Box>
					<Box mb={3}>
						<TextField
							fullWidth
							type="number"
							InputProps={{
								inputProps: { min: 0 },
							}}
							id="outlined-basic"
							label={"Qty Limit by Group"}
							variant="outlined"
							value={localGroup.qty_limit}
							{...register("qty_limit", {
								required: "Enter qty limit",
							})}
							onChange={handleInputChange}
						/>
					</Box>

					<Box
						my={3}
						// display="flex"
						// justifyContent="right"
						// alignItems="right"
					>
						<Box display="flex" justifyContent="space-between">
							<Typography
								variant="h6"
								gutterBottom
							>{`Products`}</Typography>
							<Button
								type="button"
								onClick={() => {
									if (category == null) {
										notify("Please Select Group Category");
										setShowCategory(true);
										return;
									}
									setAddProduct(true);
								}}
								color="primary"
								variant="contained"
							>
								Add products
							</Button>
						</Box>
						{products && (
							<Box my={3}>
								<Grid container spacing={2}>
									{products.map((item, i) => (
										<Grid
											item
											xs={12}
											md={products.length <= 2 ? 6 : 4}
										>
											<Card
												sx={{
													height: "100%",
													display: "flex",
													justifyContent:
														"space-between",
													flexDirection: "column",
												}}
											>
												<CardMedia
													component="img"
													// height="194"
													image={item.image.file}
													alt="Paella dish"
												/>
												<CardContent>
													<Typography
														variant="body2"
														color="text.secondary"
													>
														{item.name}
													</Typography>
												</CardContent>
												<CardActions
													disableSpacing
													sx={{
														display: "flex",
														alignItems: "end",
													}}
												>
													<IconButton
														aria-label="delete product"
														onClick={() => {
															handleOpen(item);
															// setProducts(new_products)
														}}
													>
														<Delete />
													</IconButton>
												</CardActions>
											</Card>
										</Grid>

										// <ImageListItem key={`item-${i}`}>
										// 	<img
										// 		src={`${item.image}?w=164&h=164&fit=crop&auto=format`}
										// 		srcSet={`${item.image}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
										// 		alt={item.name}
										// 		loading="lazy"
										// 	/>
										// 	<ImageListItemBar
										// 		title={item.name}
										// 		subtitle={item.author}
										// 		actionIcon={
										// 			<IconButton
										// 				sx={{
										// 					color: "rgba(255, 255, 255, 0.54)",
										// 				}}
										// 				aria-label={`info about ${item.title}`}
										// 			>
										// 				<Edit />
										// 			</IconButton>
										// 		}
										// 	/>
										// </ImageListItem>
									))}
								</Grid>
							</Box>
						)}
					</Box>
				</form>
			</div>
			<Drawer
				anchor="right"
				open={addProduct}
				onClose={() => setAddProduct(false)}
			>
				<AddProductDrawer
					setEditOpen={setAddProduct}
					products={products}
					setProducts={setProducts}
					isProductOpen={addProduct}
					fromGroup={true}
					selectedCategory={category}
					
				/>
			</Drawer>

			<Dialog
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={open}
				onClose={handleClose}
				closeAfterTransition
				// BackdropComponent={Backdrop}
				// BackdropProps={{
				// 	timeout: 500,
				// }}
			>
				<DialogTitle id="alert-dialog-title">
					{"Are you sure?"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Do you want to remove product
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>No</Button>
					<Button
						onClick={() => {
							let new_products = products.filter((product) => {
								return product !== deletingProduct;
							});
							setDeletingProduct({});
							setProducts(new_products);
							handleClose();
						}}
						autoFocus
						color="secondary"
					>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};
