import { BaseURLwithEndPoint } from "./config";
// import axios from "axios";
import { axiosInstance } from "../config/axios";

let base = BaseURLwithEndPoint();


const handlerEnabled = true

const endpoint =  "/playoff/";

export const Create = async (params) => {
	try {
		const res = await axiosInstance.post("playoff/", params);
		console.log("====================================");
		console.log(res);
		console.log("====================================");
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};

export const getAllPlayoffs = async (row,page=1) => {
	try {
		const res =  await axiosInstance.get(endpoint,{params:{page_size:row,page:page},handlerEnabled});
		console.log(res);
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		console.log('====================================');
		console.log(e);
		console.log('====================================');
		let data = {
			success: false,
		};
		return data;
	}
};


export const getPlayoffDetails = async (id) => {
	try {
		const res = await axiosInstance.get(`${endpoint}${id}/`);

		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};

export const getStylistUsers = async () => {
	try {
		let url = `${base}stylist/`;
		const res = await axiosInstance.get(url);

		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};

export const setActiveInactivePlayoff = async (data) => {
	// const api = `listcategory/${data.id}/`;
	const res = await axiosInstance.put(`${endpoint}${data.id}/`, data);
	return res.data;
};

export const pinToTopPlayoff = async (data)=>{
	// var form_data = new FormData();

	// form_data.append('pin_to_top', data.pin_to_top)
	// form_data.append('title',data.title)
	// form_data.append('is_playoff',data.is_playoff)
	// form_data.append('active',data.active)
	// console.log(data);
	const res = await axiosInstance.post(`/pin-to-top-playoff/`, data);
	return res.data;
}


export const UpdatePlayoff = async (params,id) => {
	try {
		const res = await axiosInstance.put(`${endpoint}${id}/`, params);
		console.log("====================================");
		console.log(res);
		console.log("====================================");
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};

export const handleRetire = async (params,id) => {
	try {
		const res = await axiosInstance.post(`handle-retire/${id}`, params);
		console.log("====================================");
		console.log(res);
		console.log("====================================");
		
		
		return res;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};

export const getUserCountDetails = async (row,page=1,filters={},order_by=null) => {
	try {
		const res =  await axiosInstance.get('/details',{params:{page_size:row, page: page,order_by,...filters},handlerEnabled});
		console.log(res);
		return res.data;
	} catch (e) {
		console.log('====================================');
		console.log(e);
		console.log('====================================');
		let data = {
			success: false,
		};
		return data;
	}
};

export const send_playoff_notification = async (id) => {
	try {
		const res =  await axiosInstance.get(`/playoff_notifications/${id}`);
		console.log(res);
		return res.data;
	} catch (e) {
		console.log('====================================');
		console.log(e);
		console.log('====================================');
		let data = {
			success: false,
		};
		return data;
	}
};



export const fetchAestheticList= async (query)=>{
	const res =  await axiosInstance.get(`/aesthetic/?q=${query}`);
	return res.data
}

export const createAesthetic= async (data)=>{
	const res =  await axiosInstance.post(`/aesthetic/`,data);
	return res.data
}