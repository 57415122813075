import { useEffect, useState } from "react";

import { makeStyles } from "tss-react/mui";
import _ from "lodash";
import { ToastContainer, toast } from "react-toastify";

import { Controller, useForm } from "react-hook-form";
import {
	Box,
	Button,
	Typography,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
	Grid,
	Avatar,
	Stack,
	FormLabel,
	Switch,
} from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import {  getPlayoffDetails } from "../../apis/Playoff";
import {
	getAllUser,
	getCurationDetails,
	UpdateCuration,
} from "../../apis/Curation";
import Grouplist from "../../components/Curation/Grouplist";
import SingleList from "../../components/Curation/SingleList";
import { ImageCropper } from "../../components/CommonFunctions/ImageCropper";

const useStyles = makeStyles()((theme) => ({
	title: {
		flexGrow: 1,
		display: "none",
		[theme.breakpoints.up("sm")]: {
			display: "block",
		},
	},
}));

export const EditCuration = ({ searchText }) => {
	const navigate = useNavigate();
	

	const [loading, setLoading] = useState(false);
	const [isGrouped, setIsGrouped] = useState(false);
	const [products, setProducts] = useState([]);
	
	const [group, setGroup] = useState([]);
	

	const [playoffs, setPlayoffs] = useState([]);
	const [selectedPlayoff, setSelectedPlayoff] = useState(null);
	const [users, setUsers] = useState([]);
	const [singleList, setSingleList] = useState([]);
	const [singleListQuantity, setSingleListQuantity] = useState(0);
	const [groupList, setGroupList] = useState([]);
	const { id } = useParams();

	// new states for edit page

	const [curationTitle, setCurationTitle] = useState("");
	const [ownerId, setOwnerId] = useState("");
	const [playoffId, setPlayoffId] = useState("");
	const [startDate, setStartDate] = useState();
	const [curationImage, setCurationImage] = useState(null);
	const [filename, setFilename] = useState("");
	const [crop, setCrop] = useState(false);
	const [active, setActive] = useState(false);
	const [insta, setInsta] = useState(false);

	

	useEffect(() => {
		setLoading(true);
		_.debounce(async () => {
			let curationDetails = await getCurationDetails(id);
			let playoff = await getPlayoffDetails(curationDetails.playoff.id);
			setSelectedPlayoff(curationDetails.playoff);
			setOwnerId(curationDetails.owner.id);
			setPlayoffId(curationDetails.playoff.id);
			setValue("title", curationDetails.title);
			setCurationTitle(curationDetails.title);
			setValue("owner_id", curationDetails.owner.id);
			setOwnerId(curationDetails.owner.id);
			setValue("playoff", curationDetails.playoff.id);
			setPlayoffId(curationDetails.playoff.id);
			setInsta(curationDetails.tag_in_insta)
			setValue("creation_date_time", curationDetails.creation_date_time!==null?curationDetails.creation_date_time:curationDetails.created_at);
			setStartDate(dayjs(curationDetails.creation_date_time!==null?curationDetails.creation_date_time:curationDetails.created_at));
			setValue("active", curationDetails.active);
			setActive(curationDetails.active);

			setCurationImage(curationDetails.image.file);

			let tempProdArr = [];
			curationDetails.products.map((item) => {
				tempProdArr.push(item.id);
			});
			console.log(tempProdArr);
			setProducts(tempProdArr);

			let users = await getAllUser();
			setUsers(users);
			if (playoff.grouped_list) {
				setIsGrouped(true);
				setGroupList(playoff.groups);
			} else {
				setIsGrouped(false);
				setSingleList(playoff.products);
				setSingleListQuantity(playoff.qty_limit);
			}
			setPlayoffs([playoff]);
		}, 500)();
		setLoading(false);
	}, []);


	const {
		register,
		handleSubmit,
		setValue,
		control,
		
		formState: { errors },
	} = useForm();

	const notify = (m) =>
		toast.error(m, {
			position: "bottom-right",
			autoClose: 2500,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});

	const handleOnChange = (event) => {
		const newImage = event.target?.files?.[0];
		console.log(newImage);
		if (newImage) {
			// console.log(newImage);
			setFilename(newImage.name);
			setCurationImage(URL.createObjectURL(newImage));
			setCrop(true);
		}
	};

	const onSubmit = async (data) => {
		if (!products.length) {
			notify("Please Add products");
			return;
		}
		data["grouped_list"] = isGrouped;

		data["creation_date_time"] = startDate.format();
		
		let formData = new FormData();
		for (let i in data) {
			if (i === "file") {
				console.log(i, data[i]);
				if (data[i].length) {
					let blob = await fetch(curationImage).then((r) => r.blob());
					formData.append("file", blob, filename);
				}
			} else formData.append(i, data[i]);
		}
		data["products"] = products.map((g) => parseInt(g));
		formData.append('products',data["products"]);
		formData.append('tag_in_insta',insta);
		console.log(data);
		let res = await UpdateCuration(formData, id);
		if (res.success) {
			navigate("/w86zjruk6q/curation");
		}
	};

	return (
		<Grid container justifyContent="center" spacing={2}>
			<ToastContainer />
			<Grid item xs={12} md={8}>
				<Box sx={{ px: 5 }}>
					<div style={{ padding: "45px 55px 0px" }}>
						<form onSubmit={handleSubmit(onSubmit)}>
							<Box display="flex" justifyContent="space-between">
								<Typography variant="h6" gutterBottom>
									Edit Curation
								</Typography>
							</Box>

							<Box my={3}>
								<TextField
									fullWidth
									name="title"
									label="Enter Curation title"
									variant="outlined"
									error={Boolean(errors.title)}
									helperText={errors.title?.message}
									value={curationTitle}
									{...register("title", {
										onChange: (e) => {
											setCurationTitle(e.target.value);
										},
										required: "Enter List name",
									})}
								/>
							</Box>

							<Box my={3}>
								<Grid
									container
									spacing={2}
									justifyContent={"center"}
								>
									<Grid
										item
										container
										spacing={2}
										md={6}
										xs={6}
									>
										<Grid item xs={12}>
											<FormControl
												fullWidth
												error={Boolean(errors.owner_id)}
											>
												<InputLabel htmlFor="user">
													User
												</InputLabel>
												<Select
													{...register("owner_id", {
														required: "Select User",
														onChange: (e) =>
															setOwnerId(
																e.target.value
															),
													})}
													input={
														<OutlinedInput label="User" />
													}
													// defaultValue={""}
													value={ownerId}
												>
													{users.map((user, i) => {
														return (
															<MenuItem
																key={`user-${i}`}
																value={user.id}
															>
																{user.name}
															</MenuItem>
														);
													})}
												</Select>

												<FormHelperText>
													{errors.owner_id &&
														errors.owner_id
															?.message}
												</FormHelperText>
											</FormControl>
										</Grid>
										<Grid item xs={12}>
											<FormControl
												fullWidth
												error={Boolean(errors.playoff)}
											>
												<InputLabel htmlFor="playoff">
													Playoff
												</InputLabel>
												<Select
													{...register("playoff", {
														required:
															"Select Playoff",
														onChange: (e) => {
															setPlayoffId(
																e.target.value
															);
															let selectedPlayoff =
																playoffs.find(
																	(item) =>
																		item.id ==
																		e.target
																			.value
																);
															setSelectedPlayoff(
																selectedPlayoff
															);
															setValue(
																"creation_date_time",
																selectedPlayoff.play_off_start_date_time
															);
															setStartDate(
																dayjs(
																	selectedPlayoff.play_off_start_date_time
																)
															);
															setIsGrouped(
																selectedPlayoff.grouped_list
															);
															setSingleList(
																selectedPlayoff.products
															);
															setSingleListQuantity(
																selectedPlayoff.qty_limit
															);
															setGroupList(
																selectedPlayoff.groups
															);
															setProducts([]);
														},
													})}
													input={
														<OutlinedInput label="Playoff" />
													}
													value={playoffId}
													readOnly
												>
													{playoffs.map(
														(playoff, i) => {
															return (
																<MenuItem
																	key={`playoff-${i}`}
																	value={
																		playoff.id
																	}
																>
																	{
																		playoff.title
																	}
																</MenuItem>
															);
														}
													)}
												</Select>

												<FormHelperText>
													{errors.playoff &&
														errors.playoff?.message}
												</FormHelperText>
											</FormControl>
										</Grid>
										<Grid item xs={12}>
											<LocalizationProvider
												dateAdapter={AdapterDayjs}
											>
												<Controller
													control={control}
													name="creation_date_time"
													defaultValue={null}
													rules={{
														required:
															"please select the creation date and time",
														validate: {
															// min: (date) => {
															//   return (
															//     date.isAfter(dayjs()) ||
															//     "Please, enter a future date"
															//   );
															// },
														},
													}}
													render={({
														field: {
															ref,
															onBlur,
															onChange,
															name,
															...field
														},
														fieldState,
													}) => (
														<DateTimePicker
															{...field}
															onChange={(
																newValue
															) => {
																setStartDate(
																	newValue
																);
																console.log(
																	newValue.format()
																);
																onChange(
																	newValue
																);
															}}
															inputRef={ref}
															label="Creation date & time"
															renderInput={(
																inputProps
															) => (
																<TextField
																	fullWidth
																	{...inputProps}
																	onBlur={
																		onBlur
																	}
																	name={name}
																	error={
																		!!fieldState.error
																	}
																	helperText={
																		fieldState
																			.error
																			?.message
																	}
																/>
															)}
														/>
													)}
												/>
											</LocalizationProvider>
										</Grid>
										<Grid item xs={12}>
											<Box
												sx={{
													paddingX: 2,
													paddingY: 0.75,
													border: "1px solid rgba(0, 0, 0, 0.23)",
													borderRadius: "4px",
												}}
											>
												<Stack
													direction={"row"}
													justifyContent="space-between"
													alignItems={"center"}
												>
													<FormLabel>
														Active
													</FormLabel>
													<Switch
														type="checkbox"
														{...register("active", {
															onChange: (e) => {
																setActive(
																	e.target
																		.checked
																);
															},
														})}
														checked={active}
													/>
												</Stack>
											</Box>
										</Grid>
									</Grid>
									<Grid
										item
										container
										spacing={2}
										md={6}
										xs={6}
										justifyContent={"center"}
										alignItems={"center"}
									>
										<Grid item xs={12}>
											{curationImage && (
												<>
													<Box
														sx={{
															textAlign: "center",
														}}
													>
														<FormControl
															error={Boolean(
																errors.file
															)}
														>
															{curationImage && (
																<Avatar
																	variant="square"
																	src={
																		curationImage
																	}
																	sx={{
																		width: 150,
																		height: 150,
																		objectFit:
																			"fill",
																		marginBottom: 2,
																		marginX:
																			"auto",
																	}}
																/>
															)}
															<Button
																variant="contained"
																component="label"
																onChange={
																	handleOnChange
																}
															>
																{!curationImage
																	? "Upload "
																	: "Change "}
																image
																<input
																	hidden
																	accept="image/*"
																	id="file-upload"
																	type="file"
																	{...register(
																		"file",
																		{
																			// required: image == null,
																		}
																	)}
																	error={
																		errors.file
																	}
																/>
															</Button>
														</FormControl>
													</Box>
													<ImageCropper
														image={curationImage}
														setImage={
															setCurationImage
														}
														open={crop}
														setOpen={setCrop}
														aspect={1}
													/>
												</>
											)}
										</Grid>
									</Grid>
								</Grid>
							</Box>

							{!isGrouped && (
								<Box my={3}>
									<SingleList
										singleList={singleList}
										setProducts={setProducts}
										products={products}
										quantity_limit={singleListQuantity}
									/>
								</Box>
							)}
							{isGrouped && (
								<Box my={3}>
									<Grouplist
										groupList={groupList}
										setGroup={setGroup}
										setProducts={setProducts}
										products={products}
									></Grouplist>
								</Box>
							)}

							<Box mb={3}>
								<Button
									type="submit"
									fullWidth
									// onClick={handleSave}
									color="primary"
									variant="contained"
								>
									{"Save"}
								</Button>
							</Box>
						</form>
					</div>
				</Box>
			</Grid>
		</Grid>
	);
};
