import { BaseURLwithEndPoint } from "./config";

import { axiosInstance } from "../config/axios";


const handlerEnabled = true
const endpoint = "brand/";

export const Create = async (params) => {
	try {
		const res = await axiosInstance.post(endpoint, params);
		console.log("====================================");
		console.log(res);
		console.log("====================================");
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};

export const UpdateBrand = async (params,id) => {
	try {
		const res = await axiosInstance.put(`${endpoint}${id}/`, params);
		console.log("====================================");
		console.log(res);
		console.log("====================================");
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};

export const getAllBrand = async (row,page=1) => {
	try {
		const res = await axiosInstance.get(endpoint,{params:{page_size:row,page:page},handlerEnabled});


		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};
