import { useEffect, useState } from "react";

import { makeStyles } from "tss-react/mui";
import { Add, Delete } from "@mui/icons-material";

import _ from "lodash";
import { ToastContainer, toast } from "react-toastify";
import {
	Create,
	getGroupDetails,
	getStylistUsers,
	UpdateGrapeList,
} from "../../apis/GrapeList";
import { useForm } from "react-hook-form";
import {
	Box,
	Button,
	Drawer,
	Typography,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Radio,
	RadioGroup,
	Select,
	TextField,
	Grid,
	Avatar,
	IconButton,
	CardActions,
	CardMedia,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Dialog,
	Card,
	CardContent,
} from "@mui/material";

import { AddProductDrawer } from "../../components/GrapeList/AddProductDrawer";
import { useNavigate, useParams } from "react-router-dom";
import { getTags } from "../../apis/Tags";
import Loader from "../../components/Loader/Loader";

import { ImageCropper } from "../../components/CommonFunctions/ImageCropper";

const useStyles = makeStyles()((theme) => ({
	title: {
		flexGrow: 1,
		display: "none",
		[theme.breakpoints.up("sm")]: {
			display: "block",
		},
	},
}));

export const EditPersistentList = ({ searchText }) => {
	const navigate = useNavigate();

	const [editOpen, setEditOpen] = useState(false);
	const [title, setTitle] = useState("Add");
	const [grapeList, setGrapeList] = useState();
	const [loading, setLoading] = useState(false);
	const [deletingProduct, setDeletingProduct] = useState({});
	const [open, setOpen] = useState(false);
	const [filename, setFilename] = useState("");
	const [image, setImage] = useState(null);
	const [crop, setCrop] = useState(false);

	const [listOptions, setListOptions] = useState([
		{
			value: "persistent_list",
			title: "Persistent List",
            checked: false,
		},
		
	]);
	const [showStylelist, setShowStylelist] = useState(false);
	const [stylelist, setStylelist] = useState([]);
	const [list, setList] = useState([]);
	const [products, setProducts] = useState([]);
	const [selectedOwner, setselectedOwner] = useState(null);
	const { id } = useParams();

	const handleInputChange = (e) => {
		console.log("working");
		const { name, value } = e.target;
		setGrapeList({ ...grapeList, [name]: value });
	};

	const handleListChange = (listtype, owner) => {
		console.log("====================================");
		console.log(listtype, owner);
		console.log("====================================");
		if (listtype === "stylist_list") {
			setselectedOwner(owner.id);
			setShowStylelist(true);
		} else {
			setselectedOwner(null);
			setShowStylelist(false);
		}
		let opt = listOptions.map((option) => {
			option.checked = option.value === listtype;

			return option;
		});
		console.log("====================================");
		console.log(opt);
		console.log("====================================");
		setListOptions(opt);
	};

	useEffect(() => {
		setLoading(true);
		_.debounce(async () => {
			let list = await getGroupDetails(id);
			setGrapeList(list);
			setProducts(list.products);
			setValue("title", list?.title);
			setValue("description", list?.description);
			setValue("owner", list?.owner.id);
			setValue("list_type", list?.list_type);
			setImage(list.image.file)
			let users = await getStylistUsers();
			setStylelist(users);
			handleListChange(list.list_type, list.owner);
			let tags = await getTags();
			let lis = tags.results.map((tag) => {
				let index = list.list_category.findIndex(
					(lis) => lis.id === tag.id
				);
				tag.checked = index !== -1;

				return tag;
			});
			setValue(
				"list_category",
				list.list_category.map((lc) => lc.id)
			);
			setList(lis);
		}, 500)();
		// setLoading(false);
	}, [id]);

	const handleOpen = (item) => {
		setOpen(true);
		setDeletingProduct(item);
	};

	const handleClose = () => setOpen(false);
	const handleAdd = () => {
		setTitle("Add");

		setEditOpen(true);
	};

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm();

	const handleOnChange = (event) => {
		const newImage = event.target?.files?.[0];
		console.log(newImage);
		if (newImage) {
			// console.log(newImage);
			setFilename(newImage.name);
			setImage(URL.createObjectURL(newImage));
			setCrop(true);
		}
	};

	const notify = () =>
		toast.error(`Please select Products`, {
			position: "bottom-right",
			autoClose: 2500,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	const onSubmit = async (data) => {
		console.log(data);
		if (!products.length) {
			setEditOpen(true);
			notify("Please select Products");
			return;
		}

		// let formData = new FormData();
		let formData = new FormData();

		if (!data.owner) {
			data.owner = grapeList.owner.id;
		}
		for (let i in data) {
			if (i === "file") {
				console.log(i, data[i]);
				if (data[i].length) {
				  let blob = await fetch(image).then((r) => r.blob());
				  formData.append("file", blob, filename);
				}
			} else formData.append(i, data[i]);
		}
		console.log(products.map((product) => product.id));
		formData.append(
			"products",
			products.map((product) => product.id)
		);
		formData["list_category"] = [data["list_category"]];
		let res = await UpdateGrapeList(formData, grapeList.id);
		if (res.success) {
			navigate("/w86zjruk6q/persistent-list");
			// 	let newSellers = await getAllSeller();
			// 	setRows(newSellers.results);
			// 	setEditOpen(false);
		}
	};

	return (
		<Grid container justifyContent="center" spacing={2}>
			<ToastContainer />

			{grapeList ? (
				<Grid item xs={12} md={8}>
					<Box sx={{ px: 5 }}>
						<div style={{ padding: "45px 55px 0px" }}>
							<form onSubmit={handleSubmit(onSubmit)}>
								<Box
									display="flex"
									justifyContent="space-between"
								>
									<Typography variant="h6" gutterBottom>
										Edit GrapeList
									</Typography>
								</Box>
								<Grid
									container
									justifyContent="center"
									spacing={2}
								>
									<Grid item xs={12} md={8}>
										<Box my={3}>
											<TextField
												fullWidth
												name="name"
												// style={{ width: "100%" }}
												label="Enter name of the list"
												variant="outlined"
												value={grapeList.title}
												error={Boolean(errors.title)}
												helperText={
													errors.title?.message
												}
												{...register("title", {
													required: "Enter List name",
												})}
												onChange={handleInputChange}
											/>
										</Box>

										<Box my={3}>
											<TextField
												fullWidth
												name="description"
												multiline
												rows={4}
												// style={{ width: "100%" }}
												label="Enter description"
												variant="outlined"
												{...register("description", {
													required:
														"Enter description",
												})}
												value={grapeList.description}
												onChange={handleInputChange}
												error={Boolean(
													errors.description
												)}
												helperText={
													errors.description?.message
												}
											/>
										</Box>
									</Grid>
									<Grid md={4}>
										{image && (
											<>
												<Box
													sx={{
														textAlign: "center",
													}}
												>
													<FormControl
														error={Boolean(
															errors.file
														)}
													>
														{image && (
															<Avatar
																variant="square"
																src={image}
																sx={{
																	width: 150,
																	height: 150,
																	objectFit:
																		"fill",
																	marginBottom: 2,
																	marginX:
																		"auto",
																}}
															/>
														)}
														<Button
															variant="contained"
															component="label"
															onChange={
																handleOnChange
															}
														>
															{!image
																? "Upload "
																: "Change "}
															image
															<input
																hidden
																accept="image/*"
																id="file-upload"
																type="file"
																{...register(
																	"file",
																	{
																		// required: image == null,
																	}
																)}
																error={
																	errors.file
																}
															/>
														</Button>
													</FormControl>
												</Box>
												<ImageCropper
													image={image}
													setImage={setImage}
													open={crop}
													setOpen={setCrop}
													aspect={1}
												/>
											</>
										)}
									</Grid>
								</Grid>

								<Box my={3}>
									<FormControl
										required
										error={Boolean(errors.list_category)}
										// component="outlined"
										// sx={{ m: 3 }}
										variant="outlined"
									>
										<FormLabel>List Category</FormLabel>
										<FormGroup row>
											{list.map((inputlist, i) => {
												return (
													<FormControlLabel
														key={`listt-${i}`}
														control={
															<Checkbox
																{...register(
																	"list_category",
																	{
																		// required:
																		// 	"Please Pick atleast one",
																	}
																)}
																value={
																	inputlist.id
																}
																onChange={(
																	e
																) => {
																	setList(
																		list.map(
																			(
																				l
																			) => {
																				// console.log('====================================');
																				// console.log(l.id===inputlist.id);
																				// console.log('====================================');
																				if (
																					l.id ===
																					inputlist.id
																				)
																					l.checked =
																						!l.checked;
																				return l;
																			}
																		)
																	);
																	setValue(
																		"list_category",
																		e.target
																			.value
																	);
																}}
																checked={
																	inputlist.checked
																}
																name="list_category"
															/>
														}
														label={inputlist.name}
													/>
												);
											})}
										</FormGroup>
										<FormHelperText>
											{errors.list_category?.message}
										</FormHelperText>
									</FormControl>
								</Box>

								<Box my={3}>
									<FormControl
										error={Boolean(errors.list_type)}
									>
										<FormLabel id="demo-row-radio-buttons-group-label">
											List Type
										</FormLabel>
										<RadioGroup
											row
											aria-labelledby="demo-row-radio-buttons-group-label"
											name="list_type"
										>
											{listOptions.map((option, i) => {
												return (
													<FormControlLabel
														key={`option-${i}`}
														value={option.value}
														control={
															<Radio
																checked={
																	option.checked
																}
																{...register(
																	"list_type",
																	{
																		required:
																			"Please select list",
																	}
																)}
																onChange={(
																	e
																) => {
																	handleListChange(
																		e.target
																			.value,
																		grapeList.owner
																	);
																}}
															/>
														}
														label={option.title}
													/>
												);
											})}
										</RadioGroup>
										<FormHelperText>
											{errors.list_type &&
												errors.list_type?.message}
										</FormHelperText>
									</FormControl>
								</Box>
								<Box my={3}>
									{showStylelist && (
										<FormControl
											fullWidth
											error={Boolean(errors.owner)}
										>
											<InputLabel htmlFor="owner">
												Stylist
											</InputLabel>
											<Select
												{...register("owner", {
													required: "Select stylist",
												})}
												displayEmpty
												input={
													<OutlinedInput label="Stylist" />
												}
												label="Stylist"
												value={selectedOwner}
												defaultValue={selectedOwner}
												onChange={(e) => {
													setselectedOwner(
														e.target.value
													);
												}}
												// onChange={(e) => {
												// 	//setSelectedSeller(e.target.value);
												// }}
											>
												{stylelist.map((product, i) => {
													return (
														<MenuItem
															key={`seller-${i}`}
															value={product.id}
														>
															{product.name}
														</MenuItem>
													);
												})}
											</Select>
											<FormHelperText>
												{errors.owner &&
													errors.owner?.message}
											</FormHelperText>
										</FormControl>
									)}
								</Box>
								<Box
									mb={3}
									display="flex"
									justifyContent="space-between"
								>
									<Typography variant="h6" gutterBottom>
										Products
									</Typography>
									<Button
										type="button"
										onClick={handleAdd}
										color="primary"
										variant="contained"
									>
										{"Add Products"}
									</Button>
								</Box>
								{products.length > 0 && (
									<Box my={3}>
										<Grid container spacing={2}>
											{products.map((item, i) => (
												<Grid
													item
													xs={12}
													md={2}
													key={`product-${i}`}
												>
													<Card
														sx={{
															height: "100%",
															display: "flex",
															justifyContent:
																"space-between",
															flexDirection:
																"column",
														}}
													>
														<CardMedia
															component="img"
															// height="194"
															image={
																item.image.file
															}
															alt="Paella dish"
														/>
														<CardContent>
															<Typography
																variant="body2"
																color="text.secondary"
															>
																{item.name}
															</Typography>
														</CardContent>
														<CardActions
															disableSpacing
															sx={{
																display: "flex",
																alignItems:
																	"end",
															}}
														>
															<IconButton
																aria-label="delete product"
																onClick={() => {
																	handleOpen(
																		item
																	);
																	// setProducts(new_products)
																}}
															>
																<Delete />
															</IconButton>
														</CardActions>
													</Card>
												</Grid>
											))}
										</Grid>
									</Box>
									// <Box my={3}>
									// 	<FormControl
									// 		required
									// 		error={Boolean(errors.products)}
									// 		variant="outlined"
									// 	>
									// 		<FormLabel>Product List</FormLabel>
									// 		<FormGroup row>
									// 			{products.map((list) => {
									// 				list.checked = true;
									// 				return (
									// 					<FormControlLabel
									// 						checked={
									// 							list.checked
									// 						}
									// 						control={
									// 							<Checkbox
									// 								{...register(
									// 									"products",
									// 									{
									// 										required:
									// 											"Please Pick atleast one",
									// 									}
									// 								)}
									// 								value={
									// 									list.id
									// 								}
									// 								onChange={(
									// 									e
									// 								) => {
									// 									let tempProducts =
									// 										products.filter(
									// 											(
									// 												p
									// 											) =>
									// 												!p.id ===
									// 												parseInt(
									// 													e
									// 														.target
									// 														.value
									// 												)
									// 										);

									// 									setProducts(
									// 										tempProducts
									// 									);
									// 								}}
									// 								name="products"
									// 							/>
									// 						}
									// 						label={
									// 							<Box
									// 								sx={{
									// 									display:
									// 										"inline-flex",
									// 								}}
									// 							>
									// 								<Avatar
									// 									sx={{
									// 										mx: 2,
									// 									}}
									// 									alt="Seller Logo"
									// 									src={
									// 										list
									// 											.image
									// 											.file
									// 									}
									// 								>
									// 									S
									// 								</Avatar>
									// 								{list.name}
									// 							</Box>
									// 						}
									// 					/>
									// 				);
									// 			})}
									// 		</FormGroup>
									// 		<FormHelperText>
									// 			{errors.products?.message}
									// 		</FormHelperText>
									// 	</FormControl>
									// </Box>
								)}
								<Box mb={3}>
									<Button
										type="submit"
										fullWidth
										// onClick={handleSave}
										color="primary"
										variant="contained"
									>
										{title === "Edit" ? "Update" : "Save"}
									</Button>
								</Box>
							</form>
						</div>
						<Drawer
							anchor="right"
							open={editOpen}
							onClose={() => setEditOpen(false)}
						>
							<AddProductDrawer
								setEditOpen={setEditOpen}
								products={products}
								setProducts={setProducts}
							/>
						</Drawer>
					</Box>
				</Grid>
			) : (
				<Loader />
			)}
			<Dialog
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={open}
				onClose={handleClose}
				closeAfterTransition
				// BackdropComponent={Backdrop}
				// BackdropProps={{
				// 	timeout: 500,
				// }}
			>
				<DialogTitle id="alert-dialog-title">
					{"Are you sure?"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Do you want to remove product
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>No</Button>
					<Button
						onClick={() => {
							let new_products = products.filter((product) => {
								return product !== deletingProduct;
							});
							setDeletingProduct({});
							setProducts(new_products);
							handleClose();
						}}
						autoFocus
						color="secondary"
					>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		</Grid>
	);
};
