import { getMasonryUtilityClass } from "@mui/lab";
import { useEffect, useState } from "react";
import {  } from "../apis/ProductCategory";
import { ProductCategoryAccordion } from "../components/Menu/SubmenuItem";

export const Test = () => {
    const [categoryId, setCategoryId] = useState();
    const [menu, setMenu] = useState([]);
    useEffect(() => {
		let get_categories = async () => {
			let categories = await getMasonryUtilityClass(1000, 1);
			setMenu(categories.results);
		};
		get_categories();
	}, []);
	return (
		<>
			{/* <AccordionExampleNested /> */}
			<ProductCategoryAccordion setCategoryId={setCategoryId} menu={menu}/>
		</>
	);
};
