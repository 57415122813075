import { Add, ChevronLeft, ChevronRight } from "@mui/icons-material";
import {
	Box,
	Button,
	Drawer,
	IconButton,
	Toolbar,
	Typography,
} from "@mui/material";
import _ from "lodash";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { ToastContainer } from "react-toastify";
import { Divider } from "semantic-ui-react";
import { makeStyles } from "tss-react/mui";
import { CurationListTable } from "../../components/Curation/CurationListTable";
import { CurationReview } from "../../components/Curation/Reviews";

const useStyles = makeStyles()((theme) => ({
	title: {
		flexGrow: 1,
		display: "none",
		[theme.breakpoints.up("sm")]: {
			display: "block",
		},
	},
}));

function Curations() {
	const { classes } = useStyles();
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const [curation, setCuration] = useState(null);

	useEffect(() => {}, []);
	const handleDrawerOpen = (currentCuration) => {
		console.log("====================================");
		console.log(currentCuration);
		console.log("====================================");
		setCuration(currentCuration);
		setOpen(true);
	};

	const drawerWidth = 600;
	return (
		<>
			<Toolbar>
				<Typography className={classes.title} variant="h4" noWrap />
				<Button
					onClick={() => {
						navigate("/w86zjruk6q/curation/add");
					}}
					startIcon={<Add />}
					variant="contained"
					color="primary"
				>
					Add Curation
				</Button>
			</Toolbar>
			<ToastContainer />
			<Box sx={{ px: 5 }}>
				<CurationListTable handleDrawerOpen={handleDrawerOpen} />
			</Box>
			<Drawer
				anchor="right"
				sx={{
					width: drawerWidth,

					"& .MuiDrawer-paper": {
						width: drawerWidth,
						// boxSizing: "border-box",
					},
				}}
				// variant="persistent"
				open={open}
				onClose={() => {
					setOpen(false);
				}}
			>
				<CurationReview
					curation={curation}
					reviews={[]}
					setOpen={setOpen}
				></CurationReview>
			</Drawer>
		</>
	);
}

export default Curations;
