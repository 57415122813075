import { useEffect, useState } from "react";

import { makeStyles } from "tss-react/mui";
import { Add } from "@mui/icons-material";
import { Box, Button, Drawer, Toolbar, Typography } from "@mui/material";
import Table from "../../components/Playoff/PlayListTable";

import {
	getAllProducts,
	
	deleteProduct,
	
} from "../../apis/Api";
import { ToastContainer, toast } from "react-toastify";

import _ from "lodash";
import { EditListDrawer } from "../../components/GrapeList/EditListDrawer";
import { getAllSeller } from "../../apis/GrapeList";
import { useNavigate } from "react-router-dom";
import { getAllPlayoffs } from "../../apis/Playoff";
import { getCurrentUser } from "../../store/authentication/service";

const useStyles = makeStyles()((theme) => ({
	title: {
		flexGrow: 1,
		display: "none",
		[theme.breakpoints.up("sm")]: {
			display: "block",
		},
	},
}));

export const Playoff = ({ searchText }) => {
	const { classes } = useStyles();
	const navigate = useNavigate();
	const [editOpen, setEditOpen] = useState(false);
	const [title, setTitle] = useState("Add");
	const [rows, setRows] = useState([]);
	const [seller, setSeller] = useState({});
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsCount, setRowsCount] = useState(10);
	const [count, setCount] = useState(0);
	const [user, setUser] = useState();
	

	useEffect(() => {
		setLoading(true);
		_.debounce(async () => {
			const data = await getAllPlayoffs(rowsCount);
			console.log(data);
			setRows(data.results);
			console.log(data);
			// const cnt = await getCount(searchText, "product");
			setCount(data.count);
			// setRowsCount(data.count)
		}, 500)();
		setLoading(false);
	}, [searchText]);

	useEffect(() => {
	 let user = getCurrentUser()
	 setUser(user)
	}, []);
	

	const notify = () =>
		toast.error(`Some unexpected error occured`, {
			position: "bottom-right",
			autoClose: 2500,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});

	

	
	const handleDeleteProduct = async (i) => {
		setLoading(true);
		const res = await deleteProduct(rows[i].id);
		if (res.status !== 200) {
			notify();
			return;
		}
		const data = await getAllProducts(
			searchText,
			1,
			rows[rows.length - 1]["last_updated_time"],
			rows[rows.length - 1]["_id"]["$oid"]
		);
		setRows((prev) => [...prev.filter((_, j) => j !== i), ...data]);
		setLoading(false);
	};

	const handlePage = async (_, i) => {
		const data = await getAllPlayoffs(rowsCount, i + 1);
		setRows(data.results);
		setPage(i);
	};

	const handleRowsCount = async (i) => {
		setRowsCount(i);
		setLoading(true);
		const data = await getAllPlayoffs(i);
		setRows(data.results);
		setLoading(false);
	};
	return (
		<>
			<Toolbar>
				<Typography className={classes.title} variant="h4" noWrap />
				<Button
					onClick={() => {
						
						if(user?.user_type===4){
							navigate("/upload-playoff/playoff/add");
						}else{
							navigate("/w86zjruk6q/playoff/add");
						}
						
					}}
					startIcon={<Add />}
					variant="contained"
					color="primary"
				>
					Add Playoff
				</Button>
			</Toolbar>
			<ToastContainer />
			<Box sx={{ px: 5 }}>
				<Table
					
					user = {user}
					page={page}
					rowsCount={rowsCount}
					handlePage={handlePage}
					handleRowsCount={handleRowsCount}
					count={count}
					rows={rows}
					loading={loading}
					setRows={setRows}
				/>
				<Drawer
					anchor="right"
					open={editOpen}
					onClose={() => setEditOpen(false)}
				>
					<EditListDrawer
						title={title}
						setEditOpen={setEditOpen}
						seller={seller}
						setRows={setRows}
					/>
				</Drawer>
			</Box>
		</>
	);
};
