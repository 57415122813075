import { styled } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { EditOutlined } from "@mui/icons-material";
import {
	Box,
	CircularProgress,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Chip,
	Button,
	Avatar,
	TablePagination,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { green, red } from "@mui/material/colors";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.action.hover,
		fontWeight: 600,
		userSelect: "none",
		borderBottom: `1px solid ${theme.palette.background.divider}`,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
		userSelect: "none",
		borderBottom: `1px solid ${theme.palette.background.divider}`,
	},
}));

const useStyles = makeStyles()({
	table: {
		minWidth: 1280,
	},
	tableHead: {
		position: "sticky",
		top: 0,
		zIndex: 1,
	},
});

export default function TagsTable({
	rows,
	handleEdit,
	handleActive,
	loading,
	handlePage,
	handleRowsCount,
	count,
	page,
	rowsCount,
}) {
	const classes = useStyles();

	return (
		<>
			<TableContainer component={Paper}>
				<Table className={classes.table} aria-label="customized table">
					<TableHead className={classes.tableHead}>
						<TableRow>
							{[
								"S No.",
								"Tag Name",
								"Logo",
								"Display",
								"Created at",
								"Updated at",
								"",
							].map((header, i) => (
								<StyledTableCell key={`tag-header-${i}`}>
									{header}
								</StyledTableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row, i) => (
							<TableRow key={i + 1}>
								<StyledTableCell>{page * rowsCount + i + 1}</StyledTableCell>
								<StyledTableCell>{row["name"]}</StyledTableCell>
								<StyledTableCell>
									<Avatar
										alt="ListCategory Logo"
										src={row.logo?.file}
									>
										S
									</Avatar>
								</StyledTableCell>
								<StyledTableCell>
									<Chip
										label={row.active ? "Show" : "Hide"}
										style={{
											borderRadius: 5,
											margin: 5,
											backgroundColor: row.active
												? green[700]
												: red[700],
											color: "white",
										}}
									/>
								</StyledTableCell>
								<StyledTableCell>
									{new Date(row["created_at"])
										.toString()
										.substring(4, 24)}
								</StyledTableCell>
								<StyledTableCell>
									{new Date(row["updated_at"])
										.toString()
										.substring(4, 24)}
								</StyledTableCell>
								<StyledTableCell>
									<IconButton
										color="primary"
										onClick={() => handleEdit(row.id)}
									>
										<EditOutlined />
									</IconButton>
									<Button
										size="small"
										onClick={() => handleActive(row.id)}
										sx={{
											backgroundColor: row.active
												? red[700]
												: green[700],
											color: "white",
										}}
										variant="contained"
									>
										{row.active ? "HIDE" : "SHOW"}
									</Button>
								</StyledTableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				sx={{ overflow: "inherit" }}
				rowsPerPageOptions={[10, 25, 100]}
				component="div"
				count={count}
				rowsPerPage={rowsCount}
				page={page}
				onPageChange={handlePage}
				onRowsPerPageChange={(e) => handleRowsCount(e.target.value)}
			/>
			{loading && (
				<Box display="flex" width="100%" justifyContent="center" p={3}>
					<CircularProgress />
				</Box>
			)}
		</>
	);
}
