import React, { useState } from "react";
import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import { ToastContainer, toast } from "react-toastify";

import Loader from "../Loader/Loader";
import { editWaitlistUser, getAllWaitlistUser } from "../../apis/waitlist";

export const EditWaitlistStatus = ({
  setEditOpen,
  waitlistUser,

  setRows,
}) => {
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();



const onSubmit = async (data) => {
    setLoading(true);
    console.log("ddddddddddd", data);
  
   
    let formData = { 
      status: data.status,
      user: waitlistUser.user  
    };
  
    try {
      let res = await editWaitlistUser(formData, waitlistUser.id);
      console.log("rrrrrrrrrrrrrrrrrrrrrr", res);
  
      if (res.success) {
        let newWaitlistUser = await getAllWaitlistUser();
        setRows(newWaitlistUser.results);
        setEditOpen(false);
      } else {
        setLoading(false);
        notify("Please try again later. Error");
      }
    } catch (error) {
      console.error("Error in onSubmit", error);
      setLoading(false);
      notify("Please try again later. Error");
    }
  };
  
  const notify = (m) =>
    toast.error(m, {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  return (
    <div
      style={{
        width: 600,
        padding: 5,
        height: "100vh",
        overflowY: loading ? "hidden" : "scroll",
      }}
    >
      {loading && <Loader />}
      <IconButton
        style={{ position: "fixed" }}
        onClick={() => setEditOpen(false)}
      >
        <Close />
      </IconButton>
      <ToastContainer />
      <div style={{ padding: "45px 55px 0px" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" justifyContent="space-between">
            <Typography
              variant="h6"
              gutterBottom
            >{`Edit User Status`}</Typography>
            <Button type="submit" color="primary" variant="contained">
              Update
            </Button>
          </Box>

          <Box my={3}>
            <FormLabel id="status-group">User Status</FormLabel>
            <FormControl fullWidth error={Boolean(errors.status)}>
              <Controller
                control={control}
                defaultValue={waitlistUser.status ? waitlistUser.status : ""}
                name="status"
                rules={{
                  required: "Please pick one from options",
                }}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    value={field.value}
                    onChange={(e, value) => field.onChange(value)}
                    row
                  >
                    <FormControlLabel
                      value="awaited"
                      control={<Radio />}
                      label="Awaited"
                    />
                    <FormControlLabel
                      value="invited"
                      control={<Radio />}
                      label="Invited"
                    />
                    <FormControlLabel
                      value="approved"
                      control={<Radio />}
                      label="Approved"
                    />
                  </RadioGroup>
                )}
              />
              {Boolean(errors.status) && (
                <FormHelperText>{errors.status?.message}</FormHelperText>
              )}
            </FormControl>
          </Box>
        </form>
      </div>
    </div>
  );
};
