import { REGISTER_START, LOGIN_USER } from "./constants";

// import AuthService from "../services/auth.service";
export const register = (playload) => ({
	type: REGISTER_START,
	playload,
});

export const login = (data) => {
	return {
		type: LOGIN_USER,
		data,
	};
};

// export const logout = () => (dispatch) => {
//   AuthService.logout();

//   dispatch({
//     type: LOGOUT,
//   });
// };
