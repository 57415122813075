
import { axiosInstance } from "../config/axios";



const endpoint =  "alllist/";
const handlerEnabled = true

export const Create = async (params) => {
	try {
		const res = await axiosInstance.post(endpoint, params);
		
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};

export const getAllList = async (row,page=1) => {
	
	try {
		const res = await axiosInstance.get(endpoint,{params:{page_size:row,page:page},handlerEnabled});

		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};

export const getStylistUsers = async () => {
	try {
		
		const res = await axiosInstance.get("stylist/");
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};


export const getGroupDetails = async (id) => {
	try {
		const res = await axiosInstance.get(`${endpoint}${id}/`);

		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};


export const UpdateGrapeList = async (params,id) => {
	try {
		const res = await axiosInstance.put(`${endpoint}${id}/`, params);
		console.log("====================================");
		console.log(res);
		console.log("====================================");
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};