import { axiosInstance } from '../config/axios'

const handlerEnabled = true

const endpoint = "waitlist/";

export const getAllWaitlistUser = async (row,page=1) => {
	try {
		const res = await axiosInstance.get(endpoint,{params:{page_size:row,page:page},handlerEnabled});

		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};


export const editWaitlistUser = async (params,id) => {
	try{
	const res = await axiosInstance.put(`${endpoint}${id}/`, params);
	const { data } = res;
		data["success"] = true;
		return res.data;
	}
	catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};


  




