import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Add } from '@mui/icons-material';
import { Box, Button, Drawer, Toolbar, Typography } from '@mui/material';
import Table from '../../components/User/UserTable';
import { getFullUsers,  updateUserStatus } from '../../apis/Api';
import { EditUserDrawer } from '../../components/User/EditUserDrawer';
import { getAllUsers } from '../../apis/user';



const useStyles = makeStyles()((theme) => ({
	title: {
		flexGrow: 1,
		display: 'none',
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
	},
}));

export const Users = ({ searchText }) => {
	const {classes} = useStyles();

	const [editOpen, setEditOpen] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [rows, setRows] = useState([]);
	const [user, setUser] = useState({});
	const [page, setPage] = useState(0);
	const [rowsCount, setRowsCount] = useState(10);
	const [count, setCount] = useState(0);
	const [loading, setLoading] = useState(false);
	

	useEffect(() => {
		(async () => {
			// const data = await getUserTypes();
            
			// setTypes(data);
		})();
	}, [])

	useEffect(() => {
		setLoading(true);
		(async () => {
			const data = await getAllUsers(rowsCount);
			setRows(data.results);
			setCount(data.count)
		})();
		// (async () => {
		// 	const data = await getCount(searchText);
		// 	setCount(data);
		// })();
		setLoading(false);
	}, [searchText])

	const handleAddUser = () => {
		setIsEdit(false)
		setUser({name:"",email:"",phone:""});
		setEditOpen(true);
	}

	const handleEditUser = (id) => {
		setIsEdit(true)
		setUser(rows.find(row=>row.id===id));
		
		setEditOpen(true);
	}

	const handlePage = async (_, i) => {
		const data = await getAllUsers(rowsCount, i + 1);
		setRows(data.results);
		setPage(i);
	};

	const handleRowsCount = async (i) => {
		setRowsCount(i);
		setLoading(true);
		const data = await getAllUsers(i);
		setRows(data.results);
		setLoading(false);
	};

	const handleUserStatus = async (userID, blockedStatus) => {
		let blockingObj = {  
			"user_id":userID,
			"action": blockedStatus === 1 ? "unblock" : "block"
		}
		await updateUserStatus(blockingObj)
		const data = await getFullUsers(searchText);
		setRows(data);
	}

	return (
		<>
			<Toolbar>
                
				<Typography className={classes.title} variant="h4" noWrap />
				<Button
					onClick={() => handleAddUser()}
					startIcon={<Add />}
					variant='contained'
					color='primary'
				>
					Add User
				</Button>
			</Toolbar>
            <Box sx={{ px: 5 }}>
			<Table handleEdit={handleEditUser} rows={rows} loaing={loading} handleUserStatus={handleUserStatus} page={page} rowsCount={rowsCount} handlePage={handlePage} handleRowsCount={handleRowsCount} count={count} />
            </Box>
			<Drawer anchor='right' open={editOpen} onClose={() => setEditOpen(false)}>
				<EditUserDrawer edit={isEdit} setEditOpen={setEditOpen} user={user} setUser={setUser} setRows={setRows} loading={loading}/>
			</Drawer>
		</>
	)
}