import { useEffect, useState } from "react";

import { makeStyles } from "tss-react/mui";
import _ from "lodash";
import { ToastContainer, toast } from "react-toastify";

import { Controller, useForm } from "react-hook-form";
import {
	Box,
	Button,
	Drawer,
	Typography,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
	Grid,
	Avatar,
	IconButton,
	Card,
	CardMedia,
	CardContent,
	CardActions,
	CardHeader,
	Collapse,
	AvatarGroup,
	Stack,
	Modal,
	Backdrop,
	Fade,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Switch,
} from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { getAllBrand } from "../../apis/Brand";
import { AddProductDrawer } from "../../components/GrapeList/AddProductDrawer";
import { AddGroup } from "../../components/Playoff/AddGroup";
import { AddPrizeDrawer } from "../../components/Playoff/AddPrizeDrawer";
import EditIcon from "@mui/icons-material/Edit";
import { getTags } from "../../apis/Tags";
import { Delete, Edit, ExpandMore } from "@mui/icons-material";
import { styled } from "@mui/system";
import { Create } from "../../apis/Playoff";
import { getCurrentUser } from "../../store/authentication/service";
import { AestheticInput } from "./AestheticInput";

const useStyles = makeStyles()((theme) => ({
	title: {
		flexGrow: 1,
		display: "none",
		[theme.breakpoints.up("sm")]: {
			display: "block",
		},
	},
}));

export const AddPlayoff = ({ searchText }) => {
	const navigate = useNavigate();
	const { classes } = useStyles();

	const [addProduct, setAddProduct] = useState(false);
	const [addGroup, setAddGroup] = useState(false);
	const [addPrize, setAddPrize] = useState(false);

	const [noGroup, setNoGroup] = useState(0);
	const [prize, setPrize] = useState(null);
	const [loading, setLoading] = useState(false);

	const [isGrouped, setIsGrouped] = useState(false);
	const [list, setList] = useState([]);
	const [products, setProducts] = useState([]);
	const [groups, setGroups] = useState([]);
	const [group, setGroup] = useState([]);
	const [brand, setBrand] = useState([]);
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState(null);
	const [deletingProduct, setDeletingProduct] = useState({});
	const [expandedId, setExpandedId] = useState(-1);
	const [open, setOpen] = useState(false);
	const [groupOpen, setGroupOpen] = useState(false);
	const [active, setActive] = useState(true);
	const [isPlayoff, setIsPlayoff] = useState(true);
	const [deletingGroup, setDeletingGroup] = useState({});
	const [groupEdit, setGroupEdit] = useState();
	const [user, setUser] = useState();
	const handleOpen = (item) => {
		setOpen(true);
		setDeletingProduct(item);
	};
	const handleClose = () => setOpen(false);

	const handleGroupOpen = (item) => {
		setGroupOpen(true);
		setDeletingGroup(item);
	};
	const handleGroupClose = () => setGroupOpen(false);

	const ExpandMoreDiv = styled((props) => {
		const { expand, ...other } = props;
		return <IconButton {...other} />;
	})(({ theme, expand }) => ({
		transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
		marginLeft: "auto",
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.shortest,
		}),
	}));
	const handleExpandClick = (i) => {
		setExpandedId(expandedId === i ? -1 : i);
	};

	useEffect(() => {
		setLoading(true);
		_.debounce(async () => {
			let tags = await getTags();
			let brands = await getAllBrand();
			setBrand(brands.results);
			setList(tags.results);
		}, 500)();
		setLoading(false);
	}, []);

	useEffect(() => {
		let user = getCurrentUser();
		setUser(user);
	}, []);

	const handleAddProduct = () => {
		setAddProduct(true);
	};
	const handleAddGroup = () => {
		setAddGroup(true);
		setGroup({ name: "", description: "", qty_limit: "", products: [] });
		setGroupEdit(false);
	};
	const handleAddPrize = () => {
		setAddPrize(true);
	};

	const handleGroupEdit = (group_id) => {
		let group = groups.find(({ id }) => id === group_id);
		setGroup(group);
		setAddGroup(true);
		setGroupEdit(true);
	};
	// const handleEdit = (i) => {
	// 	setTitle("Edit");

	// 	setEditOpen(true);
	// };

	const {
		register,
		handleSubmit,
		setValue,
		control,
		unregister,
		formState: { errors },
	} = useForm();
	const notify = (m) =>
		toast.error(m, {
			position: "bottom-right",
			autoClose: 2500,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	const onSubmit = async (data) => {
		console.log(data);
		data.active = !data.active;
		// if (!prize) {
		// 	notify("Add Giveway for the playoff");
		// 	setAddPrize(true);
		// 	return;
		// }
		if (isGrouped && !groups.length) {
			notify("Add atleast one Group");
			setAddGroup(true);
			return;
		}

		if (!isGrouped && !products.length) {
			notify("Please Add products");
			return;
		}
		if (prize) data["prize"] = prize.id;
		data["grouped_list"] = isGrouped;

		// if (!data.products) {
		// 	setAddProduct(true);
		// 	notify();
		// 	return;
		// }

		// let formData = new FormData();
		let formData = new FormData();
		let pproducts;
		let pgroups;
		console.log(startDate.format());
		console.log(endDate);
		for (let i in data) {
			if (i === "file") {
				formData.append(i, data[i][0]);
			} else if (i === "brand") {
				formData.append(i, data[i]);
			} else if (i === "aesthetic" && data[i]) {
				formData.append(i, data[i].id);
			} else formData.append(i, data[i]);
		}
		formData.append("play_off_start_date_time", startDate.format());
		formData.append("play_off_end_date_time", endDate.format());
		formData.append("voting_start_date_time", endDate.format());
		formData.append("voting_end_date_time", endDate.add(1, "day").format());

		// if (!data.owner) {
		// 	data.owner = 2;
		// }
		// for (let i in data) {
		// 	if (i === "image") {
		// 		formData.append(i, data[i][0]);
		// 	} else formData.append(i, data[i]);
		// }
		if (isGrouped) {
			data["groups"] = groups.map((g) => g.id);
			console.log(groups);
			pgroups = groups.map((p) => parseInt(p.id));
			console.log(pgroups);
			formData.append("groups", pgroups);
		} else {
			data["products"] = products.map((g) => g.id);
			pproducts = products.map((p) => parseInt(p.id));
			formData.append("products", pproducts);
		}

		formData["list_category"] = [data["list_category"]];
		let res = await Create(formData);
		console.log("====================================");
		console.log(res);
		console.log("====================================");
		if (res.success) {
			if (user?.user_type === 4) {
				navigate("/upload-playoff/playoff");
			} else {
				navigate("/w86zjruk6q/playoff");
			}
			// 	let newSellers = await getAllSeller();
			// 	setRows(newSellers.results);
			// 	setEditOpen(false);
		}
	};

	return (
		<Grid container justifyContent="center" spacing={2}>
			<ToastContainer />
			<Grid item xs={12} md={8}>
				<Box sx={{ px: 5 }}>
					<div style={{ padding: "45px 55px 0px" }}>
						<form onSubmit={handleSubmit(onSubmit)}>
							<Box display="flex" justifyContent="space-between">
								<Typography variant="h6" gutterBottom>
									Add Playoff
								</Typography>
							</Box>

							<Box my={3}>
								<TextField
									fullWidth
									name="title"
									label="Enter playoff title"
									variant="outlined"
									error={Boolean(errors.title)}
									helperText={errors.title?.message}
									{...register("title", {
										required: "Enter List name",
									})}
								/>
							</Box>

							<Box my={3}>
								<TextField
									fullWidth
									name="description"
									multiline
									rows={4}
									// style={{ width: "100%" }}
									label="Enter description"
									variant="outlined"
									{...register("description", {
										required: "Enter description",
									})}
									error={Boolean(errors.description)}
									helperText={errors.description?.message}
								/>
							</Box>
							<Box my={3}>
								<TextField
									fullWidth
									name="alias"
									label="Enter alias"
									variant="outlined"
									error={Boolean(errors.alias)}
									helperText={errors.alias?.message}
									{...register("alias", {
										required: "Enter alias",
									})}
								/>
							</Box>

							<Box my={3}>
								<FormControl
									required
									error={Boolean(errors.list_category)}
									// component="outlined"
									// sx={{ m: 3 }}
									variant="outlined"
								>
									<FormLabel>List Category</FormLabel>
									<FormGroup row>
										{list.map((list, i) => {
											return (
												<FormControlLabel
													key={`listt-${i}`}
													control={
														<Checkbox
															{...register(
																"list_category",
																{
																	required:
																		"Please Pick atleast one",
																}
															)}
															value={list.id}
															// onChange={handleChange}
															name="list_category"
														/>
													}
													label={list.name}
												/>
											);
										})}
									</FormGroup>
									<FormHelperText>
										{errors.list_category?.message}
									</FormHelperText>
								</FormControl>
							</Box>
							<Box my={3}>
								<Grid container spacing={2}>
									<Grid item md={4}>
										<FormControl
											fullWidth
											error={Boolean(errors.brand)}
										>
											<InputLabel htmlFor="brand">
												Brand (optional)
											</InputLabel>
											<Select
												{...register("brand", {
													// required: "select Brand",
												})}
												// value={""}
												input={
													<OutlinedInput label="Brand (optional)" />
												}
												defaultValue={""}
												// onChange={(e) => {
												// 	// setSelectedBrand(e.target.value);
												// }}
											>
												{brand.map((product, i) => {
													return (
														<MenuItem
															key={`brand-${i}`}
															value={product.id}
														>
															{product.name}
														</MenuItem>
													);
												})}
											</Select>

											<FormHelperText>
												{errors.brand &&
													errors.brand?.message}
											</FormHelperText>
										</FormControl>
									</Grid>
									<Grid item md={4}>
										<TextField
											fullWidth
											type="number"
											InputProps={{
												inputProps: { min: 1 },
											}}
											id="outlined-basic"
											label={"Max Entries Allowed"}
											variant="outlined"
											{...register(
												"max_entries_allowed",
												{
													// required:
													// 	"Enter No of Entries Allowed",
												}
											)}
										/>
									</Grid>
									<Grid item md={4}>
										<TextField
											{...register("price_limit", {
												// required:
												// 	"Enter Price Limit",
											})}
											type="number"
											InputProps={{
												inputProps: { min: 0 },
											}}
											fullWidth
											id="outlined-basic"
											label={"Price Limit"}
											variant="outlined"
										/>
									</Grid>
								</Grid>
							</Box>
							<Box my={3}>
								<Grid container spacing={2}>
									<Grid item md={6}>
										<LocalizationProvider
											dateAdapter={AdapterDayjs}
										>
											<Controller
												control={control}
												name="play_off_start_date_time"
												defaultValue={null}
												// rules={{
												// 	required:
												// 		"please select the start date",
												// 	validate: {
												// 		// min: (date) => {
												// 		// 	return (
												// 		// 		date.isAfter(
												// 		// 			dayjs()
												// 		// 		) ||
												// 		// 		"Please, enter a future date"
												// 		// 	);
												// 		// },
												// 	},
												// }}
												render={({
													field: {
														ref,
														onBlur,
														onChange,
														name,
														...field
													},
													fieldState,
												}) => (
													<DateTimePicker
														{...field}
														onChange={(
															newValue
														) => {
															setStartDate(
																newValue
															);

															let addday =
																newValue.add(
																	1,
																	"day"
																);
															setValue(
																"play_off_end_date_time",
																addday
															);
															setEndDate(addday);
															console.log(
																newValue.format()
															);
															onChange(newValue);
														}}
														inputRef={ref}
														label="Start date & time"
														// minDateTime={dayjs()}
														renderInput={(
															inputProps
														) => (
															<TextField
																fullWidth
																{...inputProps}
																onBlur={onBlur}
																name={name}
																error={
																	!!fieldState.error
																}
																helperText={
																	fieldState
																		.error
																		?.message
																}
															/>
														)}
													/>
												)}
											/>
										</LocalizationProvider>
									</Grid>
									<Grid item md={6}>
										<LocalizationProvider
											dateAdapter={AdapterDayjs}
										>
											<Controller
												control={control}
												name="play_off_end_date_time"
												defaultValue={null}
												rules={{
													required:
														"please select the end date",
													validate: {
														min: (date) => {
															console.log(
																startDate.format()
															);
															return (
																date.isAfter(
																	startDate
																) ||
																"End date should be greatern then start date"
															);
														},
													},
												}}
												render={({
													field: {
														ref,
														onBlur,
														onChange,
														name,
														...field
													},
													fieldState,
												}) => (
													<DateTimePicker
														{...field}
														// minDateTime={
														// 	startDate
														// 		? startDate
														// 		: dayjs()
														// }
														onChange={(
															newValue
														) => {
															setEndDate(
																newValue
															);
															onChange(newValue);
														}}
														inputRef={ref}
														label="End date & time"
														renderInput={(
															inputProps
														) => (
															<TextField
																fullWidth
																{...inputProps}
																onBlur={onBlur}
																name={name}
																error={
																	!!fieldState.error
																}
																helperText={
																	fieldState
																		.error
																		?.message
																}
															/>
														)}
													/>
												)}
											/>
										</LocalizationProvider>
									</Grid>
								</Grid>
							</Box>
							<Box my={3}>
								<Box
									mb={3}
									display="flex"
									justifyContent="space-between"
								>
									<Typography variant="h6" gutterBottom>
										Give Away
									</Typography>
									{!prize && (
										<Button
											type="button"
											onClick={handleAddPrize}
											color="primary"
											variant="contained"
										>
											{"Add Give Away"}
										</Button>
									)}
								</Box>
								{prize && (
									<Box>
										<Card sx={{ maxWidth: 345 }}>
											<CardHeader
												action={
													<IconButton aria-label="settings">
														<EditIcon
															onClick={
																handleAddPrize
															}
														/>
													</IconButton>
												}
											/>
											<CardMedia
												sx={{
													objectFit: "cover",
													width: "100%",
												}}
												component="img"
												// height="240"
												image={prize.image}
												alt="green iguana"
											/>
											<CardContent>
												<Typography
													gutterBottom
													variant="h6"
													component="div"
												>
													{prize.title}
												</Typography>
												<Typography
													variant="body2"
													color="text.secondary"
												>
													{prize.description}
												</Typography>
											</CardContent>
										</Card>
									</Box>
								)}
							</Box>

							<Box my={3}>
								<FormGroup>
									<FormControlLabel
										control={
											<Checkbox
												checked={isGrouped}
												onChange={(e) => {
													setValue("price_limit", "");
													setValue("qty_limit", "");
													if (e.target.checked) {
														unregister("qty_limit");
													} else {
														// register("price_limit");
														register("qty_limit");
													}
													setIsGrouped(
														e.target.checked
													);
												}}
											/>
										}
										label="Grouped List"
									/>
								</FormGroup>
							</Box>
							<Box my={3}>
								{isGrouped ? (
									<>
										<Box
											mb={3}
											display="flex"
											justifyContent="space-between"
										>
											<Typography
												variant="h6"
												gutterBottom
											>
												Groups
											</Typography>
											<Button
												type="button"
												onClick={handleAddGroup}
												color="primary"
												variant="contained"
											>
												{"Add Groups"}
											</Button>
										</Box>
										{groups.length > 0 && (
											<Grid container spacing={2}>
												{groups.map((group, i) => {
													group.expand = false;
													return (
														<Grid item md={3}>
															<Card>
																<CardHeader
																	title={`name:${group.name}`}
																	subheader={`Group Category:${group.product_category[0].name}`}
																/>
																<CardContent>
																	<Typography
																		variant="h6"
																		component="div"
																	>
																		Product:
																	</Typography>
																	<Typography
																		paragraph
																	>
																		<AvatarGroup
																			sx={{
																				justifyContent:
																					"center",
																			}}
																			total={
																				group
																					.products
																					?.length
																			}
																		>
																			{group.products
																				.slice(
																					0,
																					4
																				)
																				.map(
																					(
																						product
																					) => (
																						<Avatar
																							alt={
																								product.name
																							}
																							src={
																								product
																									.image
																									.file
																							}
																						/>
																					)
																				)}
																		</AvatarGroup>
																	</Typography>
																	<Typography
																		variant="h6"
																		component="div"
																	>
																		Qty
																		Limit:
																		{
																			group.qty_limit
																		}
																	</Typography>
																</CardContent>
																<CardActions
																	disableSpacing
																>
																	<IconButton
																		aria-label="add to favorites"
																		onClick={() => {
																			handleGroupEdit(
																				group.id
																			);
																		}}
																	>
																		<Edit />
																	</IconButton>
																	<IconButton
																		aria-label="delete product"
																		onClick={() => {
																			handleGroupOpen(
																				group
																			);
																			// setProducts(new_products)
																		}}
																	>
																		<Delete color="secondary" />
																	</IconButton>
																	<ExpandMoreDiv
																		expand={
																			expandedId ===
																			i
																		}
																		onClick={() =>
																			handleExpandClick(
																				i
																			)
																		}
																		aria-expanded={
																			expandedId ===
																			i
																		}
																		aria-label="show more"
																	>
																		<ExpandMore />
																	</ExpandMoreDiv>
																</CardActions>
																<Collapse
																	in={
																		expandedId ===
																		i
																	}
																	timeout="auto"
																	unmountOnExit
																>
																	<CardContent>
																		<Typography
																			paragraph
																		>
																			Products:
																		</Typography>
																		<Box>
																			{group.products.map(
																				(
																					product
																				) => {
																					return (
																						<Stack
																							direction="row"
																							alignItems="center"
																							gap={
																								1
																							}
																						>
																							<Avatar
																								alt="Remy Sharp"
																								src={
																									product
																										.image
																										.file
																								}
																							/>
																							<Typography
																								paragraph
																								sx={{
																									marginBottom: 0,
																								}}
																							>
																								{
																									product.name
																								}
																							</Typography>
																						</Stack>
																					);
																				}
																			)}
																		</Box>
																	</CardContent>
																</Collapse>
															</Card>
														</Grid>
													);
												})}
											</Grid>
											// <Box my={3}>
											// 	<FormControl
											// 		required
											// 		error={Boolean(
											// 			errors.products
											// 		)}
											// 		variant="outlined"
											// 	>
											// 		<FormLabel>
											// 			Product List
											// 		</FormLabel>
											// 		<FormGroup row>
											// 			{groups.map(group=>
											// 				group.name

											// 			)}
											// 		</FormGroup>
											// 		<FormHelperText>
											// 			{
											// 				errors.products
											// 					?.message
											// 			}
											// 		</FormHelperText>
											// 	</FormControl>
											// </Box>
										)}
									</>
								) : (
									<>
										<Box
											mb={3}
											display="flex"
											justifyContent="space-between"
										>
											<Typography
												variant="h6"
												gutterBottom
											>
												Products
											</Typography>
											<Button
												type="button"
												onClick={handleAddProduct}
												color="primary"
												variant="contained"
											>
												{"Add Products"}
											</Button>
										</Box>
										{products && (
											<Box my={3}>
												<Grid container spacing={2}>
													{products.map((item, i) => (
														<Grid
															item
															xs={12}
															md={3}
														>
															<Card
																sx={{
																	height: "100%",
																	display:
																		"flex",
																	justifyContent:
																		"space-between",
																	flexDirection:
																		"column",
																}}
															>
																<CardMedia
																	component="img"
																	// height="194"
																	image={
																		item
																			.image
																			.file
																	}
																	alt="Paella dish"
																/>
																<CardContent>
																	<Typography
																		variant="body2"
																		color="text.secondary"
																	>
																		{
																			item.name
																		}
																	</Typography>
																</CardContent>
																<CardActions
																	disableSpacing
																	sx={{
																		display:
																			"flex",
																		alignItems:
																			"end",
																		justifyContent:
																			"flex-end",
																	}}
																>
																	<IconButton
																		aria-label="edit"
																		onClick={() => {}}
																	>
																		<Edit />
																	</IconButton>
																	<IconButton
																		aria-label="delete product"
																		onClick={() => {
																			handleOpen(
																				item
																			);
																			// setProducts(new_products)
																		}}
																	>
																		<Delete color="secondary" />
																	</IconButton>
																</CardActions>
															</Card>
														</Grid>
													))}
												</Grid>
											</Box>
										)}
									</>
								)}
							</Box>

							<Box my={3}>
								<Grid container spacing={2}>
									{!isGrouped && (
										<Grid item md={4}>
											<TextField
												fullWidth
												id="outlined-basic"
												label={"Qty Limit"}
												type="number"
												InputProps={{
													inputProps: { min: 0 },
												}}
												{...register("qty_limit", {
													required: "Enter Qty Limit",
												})}
												variant="outlined"
											/>
										</Grid>
									)}
									<Grid item md={4}>
										<Box
											sx={{
												paddingX: 2,
												paddingY: 0.75,
												border: "1px solid rgba(0, 0, 0, 0.23)",
												borderRadius: "4px",
											}}
										>
											<Stack
												direction={"row"}
												justifyContent="space-between"
												alignItems={"center"}
											>
												<FormLabel>
													Save as Draft
												</FormLabel>
												<Switch
													// size="small"

													type="checkbox"
													{...register("active", {})}
													onChange={(e) => {
														setActive(
															!e.target.checked
														);
													}}
													checked={!active}
												/>
											</Stack>
										</Box>
									</Grid>
									<Grid item md={4}>
										<Box
											sx={{
												paddingX: 2,
												paddingY: 0.75,
												border: "1px solid rgba(0, 0, 0, 0.23)",
												borderRadius: "4px",
											}}
										>
											<Stack
												direction={"row"}
												justifyContent="space-between"
												alignItems={"center"}
											>
												<FormLabel>
													For PlayOff
												</FormLabel>
												<Switch
													// size="small"

													type="checkbox"
													{...register(
														"is_playoff",
														{}
													)}
													onChange={(e) => {
														setIsPlayoff(
															e.target.checked
														);
													}}
													checked={isPlayoff}
												/>
											</Stack>
										</Box>
									</Grid>
									<Grid item md={4}>
										<Controller
											name="aesthetic"
											control={control}
											defaultValue={null}
											render={({ field }) => (
												<AestheticInput field={field} />
											)}
										/>
									</Grid>
								</Grid>
							</Box>

							<Box my={3}></Box>

							<Box mb={3}>
								<Button
									type="submit"
									fullWidth
									// onClick={handleSave}
									color="primary"
									variant="contained"
								>
									{"Save"}
								</Button>
							</Box>
						</form>
					</div>
					<Drawer
						anchor="right"
						open={addProduct}
						onClose={() => setAddProduct(false)}
					>
						<AddProductDrawer
							fromGroup={false}
							setEditOpen={setAddProduct}
							products={products}
							setProducts={setProducts}
						/>
					</Drawer>
					<Drawer
						anchor="right"
						open={addGroup}
						onClose={() => setAddGroup(false)}
					>
						<AddGroup
							title={"Add"}
							setEditOpen={setAddGroup}
							setGroups={setGroups}
							edit={groupEdit}
							group={group}
							setGroup={setGroup}
							groups={groups}
						/>
					</Drawer>
					<Drawer
						anchor="right"
						open={addPrize}
						onClose={() => setAddPrize(false)}
					>
						<AddPrizeDrawer
							title={"Add"}
							prize={prize}
							setPrize={setPrize}
							setEditOpen={setAddPrize}
							products={products}
							setProducts={setProducts}
						/>
					</Drawer>
				</Box>
			</Grid>
			<Dialog
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={open}
				onClose={handleClose}
				closeAfterTransition
				// BackdropComponent={Backdrop}
				// BackdropProps={{
				// 	timeout: 500,
				// }}
			>
				<DialogTitle id="alert-dialog-title">
					{"Are you sure?"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Do you want to remove product
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>No</Button>
					<Button
						onClick={() => {
							let new_products = products.filter((product) => {
								return product !== deletingProduct;
							});
							setDeletingProduct({});
							setProducts(new_products);
							handleClose();
						}}
						autoFocus
						color="secondary"
					>
						Yes
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={groupOpen}
				onClose={handleGroupClose}
				closeAfterTransition
				// BackdropComponent={Backdrop}
				// BackdropProps={{
				// 	timeout: 500,
				// }}
			>
				<DialogTitle id="alert-dialog-title">
					{"Are you sure?"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Do you want to remove Group
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleGroupClose}>No</Button>
					<Button
						onClick={() => {
							console.log("====================================");
							console.log(deletingGroup);
							console.log("====================================");
							let new_groups = groups.filter((product) => {
								return product !== deletingGroup;
							});
							console.log("====================================");
							console.log(new_groups);
							console.log("====================================");
							setDeletingGroup(null);
							setGroups(new_groups);
							handleGroupClose();
						}}
						autoFocus
						color="secondary"
					>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		</Grid>
	);
};
