import { Box, Toolbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CurationViewTable from "../../components/Dashboard/ProductViewTable/CurationViewTable";
import { useLocation } from "react-router-dom";
import { getCurationShareGraphDetails } from "../../apis/Api";

function CurationViewTableDetails() {
  const location = useLocation();

  const [selcetedValue, setSelcetedValue] = useState(0);
  const [rows, setRows] = useState([]);
  const [seller, setSeller] = useState({});
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsCount, setRowsCount] = useState(10);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState("all time");

  useEffect(() => {
    setSelcetedValue(location.state.value)
    setFilter(location.state.value)
    console.log("location",location.state.value)
    if (location.state.value == 0) {
      setFilter("all time");
      getData("all time", rowsCount);
    }
    if (location.state.value == 1) {
      setFilter("day");
      getData("day", rowsCount);
    } else if (location.state.value == 2) {
      setFilter("week");
      getData("week", rowsCount);
    } else if (location.state.value == 3){
      setFilter("month");
      getData("month", rowsCount);
    }
   

  }, []);
  
  const handlePage = async (_, i) => {
    let data = "";
   

    data = await getCurationShareGraphDetails(filter, rowsCount, i + 1);

    setRows(data.results);
    setPage(i);
    // console.log("row", rowsCount)
  };
  const handleRowsCount = async (i) => {
    setRowsCount(i);
    setLoading(true);
    let data = "";

    data = await getCurationShareGraphDetails(filter, i);

    setRows(data.results);
    setLoading(false);
  };

  const handleChange = (event) => {
    setSelcetedValue(event.target.value);
    // console.log("value", event.target.value)
    setRowsCount(10);
    if (event.target.value == 0) {
      setFilter("all time");

      getData("all time", rowsCount);
    } else if (event.target.value == 1) {
      setFilter("day");

      getData("day", rowsCount);
    } else if (event.target.value == 2) {
      setFilter("week");
      getData("week", rowsCount);
    } else {
      setFilter("month");
      getData("month", rowsCount);
    }
  };
  const getData = async (filter, rowsCount) => {
    let data = "";

    data = await getCurationShareGraphDetails(filter, rowsCount);

    setRows(data.results);
    setCount(data.count);
    console.log("Curation_data", data);
    // console.log("count", count)
  };

  return (
    <Box sx={{ padding: "40px 40px 0 40px" }}>
      <Toolbar sx={{ float: "right" }}>
        <Box display="flex" justifyContent="space-evenly">
          <Typography
            gutterBottom
            variant="h4"
            component="div"
            sx={{ width: "55%" }}
          >
            Curation Share Count
          </Typography>

          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            value={selcetedValue}
            onChange={handleChange}
            sx={{
              border: "none",
              minWidth: "125px",
              padding: 0,
              height: "40px",
              borderRadius: "20px",
              ".MuiSelect-select": {
                paddingRight: "20px !important",
              },
              ".MuiSelect-outlined": {
                border: "none",
                outline: "none",
              },
              "&:focus": {
                backgroundColor: "red",
              },
            }}
            variant="outlined"
          >
            <MenuItem value="0">All Time</MenuItem>
            <MenuItem value="1">Day</MenuItem>
            <MenuItem value="2">Week</MenuItem>
            <MenuItem value="3">Month</MenuItem>
          </Select>
        </Box>
      </Toolbar>
      <CurationViewTable
        page={page}
        rowsCount={rowsCount}
        handlePage={handlePage}
        handleRowsCount={handleRowsCount}
        count={count}
        rows={rows}
        loading={loading}
        setRows={setRows}
        filter={filter}
        // type={location.state.type}

      />
    </Box>
  );
}

export default CurationViewTableDetails;
