import { Height } from "@mui/icons-material";
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Slider,
	
} from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useState } from "react";
import Cropper from "react-easy-crop";

import getCroppedImg from "../../utils/cropImage";

export const ProductImageCropper = ({ image, open, setOpen,setImage }) => {
	console.log("yesopen", open, image);

	const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") 
            return;
        setOpen(false);
    }
	// const [imgSrc, setImgSrc] = useState('')
	// const previewCanvasRef = useRef<HTMLCanvasElement>(null)
	
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [rotation, setRotation] = useState(0);
	const [zoom, setZoom] = useState(1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
	

	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels);
	}, []);
	const showCroppedImage = useCallback(async () => {
		try {
		  const croppedImage = await getCroppedImg(
		    image,
		    croppedAreaPixels,
		    0
		  )
		  console.log('donee', { croppedImage })
		  setImage(croppedImage)
          setOpen(false)
		} catch (e) {
		  console.error(e)
		}
	},[croppedAreaPixels]);
	return (
		<Dialog fullWidth open={open} onClose={handleClose}>
			<DialogTitle>Crop Product Image</DialogTitle>
			<DialogContent>
				<Box sx={{ textAlign: "center", height: "700px" }}>
					<div className="crop-container">
						<Cropper
							image={image}
							crop={crop}
							rotation={rotation}
							zoom={zoom}
							aspect={3 / 4}
							onCropChange={setCrop}
							onRotationChange={setRotation}
							onCropComplete={onCropComplete}
							onZoomChange={setZoom}
							objectFit="contain"
						/>
					</div>
					<div className="controls">
						<Slider
							// sx={{ paddingX: 3 }}
							value={zoom}
							min={1}
							max={3}
							step={0.1}
							aria-labelledby="Zoom"
							onChange={(e, zoom) => setZoom(zoom)}
							classes={{ container: "slider" }}
						/>
						<Button sx={{marginLeft:2}} variant="contained" onClick={showCroppedImage}>
							Crop
						</Button>
					</div>
                    
				</Box>
			</DialogContent>
			{/* <DialogActions></DialogActions> */}
		</Dialog>
	);
};
