import React, { useState } from "react";
import { Close } from "@mui/icons-material";
import {
	Avatar,
	Box,
	Button,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	IconButton,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import { ToastContainer, toast } from "react-toastify";
import { createSeller, editSeller, getAllSeller } from "../../apis/Seller";
import Loader from "../Loader/Loader";
import validator from "validator";
import { ImageCropper } from "../CommonFunctions/ImageCropper";

export const EditSellerDrawer = ({
	edit,
	setEditOpen,
	seller,
	setSeller,
	setRows,
}) => {
	const [loading, setLoading] = useState(false);
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setSeller({ ...seller, [name]: value });
	};

	const [filename, setFilename] = useState("");
	const [crop, setCrop] = useState(false);
	
	const handleOnChange = (event) => {
		const newImage = event.target?.files?.[0];
		console.log(newImage);
		if (newImage) {
			// console.log(newImage);
			setFilename(newImage.name);
			setImage(URL.createObjectURL(newImage));
			setCrop(true);
		}
	};
	const [image, setImage] = useState(seller.logo ? seller.logo.file : null);

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm();

	const onSubmit = async (data) => {
		console.log(data);
		setLoading(true);
		let formData = new FormData();
		for (let i in data) {
			if (i === "file") {
				console.log(i, data[i]);
				if (data[i].length) {
					let blob = await fetch(image).then(r => r.blob());
					formData.append('file', blob, filename);
					// formData.append("file", data[i][0]);
				}
			} else formData.append(i, data[i]);
		}
		let res = edit
			? await editSeller(formData, seller.id)
			: await createSeller(formData);
		console.log("====================================");
		console.log(res);
		console.log("====================================");
		if (res.success) {
			let newSellers = await getAllSeller();
			setRows(newSellers.results);
			setEditOpen(false);
		} else {
			setLoading(false);
			notify("Please Try again later");
		}
	};

	const notify = (m) =>
		toast.error(m, {
			position: "top-right",
			autoClose: 2500,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});

	// const handleURL = async (e) => {

	// 	setUrl(e.target.value);
	// 	if(e.target.value){
	// 	const data = await getProductPreview(e.target.value);
	// 	setProductPreview(data.data);
	// 	}
	// }

	return (
		<div
			style={{
				width: 600,
				padding: 5,
				height: "100vh",
				overflowY: loading ? "hidden" : "scroll",
			}}
		>
			{loading && <Loader />}
			<IconButton
				style={{ position: "fixed" }}
				onClick={() => setEditOpen(false)}
			>
				<Close />
			</IconButton>
			<ToastContainer />
			<div style={{ padding: "45px 55px 0px" }}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Box display="flex" justifyContent="space-between">
						<Typography variant="h6" gutterBottom>{`${
							edit ? "Edit" : "Add"
						} Seller`}</Typography>
						<Button
							type="submit"
							// onClick={handleSave}
							color="primary"
							variant="contained"
						>
							{edit ? "Update" : "Save"}
						</Button>
					</Box>

					<Box my={3}>
						<TextField
							fullWidth
							name="name"
							label="Name"
							variant="outlined"
							value={seller.name ? seller.name : ""}
							error={Boolean(errors.name)}
							helperText={errors.name?.message}
							{...register("name", {
								required: "Enter Seller name",
							})}
							onChange={handleInputChange}
						/>
					</Box>
					<Box my={3}>
						<TextField
							fullWidth
							name="website"
							// style={{ width: "100%" }}
							value={seller.website ? seller.website : ""}
							label="Enter Website url"
							variant="outlined"
							{...register("website", {
								required: "Enter seller website url",
								validate: {
									isURL: (value) =>
										validator.isURL(value) ||
										"Please enter a valid url",
								},
							})}
							onChange={handleInputChange}
							error={Boolean(errors.website)}
							helperText={errors.website?.message}
						/>
					</Box>
					<Box my={3}>
						<FormLabel id="locality-group">Seller Market</FormLabel>
						<FormControl fullWidth error={Boolean(errors.seller_market)}>
							<Controller
								control={control}
								defaultValue={
									seller.seller_market ? seller.seller_market : ""
								}
								name="seller_market"
								// onChange={handleInputChange}
								rules={{
									required: "Please pick one from options",
								}}
								render={({ field }) => {
									console.log(field);
									return (
										<RadioGroup
											{...field}
											value={field.value}
											onChange={(e, value) => {
												handleInputChange(e);
												field.onChange(value);
											}}
											row
										>
											<FormControlLabel
												value="Local"
												control={<Radio />}
												label="Local"
											/>
											<FormControlLabel
												value="International"
												control={<Radio />}
												label="International"
											/>
										</RadioGroup>
									);
								}}
							/>

							{Boolean(errors.seller_market) && (
								<FormHelperText>
									{errors.seller_market?.message}
								</FormHelperText>
							)}
						</FormControl>
					</Box>
					<Box my={3}>
						<FormControl error={Boolean(errors.file)}>
							{image && (
								<Avatar
									variant="square"
									src={image}
									sx={{
										width: 150,
										height: 150,
										objectFit: "fill",
										marginBottom: 2,
									}}
								/>
							)}
							<Button
								variant="contained"
								component="label"
								onChange={handleOnChange}
							>
								{!image ? "Upload " : "Change "}image
								<input
									hidden
									accept="image/*"
									id="file-upload"
									type="file"
									{...register("file", {
										required: image == null,
									})}
									error={errors.file}
								/>
							</Button>

							{Boolean(errors.file) && (
								<FormHelperText>Image required</FormHelperText>
							)}
						</FormControl>
					</Box>
				</form>
				<ImageCropper
					image={image}
					setImage={setImage}
					open={crop}
					setOpen={setCrop}
					aspect={1}
				/>
			</div>
		</div>
	);
};
