import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import ProductCardGroupList from "./ProductCardGroupList";
import PropTypes from "prop-types";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ pt: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  

function GroupListTab(props) {  
    
  const {products, setProducts,group,value,index}=props; 
  const [tempGroupList, setTempGroupList] = useState(group.products.filter((product)=>products.includes(product.id)));
  console.log(group.products.filter((product)=>products.includes(product.id)))
  
  
  
  return (
    <TabPanel value={value} index={index} key={index}>
    <Grid container spacing={2}>
    {group.products.map((item) => {
      return (
        <Grid item md={3} key={item.id}>
          <ProductCardGroupList
            product={item}
            setProducts={setProducts}
            products={products} 
            tempGroupList={tempGroupList}
            setTempGroupList={setTempGroupList}   
            quantity_limit= {group.qty_limit}      
          ></ProductCardGroupList>
        </Grid>
      );
    })}
  </Grid>
  </TabPanel>
  );
}

export default GroupListTab;
