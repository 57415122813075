import axios from "axios";
import { axiosInstance } from "../../config/axios";
import { BaseURLwithEndPoint } from "../config";

let base = BaseURLwithEndPoint();




export const getAdmitadToken = async () => {   
    try {
		const res = await axiosInstance.get(base+"get-admitad-token/");
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};
export const getAdSpaceId = async () => {   
    try {
		const res = await axiosInstance.get(base+"get-ad-space-id/");
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};

