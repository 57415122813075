import React, { useEffect, useRef, useState } from "react";
import "react-image-crop/dist/ReactCrop.css";
import {
	Avatar,
	Backdrop,
	Box,
	Button,
	Fab,
	Fade,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Grid,
	IconButton,
	ImageList,
	ImageListItem,
	ImageListItemBar,
	InputLabel,
	Link,
	ListSubheader,
	MenuItem,
	Modal,
	OutlinedInput,
	Radio,
	RadioGroup,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import { Add, Close, Info, Check } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import { Controller } from "react-hook-form";
import { getAllCategory } from "../../apis/ProductCategory";
import _ from "lodash";
import { getAllSeller } from "../../apis/Seller";
import { getAllBrand } from "../../apis/Brand";
import { CategoryAutocomplete } from "../Form/CatetoryAutoComplete";
import ReactCrop from "react-image-crop";
import { ProductImageCropper } from "./ImageCropper";
import { getColors } from "../../apis/Product";

const thumb = {
	borderRadius: 2,
	border: "1px solid #eaeaea",
	marginBottom: 8,
	padding: 4,
	boxSizing: "border-box",
};

const thumbInner = {
	position: "relative",
	display: "flex",
	minWidth: 0,
	overflow: "hidden",
};

const img = {
	display: "block",
	height: "fit-content",
	alignSelf: "center",
};

export const ProductCard = ({
	product,
	light,
	setProducts,
	pre,
	handleSave,
	clear,
	big,
	errors,
	register,
	selectedSeller,
	setSelectedSeller,
	setValue,
	existingProduct,
	setCroppedImage,
	croppedImage,
	setFileName,
	category,
	setCategory,
	showCategory,
	setShowCategory,
	edit = false,
	selectedCategory = null,
	affiliated_url
	
}) => {
	const [open, setOpen] = React.useState(false);
	const handleOpen = async (event) => {
		if (event.target.files.length) {
			setFile(URL.createObjectURL(event.target.files[0]));
			setFileName(event.target.files[0].name);
			setOpen(true);
		}
	};

	const [hover, setHover] = useState(false);
	const [discounPrice, setDiscounPrice] = useState(
		edit ? product.discount_price : product.discounted_price
	);
	const [originalPrice, setOriginalPrice] = useState(
		edit ? product.original_price : product.mrp
	);
	const [seller, setSeller] = useState([]);
	const [name, setName] = useState(
		edit ? product.name : product.parser_exist ? product.name : ""
	);
	const [brand, setBrand] = useState([]);
	const [selectedBrand, setSelectedBrand] = useState([]);
	const [image, setImage] = useState("");
	const [file, setFile] = useState("");
	const [changeImage, setChangeImage] = useState(
		edit ? false : product.parser_exist
	);
	const [colors, setColors] = useState([]);
	const [primaryColor, setPrimaryColor] = useState('');
	const [secondaryColor, setSecondaryColor] = useState('');
	const [affUrl, setAffUrl] = useState(affiliated_url!=null?affiliated_url:"");
	
	
	
	

	useEffect(() => {
		console.log("====================================");
		console.log(edit);
		console.log("====================================");
		_.debounce(async () => {
			let sellers = await getAllSeller();
			setSeller(sellers.results);
			let brands = await getAllBrand();
			let colorsRespone= await getColors()
			setColors(colorsRespone)
			setBrand(brands.results);
			if (existingProduct) {
				console.log("===================")
				console.log(existingProduct)
				console.log("===================")
				if (edit) {
					setCroppedImage(product.image.file);
				}
				setSelectedSeller(existingProduct.seller.id);
				setSelectedBrand(existingProduct.brand.id);
				setValue("seller", existingProduct.seller.id);
				setValue("brand", existingProduct.brand.id);
				setValue("product_category", existingProduct.product_category);
				setPrimaryColor(existingProduct.primary_color)
				setValue('primary_color', existingProduct.primary_color)
				setSecondaryColor(existingProduct.secondary_color)
				setValue('secondary_color', existingProduct.secondary_color)
				setAffUrl(existingProduct.affiliated_url)
				if(existingProduct.affiliated_url!=null){
					setValue('affiliated_url', existingProduct.affiliated_url)
					
				}
				
			} else {
				if (product.parser_exist) {
					let selseller =
						sellers.results.length && product != null
							? sellers.results.find(
									(seller) =>
										seller.name.toLowerCase() ===
										product.supplier.toLowerCase()
							  )
							: null;
					setSelectedSeller(selseller ? selseller.id : "");
					setValue("seller", selseller ? selseller.id : "");

					// setValue("seller", selseller ? selseller.id : "");
				}
			}
		}, 200)();
	}, []);

	const addAsync = async () => {
		if (light) {
			setProducts((prev) =>
				prev.filter((e) => e !== product["_id"]["$oid"])
			);
			return;
		} else if (product.product_id) {
			setProducts((prev) => {
				if (prev.includes(product.product_id)) {
					notify();
					return prev;
				}
				return [...prev, product.product_id];
			});
		} else {
			const id = await handleSave();
			setProducts((prev) => {
				return [...prev, id];
			});
		}
		clear();
	};
	const notify = () =>
		toast.error(`Product already added`, {
			position: "bottom-right",
			autoClose: 2500,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	return (
		<>
			<Box my={3}>
				<TextField					
					fullWidth
					name="affiliated_url"					
					label="Afiliated Link"
					variant="outlined"
					value={affUrl}
					InputLabelProps={{
						shrink: true,
					  }}
					  InputProps={{
						readOnly: true,
					  }}
					{...register("affiliated_url", {						
						
					})}
					
					
				/>
			</Box>

			<Box my={3}>
				<TextField
					// InputProps={{
					// 	readOnly: product.parser_exist ? true : false,
					// }}
					fullWidth
					name="name"
					// style={{ width: "100%" }}
					label="Product Name"
					variant="outlined"
					value={name}
					error={Boolean(errors.name)}
					helperText={errors.name?.message}
					{...register("name", {
						required: "Enter product name",
						onChange: (e) => {
							setName(e.target.value);
							setValue("name", e.target.value);
						},
					})}
				/>
			</Box>
			
			<Box my={3}>
				<FormLabel>Product Category</FormLabel>
				<CategoryAutocomplete
					title="Product Category"
					showCategory={showCategory}
					setShowCategory={setShowCategory}
					category={category}
					setCategory={setCategory}
					selectedCategory={selectedCategory}
				/>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<FormControl fullWidth error={Boolean(errors.brand)}>
							<InputLabel htmlFor="brand">Brand</InputLabel>
							<Select
								{...register("brand", {
									required: "select Brand",
								})}
								value={selectedBrand}
								input={<OutlinedInput label="Brand" />}
								defaultValue={selectedBrand}
								onChange={(e) => {
									setSelectedBrand(e.target.value);
								}}
							>
								{brand.map((product, i) => {
									return (
										<MenuItem
											key={`brand-${i}`}
											value={product.id}
										>
											{product.name}
										</MenuItem>
									);
								})}
							</Select>

							<FormHelperText>
								{errors.brand && errors.brand?.message}
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl fullWidth error={Boolean(errors.seller)}>
							<InputLabel htmlFor="seller">Seller</InputLabel>
							<Select
								{...register("seller", {
									required: "Select Seller",
								})}
								displayEmpty
								input={<OutlinedInput label="Seller" />}
								// defaultValue={""}
								value={selectedSeller ? selectedSeller : ""}
								// defaultValue={
								// 	selectedSeller ? selectedSeller : ""
								// }
								onChange={(e) => {
									setSelectedSeller(e.target.value);
								}}
							>
								{seller.map((product, i) => {
									return (
										<MenuItem
											key={`seller-${i}`}
											value={product.id}
										>
											{product.name}
										</MenuItem>
									);
								})}
							</Select>
							<FormHelperText>
								{errors.seller && errors.seller?.message}
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl fullWidth error={Boolean(errors.primary_color)}>
							<InputLabel htmlFor="primary_color">Primary Color</InputLabel>
							<Select
								{...register("primary_color", {
									required: "Select Primary Color",
								})}								
								input={<OutlinedInput label="Primary Color" />}						
								// value={primaryColor}
								// defaultValue={primaryColor}
								value={primaryColor ? primaryColor : ""}
								onChange={(e) => {
									setPrimaryColor(e.target.value);
								}}
							>
								{colors?.map((item, i) => {
									return (
										<MenuItem
											key={`seller-${i}`}
											value={item.id}
										>
											{item.name}
										</MenuItem>
									);
								})}
							</Select>
							<FormHelperText>
								{errors.primary_color && errors.primary_color?.message}
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl fullWidth error={Boolean(errors.secondary_color)}>
							<InputLabel htmlFor="secondary_color">Secondary Color</InputLabel>
							<Select
								{...register("secondary_color", {
									
								})}
								displayEmpty
								input={<OutlinedInput label="Secondary Color" />}
								
								value={secondaryColor ? secondaryColor : ""}
								
								onChange={(e) => {
									setSecondaryColor(e.target.value);
								}}
							>
								{colors?.map((item, i) => {
									return (
										<MenuItem
											key={`seller-${i}`}
											value={item.id}
										>
											{item.name}
										</MenuItem>
									);
								})}
							</Select>
							<FormHelperText>
								{errors.secondary_color && errors.secondary_color?.message}
							</FormHelperText>
						</FormControl>
					</Grid>

					<Grid item xs={6}>
						<TextField
							type={"number"}
							// InputProps={{
							// 	readOnly: product.parser_exist ? true : false,
							// 	inputProps: { min: 0 },
							// }}
							fullWidth
							name="discount_price"
							// style={{ width: "100%" }}
							label="Discounted Price"
							variant="outlined"
							{...register("discount_price")}
							value={discounPrice}
							onChange={(e) => {
								setDiscounPrice(e.target.value);
							}}
							// error={Boolean(errors.name)}
							// helperText={errors.name?.message}
							// {...register("name", {
							// 	required: "Enter Brand name",
							// })}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							InputProps={{
								// readOnly: product.parser_exist ? true : false,
								inputProps: { min: 0 },
							}}
							type="number"
							fullWidth
							name="original_price"
							// style={{ width: "100%" }}
							label="Price"
							variant="outlined"
							value={originalPrice}
							{...register("original_price")}
							// error={Boolean(errors.name)}
							// helperText={errors.name?.message}
							// {...register("name", {
							// 	required: "Enter Brand name",
							// })}
							onChange={(e) => {
								setOriginalPrice(e.target.value);
							}}
						/>
					</Grid>
				</Grid>
			</Box>
			{!edit && product.success && (
				<Button
					onClick={() => {
						setChangeImage((pre) => !pre);
					}}
				>
					Update from {changeImage ? "System" : "List of Images"}
				</Button>
			) }

			
			{changeImage && (!edit && product.success) ? (
				<div
					style={{
						...thumb,
						width: light ? (big ? 272 : 146) : "auto",
					}}
					key={product.product_id}
				>
					<FormControl
						sx={{ mx: 1 }}
						error={Boolean(errors.image)}
						variant="standard"
					>
						<FormHelperText>
							{errors.image && errors.image?.message}
						</FormHelperText>
						<RadioGroup
							row
							aria-labelledby="demo-radio-buttons-group-label"
							// defaultValue={0}
							onChange={(e) => {
								setImage(e.currentTarget.value);
							}}
							value={image}
							name="image"
						>
							<ImageList sx={{ height: 450 }} cols={3}>
								{" "}
								{product?.images
									?.slice(0, light ? 1 : 5)
									?.map((img, i) => (
										<ImageListItem key={i}>
											<img
												alt=""
												loading="lazy"
												srcSet={`${img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
												src={`${img}?w=164&h=164&fit=crop&auto=format`}
												// style={{
												// 	...img,
												// 	width: big ? 262 : 136,
												// }}
											/>
											<ImageListItemBar
												sx={{
													background: "transparent",
												}}
												// title={"s"}
												position={"top"}
												actionIcon={
													<FormControlLabel
														value={img}
														control={
															<Radio
																sx={{
																	pl: 2,
																}}
																{...register(
																	"image",
																	{
																		required:
																			"Please select one image",
																	}
																)}
																checkedIcon={
																	<Check />
																}
															/>
														}
													/>
												}
												actionPosition="left"
											/>
										</ImageListItem>
									))}
							</ImageList>
						</RadioGroup>
					</FormControl>

					<div
						onMouseEnter={() => setHover(true)}
						onMouseLeave={() => setHover(false)}
						style={thumbInner}
					>
						{/* {product?.images?.slice(0, light ? 1 : 5)?.map((image) => (
					<img
						alt=""
						src={image}
						style={{ ...img, width: big ? 262 : 136 }}
					/>
				))} */}
						<ToastContainer />
						{hover && !pre && (
							<div
								style={{
									position: "absolute",
									left: "50%",
									top: "50%",
								}}
							>
								<Fab
									color="secondary"
									size="small"
									style={{
										position: "relative",
										left: "-50%",
										top: -20,
									}}
									onClick={addAsync}
								>
									{light ? <Close /> : <Add />}
								</Fab>
							</div>
						)}
					</div>
				</div>
			) : (
				<Box my={3}>
					<FormControl error={Boolean(errors.file)}>
						{croppedImage && (
							<Avatar
								variant="square"
								src={croppedImage}
								sx={{
									width: 210,
									height: 280,
									objectFit: "fill",
									marginBottom: 2,
								}}
							/>
							// <Box
							// 	component={"img"}
							// 	alt="text"
							// 	src={image}
							// 	sx={{
							// 		// height: 233,
							// 		width: 350,
							// 		// maxHeight: { xs: 233, md: 167 },
							// 		maxWidth: { xs: 350, md: 250 },
							// 	}}
							// />
						)}
						<Button
							variant="contained"
							component="label"
							onChange={handleOpen}
						>
							{!croppedImage ? "Upload " : "Change "}image
							<input
								hidden
								accept="image/*"
								id="file-upload"
								type="file"
								{...register("file", {
									required: ((!edit && !product.success))
								})}
								error={Boolean(errors.file)}
							/>
						</Button>
						<FormHelperText>
							Upload image with aspect ratio 3:4 min size of
							210*280, <br /> You can use our cropper to crop a
							image
						</FormHelperText>

						{errors.file && (
							<FormHelperText>
								{errors.file?.message}
							</FormHelperText>
						)}
					</FormControl>
				</Box>
			)} 

			{open && (
				<ProductImageCropper
					open={open}
					setOpen={setOpen}
					image={file}
					setImage={setCroppedImage}
				/>
			)}
			{/* <Link href={product.product_url}>
				<Box py={2}>
					<Typography>{product.name}</Typography>
				</Box>
			</Link> */}
		</>
	);
};
