
import { axiosInstance } from "../config/axios";

const handlerEnabled = true

const endpoint ="listcategory/";

export const getTags = async (row,page=1) => {
	try {
		const res = await axiosInstance.get(endpoint,{params:{page_size:row,page:page},handlerEnabled});

		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};

export const setActiveInactiveTag = async (data) => {
	// const api = `listcategory/${data.id}/`;
	const res = await axiosInstance.put(`${endpoint}${data.id}/`, data);
	return res.data;
};

export const addTag = async (data) => {
	// const api = `listcategory/`;
	const res = await axiosInstance.post(endpoint, data);
	return res.data;
};

export const editTag = async (params,id) => {
	try{
	const res = await axiosInstance.put(`${endpoint}${id}/`, params);
	const { data } = res;
		data["success"] = true;
		return res.data;
	}
	catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};