import React, { useState } from "react";
// import { styled } from '@mui/material/styles';
import { makeStyles } from "tss-react/mui";
import { Add } from "@mui/icons-material";
import { Button, Drawer, Toolbar, Typography } from "@mui/material";

import {
	getAllProducts,
	getUsers,
	deleteProduct,
	getProdTags,
	getCount,
} from "../../apis/Api";

import { ToastContainer, toast } from "react-toastify";

import _ from "lodash";
import { useForm } from "react-hook-form";
import { EditProductDrawer } from "../../components/Products/EditProductDrawer";
import DataTable from "../../components/Products/ProductTable";
import { Box } from "@mui/system";
import { AddProductDrawer } from "../../components/Products/AddProductDrawer";
import { getAllProduct } from "../../apis/Product";
import { getAdSpaceId, getAdmitadToken } from "../../apis/Admited api/Api";


const useStyles = makeStyles()((theme) => ({
	title: {
		flexGrow: 1,
		display: "none",
		[theme.breakpoints.up("sm")]: {
			display: "block",
		},
	},
}));

export const Products = ({ searchText }) => {
	const { classes } = useStyles();
	const [category, setCategory] = useState(null);
	const [editOpen, setEditOpen] = React.useState(false);
	const [addOpen, setAddOpen] = React.useState(false);
	const [title, setTitle] = React.useState("Add");
	const [rows, setRows] = React.useState([]);
	const [product, setProduct] = React.useState({});
	const [users, setUsers] = React.useState([]);
	const [tags, setTags] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [page, setPage] = React.useState(0);
	const [rowsCount, setRowsCount] = React.useState(10);
	const [count, setCount] = React.useState(0);

	React.useEffect(() => {
		setLoading(true);
		(async () => {
			// const data = await getUsers();
			// setUsers(data);
		})();
		(async () => {
			// const data = await getProdTags();
			// console.log(data);
			// setTags(data);
		})();
		setLoading(false);
		
		
		
	}, []);

	React.useEffect(() => {
		setLoading(true);
		_.debounce(async () => {
			const data = await getAllProduct(rowsCount);
			setRows(data.results);
			// const cnt = await getCount(searchText, "product");
			setCount(data.count);
		}, 500)();
		setLoading(false);
	}, [searchText]);

	const notify = () =>
		toast.error(`Some unexpected error occured`, {
			position: "bottom-right",
			autoClose: 2500,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});

	const handleAddProduct = () => {
		setTitle("Add");
		setProduct({});
		setAddOpen(true);
	};

	const handleEditProduct = (product) => {
		setTitle("Edit");
		setProduct(product);
		setCategory(product.category)
		setEditOpen(true);
	};

	const handleDeleteProduct = async (i) => {
		// setLoading(true);
		// const res = await deleteProduct(rows[i]["_id"]["$oid"]);
		// if (res.status !== 200) {
		// 	notify();
		// 	return;
		// }
		// const data = await getAllProducts(
		// 	searchText,
		// 	1,
		// 	rows[rows.length - 1]["last_updated_time"],
		// 	rows[rows.length - 1]["_id"]["$oid"]
		// );
		// setRows((prev) => [...prev.filter((_, j) => j !== i), ...data]);
		// setLoading(false);
	};

	const handlePage = async (_, i) => {
		const data = await getAllProduct(rowsCount, i + 1);
		setRows(data.results);
		setPage(i);
	};

	const handleRowsCount = async (i) => {
		setRowsCount(i);
		setLoading(true);
		const data = await getAllProduct(i);
		setRows(data.results);
		setLoading(false);
	};

	return (
		<>
			<Box px={5}>
				<Toolbar>
					<Typography className={classes.title} variant="h4" noWrap />
					<Button
						onClick={() => handleAddProduct()}
						startIcon={<Add />}
						variant="contained"
						color="primary"
					>
						Add Products
					</Button>
				</Toolbar>
				<ToastContainer />
				<DataTable
					handleEdit={handleEditProduct}
					handleDelete={handleDeleteProduct}
					page={page}
					rowsCount={rowsCount}
					handlePage={handlePage}
					handleRowsCount={handleRowsCount}
					count={count}
					rows={rows}
					loading={loading}
				/>
				<Drawer
					anchor="right"
					open={addOpen}
					onClose={() => setAddOpen(false)}
				>
					<EditProductDrawer
						title={title}
						setEditOpen={setAddOpen}
						product={product}
						setRows={setRows}
						page={page}
						rowsCount={rowsCount}
						
					/>
				</Drawer>
				
			</Box>
		</>
	);
};
