import { ListItem, IconButton, Popover, Box, Typography } from "@mui/material"
// import { useHistory } from 'react-router-dom';
import { useState } from 'react'

export const UserMenu = ({ user }) => {
	const [open, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	// const history = useHistory();

	const toggleMenu = (e) => {
		setAnchorEl(e.currentTarget);
		setOpen(prev => !prev);
	}

	const logout = () => {
		localStorage.removeItem('token')
		localStorage.removeItem('user')
		window.location.reload(false);
		// history.go(0);
	}
	return (
		<>
			<IconButton onClick={toggleMenu}>
				<img src={user?.avatar ?? 'https://img.icons8.com/fluency-systems-regular/48/000000/user.png'} alt='user' width={25} height={25} style={{ borderRadius: '50%' }} />
			</IconButton>
			<Popover
				anchorEl={anchorEl}
				anchorReference={'anchorEl'}
				onClose={toggleMenu}
				open={open}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<Box p={1}>
					<Typography style={{ margin: 15 }}>{user?.first_name} {user?.last_name}</Typography>
					<Typography style={{ margin: 15 }}>{user?.email}</Typography>
					<ListItem button style={{ padding: 10 }} onClick={logout}><img src="https://img.icons8.com/dotty/80/000000/logout-rounded-left.png" alt='' width={30} style={{ marginRight: 10 }} /> Logout</ListItem>
				</Box>
			</Popover>
		</>
	)
}