import {
	Box,
	Button,
	CircularProgress,
	FormControl,
	FormHelperText,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { PropTypes } from "prop-types";
import { useForm } from "react-hook-form";
import { useState } from "react";
// import { login } from "../../services/Auth.service";
import { Link, Navigate, useNavigate } from "react-router-dom";
import validator from "validator";

import { setToken, signup } from "../../store/authentication/service";

export const Register = () => {
	const [showPassword, setShowPassword] = useState(false);
	const notify = () => {
		// toast.error(`Please enter correct details`, {
		// 	position: "bottom-right",
		// 	autoClose: 2500,
		// 	hideProgressBar: false,
		// 	closeOnClick: true,
		// 	pauseOnHover: true,
		// 	draggable: true,
		// 	progress: undefined,
		// });
	};
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const onSubmit = async (data) => {
		setLoading(true);
		let user = await signup(data);
		console.log(user);
		if (user) {
			console.log(user);
			// await setAxiosAuthorization(isSuccess.access_token)
			// localStorage.setItem('token', isSuccess.access_token)
			// console.log(JSON.stringify(isSuccess.user));
			// localStorage.setItem('user', JSON.stringify(isSuccess.user))
			setToken(user);
			setLoading(false);
			navigate("/w86zjruk6q/")
		

			// setUser(isSuccess);
		} else {
			notify();
		}
	};
	return (
		<Box>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Box pb={3}>
					<Box pb={3}>
						<TextField
							required
							fullWidth
							label="Name"
							variant="outlined"
							error={Boolean(errors.name)}
							helperText={errors.name?.message}
							{...register("name", {
								required: "Enter your name",
							})}
						/>
					</Box>
					<TextField
						// required
						fullWidth
						label="Email"
						variant="outlined"
						error={Boolean(errors.email)}
						helperText={errors.email?.message}
						{...register("email", {
							required: "Enter your email id",
							validate: {
								isEmail: (value) =>
									validator.isEmail(value) ||
									"Not a valid email",
							},
						})}
					/>
				</Box>
				<Box pb={5}>
					<FormControl
						error={Boolean(errors.password)}
						fullWidth
						variant="outlined"
					>
						<InputLabel htmlFor="outlined-adornment-password">
							Password*
						</InputLabel>
						<OutlinedInput
							id="outlined-adornment-password"
							type={showPassword ? "text" : "password"}
							{...register("password", {
								required: "Enter Password",
								minLength: {
									value: 8,
									message: "Must be 8 characters",
								},
							})}
							// value={values.password}
							// onChange={handleChange("password")}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={() =>
											setShowPassword((prev) => !prev)
										}
										edge="end"
									>
										{showPassword ? (
											<VisibilityOff />
										) : (
											<Visibility />
										)}
									</IconButton>
								</InputAdornment>
							}
							label="Password*"
						/>
						<FormHelperText>
							{errors.password?.message}
						</FormHelperText>
					</FormControl>
				</Box>
				<Button
					style={{ width: "100%", marginBottom: 20 }}
					type="submit"
					size="large"
					color="primary"
					variant="contained"
					// onClick={login ? handleLogin : handleSignUp}
				>
					{loading ? (
						<CircularProgress size={26} />
					) :"Register"
					}
				</Button>
			</form>
		</Box>
	);
};
