import { Switch } from '@mui/material';
import React, { useState } from 'react'
import { editUser } from '../../apis/user';


export default function StatusSwitch({value,user}) {
    const [active, setActive] = useState(value);
    const handleClick= async()=>{
        setActive(pre=>!pre)
        user={...user,allow_notification:!active}
        await editUser(user, user.slug);
		// if (res.success) {
		// 	navigate("/w86zjruk6q/curation");
		// }

    }
  return (
    <Switch
    checked={active}
    onChange={handleClick}
    inputProps={{
        "aria-label": "controlled",
    }}
/>
  )
}
