import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import ProductCard from "./ProductCard";
import GroupListTab from "./GroupListTab";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Grouplist(props) {
  const { groupList ,setProducts,products} = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  

  return (
    <Box sx={{  }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" , maxWidth:"100%"}}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
        scrollButtons="auto"
          aria-label="basic tabs example"
        >
          {groupList.map((group, i) => {
            return <Tab label={group.name} {...a11yProps(i)} key={i} />;
          })}
        </Tabs>
      </Box>
      {groupList.map((group, i) => {
        return (         
            <GroupListTab group={group} products={products} setProducts={setProducts} value={value} index={i}></GroupListTab>         
        );
      })}
    </Box>
  );
}

export default Grouplist;
