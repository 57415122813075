



import { useLottie } from "lottie-react";
import groovyWalkAnimation from "../../assets/65364-loader.json"
import Lottie from "lottie-react";
import { Box} from "@mui/system";

function Loader() {
	const options = {
		animationData: groovyWalkAnimation,
		loop: true
	  };
	
	  
	
	  return <Box
	  display="flex"
	  justifyContent="center"
	  alignItems="center"
	  minHeight="100vh"
	><Lottie style={{width:"200px"}} animationData={groovyWalkAnimation}></Lottie></Box>;
}

export default Loader;
