import React, { useState } from "react";
import { Close } from "@mui/icons-material";
import { useForm } from "react-hook-form";

import {
	Autocomplete,
	Avatar,
	Box,
	Button,
	FormControl,
	IconButton,
	InputAdornment,
	TextField,
	Typography,
} from "@mui/material";
// import { ChipInput } from './ChipInput';

import { isPossiblePhoneNumber } from "react-phone-number-input";

import { addUser, CheckUserExist, editUser } from "../../apis/user";
import { ImageCropper } from "../CommonFunctions/ImageCropper";
import { useEffect } from "react";
import { getFullUsers } from "../../apis/Api";

export const EditUserDrawer = ({
	setEditOpen,
	user,
	setUser,
	setRows,
	edit,
}) => {
	const [filename, setFilename] = useState("");
	const [crop, setCrop] = useState(false);
	const [image, setImage] = useState(user.image?.file);
	const types = [
		{ id: 2, label: "Stylist" },
		{ id: 3, label: "User" },
	];
	const [type, setType] = useState(
		user.user_type
			? types.find((type) => type.label.toLowerCase() === user.role)
			: null
	);

	const {
		register,
		handleSubmit,
		setValue,
		clearErrors,
		formState: { errors },
	} = useForm();

	useEffect(() => {
		setValue("user_type", type?.label);
		setValue("name", user.name);
		setValue(
			"phone",
			user.phone.startsWith("+91") ? user.phone.slice(3) : user.phone
		);
	}, []);
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setUser({ ...user, [name]: value });
	};

	const onSubmit = async (data) => {
		console.log(data);
		data.firstname = data.name.split(" ")[0];
		data.lastname = data.name.split(" ").length>1?data.name.split(" ")[1]:" ";
		data.username = data.name;
		data.phone = data.phone.startsWith("+91")
			? data.phone
			: `+91${data.phone}`;
		data.user_type = types.find((type) => type.label === data.user_type).id;
		console.log(data);

		// setLoading(true);
		let formData = new FormData();
		for (let i in data) {
			if (i === "file") {
				console.log(i, data[i]);
				if (data[i].length) {
					let blob = await fetch(image).then((r) => r.blob());
					formData.append("file", blob, filename);
				}
			} else formData.append(i, data[i]);
		}

		let res = edit
			? await editUser(formData, user.slug)
			: await addUser(formData);
		const newUsers = await getFullUsers();
		setRows(newUsers);
		setEditOpen(false);
		console.log("====================================");
		console.log(res);
		console.log("====================================");

		// await addUser({ ...data });
	};

	const handleOnChange = (event) => {
		const newImage = event.target?.files?.[0];
		console.log(newImage);
		if (newImage) {
			// console.log(newImage);
			setFilename(newImage.name);
			setImage(URL.createObjectURL(newImage));
			setCrop(true);
		}
	};

	const checkUserExist = async (v) => {
		return await CheckUserExist(v);
	};

	// const handleSave = async () => {
	// 	let data = {};
	// 	if (user_handle === user["user_handle"]) {
	// 		data = {
	// 			mobile_number: mobile,
	// 			email: mail,
	// 			expertise_tag: tags,
	// 			profile_photo:
	// 				profile[0] && profile[0]["url"]
	// 					? profile[0]["url"]
	// 					: "https://grapedev.objectstore.e2enetworks.net/image/gender_neutral_avatar1633267478615FRU09G.png",
	// 			// "user_type": type,
	// 			user_firstname: user_firstname,
	// 			user_lastname: user_lastname,
	// 			bio: user["bio"] ?? "",
	// 			gender: "",
	// 			enable_notifications: "yes",
	// 			privacy_terms: true,
	// 			age_verification: true,
	// 			dob: "",
	// 			avatar: 4,
	// 		};
	// 	} else {
	// 		data = {
	// 			phone: mobile,
	// 			email: mail,
	// 			expertise_tag: tags,
	// 			profile_photo:
	// 				profile[0] && profile[0]["url"]
	// 					? profile[0]["url"]
	// 					: "https://grapedev.objectstore.e2enetworks.net/image/gender_neutral_avatar1633267478615FRU09G.png",
	// 			user_type: 2,
	// 			username: user_handle,
	// 			name: user_firstname,
	// 			user_lastname: user_lastname,
	// 			bio: user["bio"] ?? "",
	// 			gender: "",
	// 			enable_notifications: "yes",
	// 			privacy_terms: true,
	// 			age_verification: true,
	// 			dob: "",
	// 			avatar: 4,
	// 		};
	// 	}
	// 	if (user["_id"]) {
	// 		await updateUser({ user_id: user["_id"]["$oid"], ...data });
	// 	} else {
	// 		await addUser({ ...data });
	// 	}
	// 	const newUsers = await getFullUsers();
	// 	setRows(newUsers);
	// 	setEditOpen(false);
	// };

	// const checkUserHandleValidate = (userHandle) => {
	// 	setUserHandle(userHandle);
	// };

	// useDebouncedEffect(
	// 	async () => {
	// 		const checkStatus = await checkUserHandle(user_handle);

	// 		setUserHandleStatus(!checkStatus);
	// 	},
	// 	[user_handle],
	// 	1000
	// );

	return (
		<Box sx={{ width: 600, padding: 2 }} component="div">
			<IconButton
				style={{ position: "fixed" }}
				onClick={() => setEditOpen(false)}
			>
				<Close />
			</IconButton>

			<Box
				component={"form"}
				onSubmit={handleSubmit(onSubmit)}
				sx={{ padding: "45px 55px 0px" }}
			>
				<Box display="flex" justifyContent="space-between">
					<Typography variant="h6" gutterBottom>
						{!edit ? "Add" : "Edit"} User
					</Typography>
					<Button type="submit" color="primary" variant="contained">
						{edit ? "Update" : "Save"}
					</Button>
				</Box>

				<Box my={3}>
					<TextField
						{...register("name", {
							required: "Please Enter Name",
							// pattern: {
							// 	value: /^[a-zA-Z]+ [a-zA-Z]+$/,
							// 	message:
							// 		"Please enter first name and last name",
							// },
						})}
						fullWidth
						onChange={handleInputChange}
						helperText={errors.name?.message}
						error={Boolean(errors.name)}
						label="Name"
						variant="outlined"
					/>
				</Box>

				<Box my={3}>
					<TextField
						fullWidth
						label="Phone"
						variant="outlined"
						error={Boolean(errors.phone)}
						helperText={errors.phone?.message}
						disabled={edit}
						onChange={handleInputChange}
						{...register("phone", {
							required: "Enter phone number",
							validate: {
								isPhone: (v) => {
									if (edit) {
										return;
									}
									let phone = v.startsWith("+91")
										? v
										: `+91${v}`;

									return (
										isPossiblePhoneNumber(phone) ||
										"Invalid Phone number!"
									);
								},
								checkExist: async (v) => {
									if (edit) {
										return;
									}
									let phone = v.startsWith("+91")
										? v
										: `+91${v}`;
									return (
										(await checkUserExist({
											phone: phone,
										})) || "User already Exist"
									);
								},
							},
						})}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									+91
								</InputAdornment>
							),
						}}
					/>
				</Box>
				<Box my={3}>
					<Autocomplete
						value={type}
						isOptionEqualToValue={(option, value) =>
							option.id === value.id
						}
						onChange={(_, value) => {
							setType(value);
							setUser({
								...user,
								role: value.label.toLowerCase(),
								user_type: value.id,
							});
							if (errors.user_type) clearErrors("user_type");
						}}
						options={types}
						fullWidth
						renderInput={(params) => (
							<TextField
								error={Boolean(errors.user_type)}
								{...register("user_type", {
									required: "Select User Type",
								})}
								{...params}
								helperText={errors.user_type?.message}
								label="User Type"
								variant="outlined"
							/>
						)}
					/>
				</Box>

				{/* <Box my={3}>
					<TextField
						fullWidth
						label="Email"
						{...register("email", {
							// required: "Enter your email id",
							validate: {
								isEmail: (value) =>
									validator.isEmail(value) ||
									"Not a valid email",
								checkExist: async (v) =>
									(await checkUserExist({ email: v })) ||
									"User already Exist",
							},
						})}
						variant="outlined"
						helperText={errors.email?.message}
						error={Boolean(errors.email)}
						inputProps={{
							autoComplete: "new-password",
						}}
					/>
				</Box> */}
				<Box my={3}>
					<FormControl error={Boolean(errors.file)}>
						{image && (
							<Avatar
								variant="square"
								src={image}
								sx={{
									width: 150,
									height: 150,
									objectFit: "fill",
									marginBottom: 2,
								}}
							/>
						)}
						<Button
							variant="contained"
							component="label"
							onChange={handleOnChange}
						>
							{!image ? "Upload " : "Change "}image
							<input
								hidden
								accept="image/*"
								id="file-upload"
								type="file"
								{...register("file", {
									// required: image == null,
								})}
								error={errors.file}
							/>
						</Button>

						{/* {Boolean(errors.file) && (
								<FormHelperText>Image required</FormHelperText>
							)} */}
					</FormControl>
				</Box>

				{/* <ChipInput label='Expertise' tags={tags} setTags={setTags} /> */}
			</Box>
			<ImageCropper
				image={image}
				setImage={setImage}
				open={crop}
				setOpen={setCrop}
				aspect={1}
			/>
		</Box>
	);
};
