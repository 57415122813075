import { styled } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import {
	Avatar,
	Box,
	Chip,
	CircularProgress,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
} from "@mui/material";
import { Link } from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.action.hover,
		fontWeight: 600,
		userSelect: "none",
		borderBottom: `1px solid ${theme.palette.background.divider}`,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
		userSelect: "none",
		borderBottom: `1px solid ${theme.palette.background.divider}`,
	},
}));

const useStyles = makeStyles()({
	table: {
		minWidth: 1280,
	},
	tableHead: {
		position: "sticky",
		top: 0,
		zIndex: 1,
	},
});

export default function DataTable({
	rows,
	handleEdit,
	handleDelete,
	loading,
	handlePage,
	handleRowsCount,
	count,
	page,
	rowsCount,
}) {
	const { classes } = useStyles();

	return (
		<Box >
			
				<TableContainer component={Paper}>
					<Table
						className={classes.table}
						aria-label="customized table"
					>
						<TableHead className={classes.tableHead}>
							<TableRow>
								{[
									"S No.",
									"Name",
									"Seller Market",
									"logo",
									"Created at",
									"Updated at",
									"Action",
								].map((header) => (
									<StyledTableCell>{header}</StyledTableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.map((row, i) => (
								<TableRow key={i + 1}>
									<StyledTableCell>
										{page * rowsCount + i + 1}
									</StyledTableCell>
									<StyledTableCell
										style={{ minWidth: 120, maxWidth: 150 }}
									>
										{row.website ? (
											<Link
												target="_blank"
												href={row.website}
											>
												{row["name"]}
											</Link>
										) : (
											<>{row.name}</>
										)}
									</StyledTableCell>
									<StyledTableCell
										style={{ minWidth: 120, maxWidth: 150 }}
									>
										{row.seller_market}
									</StyledTableCell>
									<StyledTableCell
										style={{ minWidth: 120, maxWidth: 150 }}
									>
										{row.logo && (
											<Avatar
												alt="Brand Logo"
												src={row.logo?.file}
											/>
										)}
									</StyledTableCell>

									<StyledTableCell
										style={{ minWidth: 100, maxWidth: 100 }}
									>
										{new Date(row["created_at"])
											.toString()
											.substring(4, 24)}
									</StyledTableCell>
									<StyledTableCell
										style={{ minWidth: 100, maxWidth: 100 }}
									>
										{new Date(row["updated_at"])
											.toString()
											.substring(4, 24)}
									</StyledTableCell>

									<StyledTableCell>
										<IconButton
											color="primary"
											onClick={() => handleEdit(row.id)}
										>
											<EditOutlined />
										</IconButton>
										<IconButton
											color="secondary"
											onClick={() =>
												handleDelete(
													page * rowsCount + i
												)
											}
										>
											<DeleteOutline />
										</IconButton>
									</StyledTableCell>

									{/* <StyledTableCell style={{ minWidth: 120, maxWidth: 150 }}>
									{row["logo"]}
								</StyledTableCell>
								
								<StyledTableCell style={{ minWidth: 100, maxWidth: 100 }}>{(new Date(row["created_time"])).toString().substring(4, 24)}</StyledTableCell>
								<StyledTableCell style={{ minWidth: 100, maxWidth: 100 }}>{(new Date(row["last_updated_time"])).toString().substring(4, 24)}</StyledTableCell>
								
								<StyledTableCell>
									{row["images"].slice(0, 2).map(image => (
										<img src={image} alt="" width='50px' style={{ margin: 5, borderRadius: 5 }} />))
									}
								</StyledTableCell> */}
									{/* <StyledTableCell>
									<IconButton color='primary' onClick={() => handleEdit(i)}>
										<EditOutlined />
									</IconButton>
									<IconButton color='secondary' onClick={() => handleDelete(page * rowsCount + i)}>
										<DeleteOutline />
									</IconButton>
								</StyledTableCell> */}
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					sx={{
						"& p": {
							m: 0,
						},
					}}
					// style={{ overflow: "inherit" }}
					rowsPerPageOptions={[10, 25, 100]}
					component="div"
					count={count}
					rowsPerPage={rowsCount}
					page={page}
					onPageChange={handlePage}
					onRowsPerPageChange={(e) => handleRowsCount(e.target.value)}
				/>
				{loading && (
					<Box
						display="flex"
						width="100%"
						justifyContent="center"
						p={3}
					>
						<CircularProgress />
					</Box>
				)}
			
		</Box>
	);
}
