import { LOGIN_FAIL, LOGIN_SUCCESS, LOGIN_USER, SETTOKEN } from "./constants";
import { put, call, takeLatest } from "redux-saga/effects";
import { loginService,setToken } from "./service";

export function* loginSaga(payload) {
	try {
		const response = yield call(loginService, payload);
		yield put({ type: SETTOKEN, response });
		
	} catch (error) {
		
		yield put({ type: LOGIN_FAIL, error });
	}
}

export function* setTokenSaga(payload) {
    let {response} =payload
    setToken(response.data)
    
    yield put({ type: LOGIN_SUCCESS, response });
}

export function* authenticationWatcher() {
	yield takeLatest(LOGIN_USER, loginSaga);
    yield takeLatest(SETTOKEN, setTokenSaga);
}


