import { styled } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import {
	AddCircle,
	ContentCopyRounded,
	DoDisturbOn,
	EditOutlined,
	NotificationsActive,
} from "@mui/icons-material";
import {
	Avatar,
	Box,
	Button,
	Chip,
	CircularProgress,
	IconButton,
	Paper,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
} from "@mui/material";
import { Link } from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { setActiveInactivePlayoff } from "../../apis/Playoff";
import NotificationModal from "./NotificationModal";
import { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import PinToTopPlayoff from "./PinToTopPlayoff";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.action.hover,
		fontWeight: 600,
		userSelect: "none",
		borderBottom: `1px solid ${theme.palette.background.divider}`,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
		userSelect: "none",
		borderBottom: `1px solid ${theme.palette.background.divider}`,
	},
}));

const useStyles = makeStyles()({
	table: {
		minWidth: 1280,
	},
	tableHead: {
		position: "sticky",
		top: 0,
		zIndex: 1,
	},
});
export const notify = (m) =>
	toast.success(m, {
		position: "top-right",
		autoClose: 2500,
		hideProgressBar: false,
		closeOnClick: true,
		// pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});
export default function DataTable({
	rows,
	handleEdit,
	handleDelete,
	loading,
	handlePage,
	handleRowsCount,
	count,
	page,
	rowsCount,
	setRows,
	user,
}) {
	const { classes } = useStyles();
	console.log(page * rowsCount, (page + 1) * rowsCount);
	const [playoff, setPlayoff] = useState(null);
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState("");
	const handleChange = (id) => {
		const newRows = rows.map((row) => {
			if (row.id === id) {
				row.active = !row.active;
				setActiveInactivePlayoff(row);
				return row;
			} else {
				// Return a new circle 50px below
				return row;
			}
		});

		setRows(newRows);
	};

	useEffect(() => {
		if (!open) {
			setMessage("");
			if (rows.length && playoff) {
				let index=rows.findIndex((p) => (p.id = playoff.id))
				if(index>-1){
					let temp=[...rows]
					temp[index].retire=playoff.retire
					setRows(temp)
				}
				
			}
			setPlayoff(null)
		}
	}, [open]);

	return (
		<>
			<TableContainer component={Paper}>
				<ToastContainer />
				<Table className={classes.table} aria-label="customized table">
					<TableHead className={classes.tableHead}>
						<TableRow>
							{[
								"S No.",
								"id",
								"Slug",
								"Name",
								"Description",
								"Collage",
								// "Grouped list?",
								//"Products",
								"Groups",
								"List Categories",
								"prize",
								"Playoff date & time",
								//"Voting date & time",
								"Active/Inactive",
								"For Playoff",
								"Pin To Top",
								"Action",
							].map((header, index) => (
								<StyledTableCell key={`header-${index}`}>
									{header}
								</StyledTableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row, i) => (
							<TableRow key={`row-${i}`}>
								<StyledTableCell>
									{page * rowsCount + i + 1}
								</StyledTableCell>
								<StyledTableCell>{row.id}</StyledTableCell>
								<StyledTableCell>
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
										}}
									>
										<Typography variant="p" component={"p"}>
											{row.slug}
										</Typography>

										<CopyToClipboard
											text={row.slug}
											onCopy={() => notify("copied")}
										>
											<IconButton
												size="small"
												color="primary"
											>
												<ContentCopyRounded />
											</IconButton>
										</CopyToClipboard>
									</Box>
								</StyledTableCell>
								<StyledTableCell
									style={{ minWidth: 120, maxWidth: 150 }}
								>
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
										}}
									>
										<Typography variant="p" component={"p"}>
											{row.title}
										</Typography>

										<CopyToClipboard
											text={row.title}
											onCopy={() => notify("copied")}
										>
											<IconButton
												size="small"
												color="primary"
											>
												<ContentCopyRounded />
											</IconButton>
										</CopyToClipboard>
									</Box>
								</StyledTableCell>

								<StyledTableCell
									style={{
										minWidth: 120,
										maxWidth: 150,
										overflow: "hidden",
										textOverflow: "ellipsis",
										whiteSpace: "nowrap",
									}}
								>
									{row.description}
								</StyledTableCell>

								<StyledTableCell
									style={{ minWidth: 120, maxWidth: 150 }}
								>
									<Avatar
										alt="List Logo"
										variant="square"
										sx={{
											width: 150,
											height: 150,
											objectFit: "fill",
										}}
										src={row.image?.file}
									>
										S
									</Avatar>
								</StyledTableCell>

								{/* <StyledTableCell
									style={{ minWidth: 70, maxWidth: 100 }}
								>
									{row.grouped_list ? "Yes" : "No"}
								</StyledTableCell> */}
								{/* <StyledTableCell
									style={{ minWidth: 120, maxWidth: 150 }}
								>
									{row.products.map((p,index) => (
										<p key={`name-${index}`}>{p.name}</p>
									))}
								</StyledTableCell> */}
								<StyledTableCell
									style={{ minWidth: 120, maxWidth: 150 }}
								>
									{row.groups.map((p, index) => (
										<p key={`group-${index}`}>{p.name}</p>
									))}
								</StyledTableCell>
								<StyledTableCell
									style={{ minWidth: 120, maxWidth: 150 }}
								>
									{row.list_category.map((p, index) => (
										<p key={`cetegory-${index}`}>
											{p.name}
										</p>
									))}
								</StyledTableCell>
								<StyledTableCell
									style={{ minWidth: 120, maxWidth: 150 }}
								>
									<p>{row.prize?.title}</p>
								</StyledTableCell>
								<StyledTableCell
									style={{ minWidth: 100, maxWidth: 100 }}
								>
									{new Date(row.play_off_start_date_time)
										.toString()
										.substring(4, 24)}{" "}
									-{" "}
									{new Date(row.play_off_end_date_time)
										.toString()
										.substring(4, 24)}
								</StyledTableCell>
								{/* <StyledTableCell
									style={{ minWidth: 100, maxWidth: 100 }}
								>
									{new Date(row.voting_start_date_time)
										.toString()
										.substring(4, 24)}{" "}
									-{" "}
									{new Date(row.voting_end_date_time)
										.toString()
										.substring(4, 24)}
								</StyledTableCell> */}
								<StyledTableCell>
									<Switch
										checked={row.active}
										onChange={(e) => {
											e.preventDefault();
										}}
										inputProps={{
											"aria-label": "controlled",
										}}
									/>
								</StyledTableCell>
								<StyledTableCell>
									<Switch
										checked={row.is_playoff}
										onChange={(e) => {
											e.preventDefault();
										}}
										inputProps={{
											"aria-label": "controlled",
										}}
									/>
								</StyledTableCell>
								<StyledTableCell>
									<PinToTopPlayoff
										row={row}
									></PinToTopPlayoff>
								</StyledTableCell>

								<StyledTableCell>
									<IconButton
										color="primary"
										onClick={() => {
											const playoff = {
												id: row.id,
												title: row.title,
												retire: row.retire,
												notify: true,
											};
											setPlayoff(playoff);

											setOpen(true);
											setMessage(
												`Do you want to send notification to Playoff ${row?.title} `
											);
										}}
									>
										<NotificationsActive></NotificationsActive>
									</IconButton>
									<IconButton
										color="primary"
										href={
											user.user_type == 4
												? `/upload-playoff/playoff/${row.id}/edit/`
												: `/w86zjruk6q/playoff/${row.id}/edit/`
										}
									>
										<EditOutlined />
									</IconButton>
									<CopyToClipboard
										text={`https://www.grape.town/playoff/${row.slug}`}
										onCopy={() => notify("copied")}
									>
										<IconButton
											size="small"
											color="primary"
										>
											<ContentCopyRounded />
										</IconButton>
									</CopyToClipboard>

									<IconButton
										size="small"
										color={
											row.retire ? "primary" : "secondary"
										}
										onClick={() => {
											const playoff = {
												id: row.id,
												title: row.title,
												retire: !row.retire,
												notify: false,
											};
											setPlayoff(playoff);
											setOpen(true);
											setMessage(
												`Do you want to engage Playoff ${row?.title}'s curations`
											);
										}}
									>
										{row.retire ? (
											<AddCircle />
										) : (
											<DoDisturbOn />
										)}
									</IconButton>
								</StyledTableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				sx={{
					"& p": {
						m: 0,
					},
				}}
				rowsPerPageOptions={[10, 25, 100]}
				component="div"
				count={count}
				rowsPerPage={rowsCount}
				page={page}
				onPageChange={handlePage}
				onRowsPerPageChange={(e) => handleRowsCount(e.target.value)}
			/>
			<NotificationModal
				playoff={playoff}
				setOpen={setOpen}
				open={open}
				msg={message}
			/>
			{loading && (
				<Box display="flex" width="100%" justifyContent="center" p={3}>
					<CircularProgress />
				</Box>
			)}
		</>
	);
}
