import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import {  handleRetire, send_playoff_notification } from "../../apis/Playoff";

export default function NotificationModal({ open, setOpen, playoff, msg }) {
	
	const send_notification = async () => {
		if (playoff.notify) {
			await send_playoff_notification(playoff.id);
		} else {
			let res=await handleRetire(playoff,playoff.id)
			console.log('====================================');
			console.log(res);
			console.log('====================================');
		}
		setOpen(false);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Confirmation"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{msg}
					</DialogContentText>
				</DialogContent>
				<DialogActions sx={{ paddingX: 3, paddingBottom: 3 }}>
					<Button
						variant="contained"
						color="primary"
						onClick={handleClose}
					>
						No
					</Button>
					<Button
						variant="contained"
						color="secondary"
						onClick={send_notification}
						autoFocus
					>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
