import { styled } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { DeleteOutline, Description, EditOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import { Link } from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { useState } from "react";
import MenuItem from "@mui/material/MenuItem";

import Select from "@mui/material/Select";
import { getPlayoffDetails } from "../../../apis/Playoff";
import { getPlayoffShareGraphDetails } from "../../../apis/Api";
import CustomeCellData from "../CustomeCellData/CustomeCellData";
import CustomeCellDataPlayoff from "../CustomeCellData/CustomeCellDataPlayoff";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    fontWeight: 600,
    userSelect: "none",
    borderBottom: `1px solid ${theme.palette.background.divider}`,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    userSelect: "none",
    borderBottom: `1px solid ${theme.palette.background.divider}`,
  },
}));

const useStyles = makeStyles()({
  table: {
    minWidth: 1280,
  },
  tableHead: {
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
});

function PlayoffViewTable({
  rows,
  loading,
  handlePage,
  handleRowsCount,
  count,
  page,
  rowsCount,
  setRows,
//   type,
  filter,
}) {
  const { classes } = useStyles();
  console.log(page * rowsCount, (page + 1) * rowsCount);

  const getProductDetails = async (id) => {
    let data = await getPlayoffShareGraphDetails(filter, id);
    console.log("=============");
    console.log("playoff_data_data", data);
    console.log("=============");
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead className={classes.tableHead}>
            <TableRow>
              {[
                "S No.",
                "Name",
               
                "Collage",
               
                "Groups",
                "Plyoff Share View Count",
              ].map((header, i) => (
                <StyledTableCell key={`table-head-${i}`}>
                  {header}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, i) => (
              <TableRow key={i + 1}>
                <StyledTableCell>{page * rowsCount + i + 1}</StyledTableCell>
                <StyledTableCell style={{ minWidth: 70, maxWidth: 150 }}>
                  {row.playoff.title}
                </StyledTableCell>
                
                <StyledTableCell
                  style={{ minWidth: 120, maxWidth: 150, textAlign: "center" }}
                >
                  <Avatar
                    alt="List Logo"
                    variant="square"
                    sx={{
                      width: 150,
                      height: 150,
                      objectFit: "fill",
                      marginX: "auto",
                    }}
                    src={row.playoff.image?.file}
                  >
                    S
                  </Avatar>
                </StyledTableCell>
               
                <StyledTableCell style={{ minWidth: 120, maxWidth: 150 , textAlign: "center"}}>
                  {row.playoff.groups.map((p, index) => (
                    <p key={`group-${index}`}>{p.name}</p>
                  ))}
                </StyledTableCell>
                <StyledTableCell
                  style={{ minWidth: 70, maxWidth: 100, textAlign: "center" }}
                >
                  {/* {row.total_share} */}
                  <CustomeCellDataPlayoff row={row} filter={filter}></CustomeCellDataPlayoff>
                </StyledTableCell>
                {/* <StyledTableCell 	style={{ minWidth: 120, maxWidth: 150,    
									   overflow: "hidden",
									   textOverflow: "ellipsis",
									   whiteSpace:"nowrap"}}>
                  {row.description}
                </StyledTableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{
          "& p": {
            m: 0,
          },
        }}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={count}
        rowsPerPage={rowsCount}
        page={page}
        onPageChange={handlePage}
        onRowsPerPageChange={(e) => handleRowsCount(e.target.value)}
      />
      {loading && (
        <Box display="flex" width="100%" justifyContent="center" p={3}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
}

export default PlayoffViewTable;
