import React, { useEffect, useState } from "react";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
// import {
// 	DateTimePicker,
// 	MuiPickersUtilsProvider,
// // } from '@material-ui/pickers';
import { makeStyles } from "tss-react/mui";
import {
  Box,
  Paper,
  Typography,
  ThemeProvider,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  IconButton,
  CardActions,
  CardContent,
} from "@mui/material";
import { Autocomplete } from "@mui/lab";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  BarChart,
  Bar,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  getAnalytics,
  getCommunityFavouritesGraph,
  getGrapeListGraph,
  getProductViewCount,
  getReportsCounts,
  getUserShareGraph,
  getPlayoffShareGraph,
  getCurationShareGraph
} from "../apis/Api";
import clsx from "clsx";
import { addDays } from "date-fns";


import MenuItem from "@mui/material/MenuItem";

import Select from "@mui/material/Select";
// import Link from "@mui/material/Link";
import { Link } from "react-router-dom";
import { set, sumBy } from "lodash";
import moment from "moment-timezone";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link as RouterLink } from "react-router-dom";
import GrapeListGraphCard from "../components/Dashboard/GrapListGraphCard/GrapeListGraphCard";
import UserListGraphCard from "../components/Dashboard/GrapListGraphCard/UserListGraphCard"
import { getAdSpaceId, getAdmitadToken } from "../apis/Admited api/Api";
import PlayoffListGraphCard from "../components/Dashboard/GrapListGraphCard/PlayoffListGraphCard";
import CurationListGraphCard from "../components/Dashboard/GrapListGraphCard/CurationListGraphCard";

const drawerWidth = 360;
const interval = ["Hourly", "Daily", "Weekly", "Monthly", "Yearly"];

const useStyles = makeStyles()((theme) => ({
  paper: {
    boxShadow:
      "rgba(0, 0, 0, 0.06) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
    margin: "20px 0 0 20px",
    padding: 10,
    borderRadius: 10,
  },
  title: {
    marginTop: 10,
    marginLeft: 10,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    zIndex: 0,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(8) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(10) + 1,
    },
  },
}));

export const Dashboard = ({ theme }) => {
  // const [loading, setLoading] = React.useState(false);
  // const [type, setType] = React.useState("Daily");
  // const [start, setStart] = React.useState(addDays(new Date(), -14));
  // const [end, setEnd] = React.useState(new Date());
  // const { classes } = useStyles();
  // const [userReport, setUserReport] = React.useState({});
  // const [contentReport, setContentReport] = React.useState({});
  // const [graphData, setGraphData] = useState({});
  // const [productViewCountValue, setProductViewCountValue] = useState(0);
  // const [selcetedValue, setSelcetedValue] = React.useState(0);
  // const [persitentListFilterValue, setPersitentListFilterValue] = useState(0);
  // const [persitentListFilterValue2, setPersitentListFilterValue2] = useState(0);
  // const [persistentlistGraph, setPersistentlistGraph] = useState({});
  // const [persistentlistGraph2, setPersistentlistGraph2] = useState({});
  // const [sumofViewsPL, setSumofViewsPL] = useState(0);
  // const [sumofViewsPL2, setSumofViewsPL2] = useState(0);
  
   useEffect(() => {
    getAdmitadToken()
		getAdSpaceId()
   }, []);
   
  
  // const [currentDate, setCurrentDate] = useState(
  //   moment().format("YYYY-MM-DD HH:mm:ss")
  // );

  // const handleChange = (event) => {
  //   setSelcetedValue(event.target.value);
  //   if (event.target.value == 1) {
  //     productViewCount("day");
  //   } else if (event.target.value == 2) {
  //     productViewCount("week");
  //   } else if(event.target.value == 3) {
  //     productViewCount("month");
  //   }else{
  //     productViewCount("all time");
  //   }
  // };
  // const handleChangePersistentList = (event) => {
  //   setPersitentListFilterValue(event.target.value);
  //   if (event.target.value == 1) {
  //     productViewCountPrsistentList("day");
  //   } else if (event.target.value == 2) {
  //     productViewCountPrsistentList("week");
  //   } else if(event.target.value == 3) {
  //     productViewCountPrsistentList("month");
  //   }else{
  //     productViewCountPrsistentList("all time");
  //   }
  // };
  // const handleChangePersistentList2 = (event) => {
  //   setPersitentListFilterValue2(event.target.value);
  //   if (event.target.value == 1) {
  //     productViewCountPrsistentList2("day");
  //   } else if (event.target.value == 2) {
  //     productViewCountPrsistentList2("week");
  //   } else if(event.target.value == 3) {
  //     productViewCountPrsistentList2("month");
  //   }else{
  //     productViewCountPrsistentList2("all time");
  //   }
  // };

  // React.useEffect(() => {   
  //   productViewCount("all time");
  //   productViewCountPrsistentList("all time");
  //   productViewCountPrsistentList2('all time')
  // }, []);

  // const productViewCount = async (
  //   filter,
  //   date = moment().format("YYYY-MM-DD HH:mm:ss")
  // ) => {
  //   const data = await getProductViewCount(filter, date);
  //   let sum = data.reduce((a, b) => a + b.count, 0);
  //   setProductViewCountValue(sum);
  //   setGraphData(data);
  // };
  // const productViewCountPrsistentList = async (
  //   filter,
  //   date = moment().format("YYYY-MM-DD HH:mm:ss")
  // ) => {
  //   const data = await getCommunityFavouritesGraph(2294,filter, date);
  //   let sum = data.reduce((a, b) => a + b.count, 0);
  //   setSumofViewsPL(sum);
  //   setPersistentlistGraph(data);
  // };
  // const productViewCountPrsistentList2 = async (
  //   filter,
  //   date = moment().format("YYYY-MM-DD HH:mm:ss")
  // ) => {
  //   const data = await getCommunityFavouritesGraph(2295,filter, date);
  //   let sum = data.reduce((a, b) => a + b.count, 0);
  //   setSumofViewsPL2(sum);
  //   setPersistentlistGraph2(data);
  // };
  // const tickFormatter = (tick) => {
  //   if(selcetedValue == 0 ){
  //     return moment(tick).tz("Asia/Kolkata").format("MMM");
  //   }
  //   else if (selcetedValue == 1) {
  //     return moment(tick).tz("Asia/Kolkata").format("hh:mm a");
  //   } else if (selcetedValue == 2) {
  //     return moment(tick).format("ddd");
  //   } else {
  //     return 'Week '+Math.ceil(moment(tick).date() / 7);
  //   }
  // };
  // const tickFormatterPL = (tick) => {
  //   if(persitentListFilterValue == 0 ){
  //     return moment(tick).tz("Asia/Kolkata").format("MMM");
  //   }
  //   else if (persitentListFilterValue == 1) {
  //     return moment(tick).tz("Asia/Kolkata").format("hh:mm a");
  //   } else if (persitentListFilterValue == 2) {
  //     return moment(tick).format("ddd");
  //   } else {
  //     return 'Week '+Math.ceil(moment(tick).date() / 7);
  //   }
  // };
  // const tickFormatterPL2 = (tick) => {
  //   if(persitentListFilterValue2 == 0 ){
  //     return moment(tick).tz("Asia/Kolkata").format("MMM");
  //   }
  //   else if (persitentListFilterValue2 == 1) {
  //     return moment(tick).tz("Asia/Kolkata").format("hh:mm a");
  //   } else if (persitentListFilterValue2 == 2) {
  //     return moment(tick).format("ddd");
  //   } else {
  //     return 'Week '+Math.ceil(moment(tick).date() / 7);
  //   }
  // };
  // const formatTooltipLabel = (value) => {
  //   return moment(value).tz("Asia/Kolkata").format("DD-MM-YYYY");
  // };
  // const getPervious =() =>{
  //   if (selcetedValue == 0) {
  //     setCurrentDate(moment(currentDate).subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss'))
  //     productViewCount("day",moment(currentDate).subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss'));
  //   }
  //   else if(selcetedValue == 1){
  //     setCurrentDate(moment(currentDate).subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss'))
  //     productViewCount("day",moment(currentDate).subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss'));
  //   }
  // }
  // const getNext = () =>{
  //   const today = moment()
  //   if (selcetedValue == 0) {
  //     if(moment(currentDate).add(1, 'days').isSameOrBefore(today, 'day')){
  //       setCurrentDate(moment(currentDate).add(1, 'days').format('YYYY-MM-DD HH:mm:ss'))
  //       productViewCount("day",moment(currentDate).add(1, 'days').format('YYYY-MM-DD HH:mm:ss'));
  //     }
  //   }
  //   else if(selcetedValue == 1){
  //     if(moment(currentDate).add(7, 'days').isSameOrBefore(today, 'day')){
  //       setCurrentDate(moment(currentDate).add(7, 'days').format('YYYY-MM-DD HH:mm:ss'))
  //       productViewCount("day",moment(currentDate).add(1, 'days').format('YYYY-MM-DD HH:mm:ss'));
  //     }
  //   }
  // }

  return (
    <>
      <Box>
        <Grid container spacing={4}>
          {/* <Grid item xs={6}>
            <Card
              sx={{               
                margin: 2,
                padding: 2.5,
                "&:hover": { backgroundColor: "none" },
              }}
            >
              <CardContent>
                <Box display="flex" justifyContent="space-evenly">
                  <Typography
                    gutterBottom
                    variant="h4"
                    component="div"
                    sx={{ width: "55%" }}
                  >
                    Product View Count
                  </Typography>

                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={selcetedValue}
                    onChange={handleChange}
                    sx={{
                      border: "none",
                      minWidth: "125px",
                      padding: 0,
                      height: "40px",
                      borderRadius: "20px",
                      ".MuiSelect-select": {
                        paddingRight: "20px !important",
                      },
                      ".MuiSelect-outlined": {
                        border: "none",
                        outline: "none",
                      },
                      "&:focus": {
                        backgroundColor: "red",
                      },
                    }}
                    variant="outlined"
                  >
                    <MenuItem value="0">All Time</MenuItem>
                    <MenuItem value="1">Day</MenuItem>
                    <MenuItem value="2">Week</MenuItem>
                    <MenuItem value="3">Month</MenuItem>
                  </Select>
                </Box>

                <Typography
                  variant="h1"
                  sx={{
                    margin: 2,
                    textAlign: "center",
                  }}
                >
                  {productViewCountValue}
                </Typography>
                <ResponsiveContainer height={300}>
                  <LineChart
                    data={graphData}
                    height="100%"
                    width="100%"
                    margin={{
                      top: 5,
                      right: 0,
                      left: -25,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={"dateTime"} tickFormatter={tickFormatter} />
                    <YAxis dataKey="count" />
                    <Tooltip labelFormatter={formatTooltipLabel} />
                    <Legend />
                    <Line dataKey="count" />
                  </LineChart>
            
                </ResponsiveContainer>
              </CardContent>
              <CardActions>
                <Box sx={{ marginTop: 3, marginX: "auto" }}>
                  <Link
                    to="/w86zjruk6q/product-view-details"
                    state={{ value: selcetedValue,type:"playoff",id:''}}
                    underline="hover"
                  >
                    View details
                  </Link>
                </Box>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card
              sx={{               
                margin: 2,
                padding: 2.5,
                "&:hover": { backgroundColor: "none" },
              }}
            >
              <CardContent>
                <Box display="flex" justifyContent="space-evenly">
                  <Typography
                    gutterBottom
                    variant="h4"
                    component="div"
                    sx={{ width: "55%" }}
                  >
                    Persistent List product View Count (Most Popular)
                  </Typography>

                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={persitentListFilterValue}
                    onChange={handleChangePersistentList}
                    sx={{
                      border: "none",
                      minWidth: "125px",
                      padding: 0,
                      height: "40px",
                      borderRadius: "20px",
                      ".MuiSelect-select": {
                        paddingRight: "20px !important",
                      },
                      ".MuiSelect-outlined": {
                        border: "none",
                        outline: "none",
                      },
                      "&:focus": {
                        backgroundColor: "red",
                      },
                    }}
                    variant="outlined"
                  >
                    <MenuItem value="0">All Time</MenuItem>
                    <MenuItem value="1">Day</MenuItem>
                    <MenuItem value="2">Week</MenuItem>
                    <MenuItem value="3">Month</MenuItem>
                  </Select>
                </Box>

                <Typography
                  variant="h1"
                  sx={{
                    margin: 2,
                    textAlign: "center",
                  }}
                >
                  {sumofViewsPL}
                </Typography>
                <ResponsiveContainer height={300}>
                  <LineChart
                    data={persistentlistGraph}
                    height="100%"
                    width="100%"
                    margin={{
                      top: 5,
                      right: 0,
                      left: -25,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={"dateTime"} tickFormatter={tickFormatterPL} />
                    <YAxis dataKey="count" />
                    <Tooltip labelFormatter={formatTooltipLabel} />
                    <Legend />
                    <Line dataKey="count" />
                  </LineChart>
                 
                </ResponsiveContainer>
              </CardContent>
              <CardActions>
                <Box sx={{ marginTop: 3, marginX: "auto" }}>
                  <Link
                    to="/w86zjruk6q/product-view-details"
                    state={{ value: persitentListFilterValue, type:"persistent_list", id:2294 }}
                    underline="hover"
                  >
                    View details
                  </Link>
                </Box>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card
              sx={{               
                margin: 2,
                padding: 2.5,
                "&:hover": { backgroundColor: "none" },
              }}
            >
              <CardContent>
                <Box display="flex" justifyContent="space-evenly">
                  <Typography
                    gutterBottom
                    variant="h4"
                    component="div"
                    sx={{ width: "55%" }}
                  >
                    Persistent List product View Count (Curated Deals)
                  </Typography>

                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={persitentListFilterValue2}
                    onChange={handleChangePersistentList2}
                    sx={{
                      border: "none",
                      minWidth: "125px",
                      padding: 0,
                      height: "40px",
                      borderRadius: "20px",
                      ".MuiSelect-select": {
                        paddingRight: "20px !important",
                      },
                      ".MuiSelect-outlined": {
                        border: "none",
                        outline: "none",
                      },
                      "&:focus": {
                        backgroundColor: "red",
                      },
                    }}
                    variant="outlined"
                  >
                    <MenuItem value="0">All Time</MenuItem>
                    <MenuItem value="1">Day</MenuItem>
                    <MenuItem value="2">Week</MenuItem>
                    <MenuItem value="3">Month</MenuItem>
                  </Select>
                </Box>

                <Typography
                  variant="h1"
                  sx={{
                    margin: 2,
                    textAlign: "center",
                  }}
                >
                  {sumofViewsPL2}
                </Typography>
                <ResponsiveContainer height={300}>
                  <LineChart
                    data={persistentlistGraph2}
                    height="100%"
                    width="100%"
                    margin={{
                      top: 5,
                      right: 0,
                      left: -25,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={"dateTime"} tickFormatter={tickFormatterPL2} />
                    <YAxis dataKey="count" />
                    <Tooltip labelFormatter={formatTooltipLabel} />
                    <Legend />
                    <Line dataKey="count" />
                  </LineChart>
               
                </ResponsiveContainer>
              </CardContent>
              <CardActions>
                <Box sx={{ marginTop: 3, marginX: "auto" }}>
                  <Link
                    to="/w86zjruk6q/product-view-details"
                    state={{ value: persitentListFilterValue2, type:"persistent_list" , id:2295}}
                    underline="hover"
                  >
                    View details
                  </Link>
                </Box>
              </CardActions>
            </Card>
          </Grid> */}
          <Grid item xs={6}>
            <GrapeListGraphCard title="Product View Count " fetchDataFunc={getProductViewCount} type="playoff" id=""></GrapeListGraphCard>
          </Grid>
          <Grid item xs={6}>
            <GrapeListGraphCard title="Product View Count (Most Popular)" fetchDataFunc={getCommunityFavouritesGraph} id={2294} type="persistent_list"></GrapeListGraphCard>
          </Grid>
          <Grid item xs={6}>
            <GrapeListGraphCard title="Product View Count (Curated Deals)" fetchDataFunc={getCommunityFavouritesGraph} id={2295} type="persistent_list"></GrapeListGraphCard>
          </Grid>
          <Grid item xs={6}>
            <GrapeListGraphCard title="Product View Count (GrapeList)" fetchDataFunc={getGrapeListGraph} type="grape_list" id=""></GrapeListGraphCard>
          </Grid>

          <Grid item xs={6}>
            <UserListGraphCard title="User Share Count " fetchDataFunc={getUserShareGraph} type="grape_list" id=""></UserListGraphCard>
          </Grid>
          <Grid item xs={6}>
            <PlayoffListGraphCard title="Playoff Share Count " fetchDataFunc={getPlayoffShareGraph} type="grape_list" id=""></PlayoffListGraphCard>
          </Grid>
          <Grid item xs={6}>
            < CurationListGraphCard title="Curation Share Count " fetchDataFunc={getCurationShareGraph} type="grape_list" id=""></ CurationListGraphCard>
          </Grid>
         
        </Grid>

        {/* <Paper className={classes.paper}>
          <Typography className={classes.title} variant="h6" noWrap>
            Reporting
          </Typography>
          <Box style={{ width: "400px", height: "260px" }}>
            <Grid container spacing={2}>
              <Grid container spacing={2} style={{ marginTop: "16px" }}>
                <Grid item xs={6}>
                  <Typography
                    className={classes.title}
                    variant="body1"
                    noWrap
                  ></Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.title} variant="body1" noWrap>
                    User
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.title} variant="body1" noWrap>
                    Content
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography className={classes.title} variant="body1" noWrap>
                    Open
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.title} variant="body1" noWrap>
                    {userReport && userReport["open"] && userReport["open"]}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.title} variant="body1" noWrap>
                    {contentReport &&
                      contentReport["open"] &&
                      contentReport["open"]}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography className={classes.title} variant="body1" noWrap>
                    Approved
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.title} variant="body1" noWrap>
                    {userReport &&
                      userReport["approve"] &&
                      userReport["approve"]}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.title} variant="body1" noWrap>
                    {contentReport &&
                      contentReport["approve"] &&
                      contentReport["approve"]}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography className={classes.title} variant="body1" noWrap>
                    Rejected
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.title} variant="body1" noWrap>
                    {userReport && userReport["reject"] && userReport["reject"]}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.title} variant="body1" noWrap>
                    {contentReport &&
                      contentReport["reject"] &&
                      contentReport["reject"]}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>

        <Paper className={classes.paper}>
          <Typography className={classes.title} variant="h6" noWrap>
            Wardrobe Products
          </Typography>
          <LineChart
            width={400}
            height={260}
            margin={{ top: 40, right: 50, bottom: 0, left: 0 }}
            data={data["wardrobe_product_count"]?.x?.map((e, i) => ({
              x: e.substring(4, 11),
              Product: data["wardrobe_product_count"].Y[i],
            }))}
          >
            <Line
              strokeWidth={3}
              type="monotone"
              dataKey="Product"
              stroke="#5c6bc0"
            />
            <CartesianGrid stroke="#ddd" strokeDasharray="5 5" />
            <XAxis dataKey="x" stroke="rgb(102, 102, 102)" />
            <YAxis />
            <Tooltip />
          </LineChart>
        </Paper>

        <Paper className={classes.paper}>
          <Typography className={classes.title} variant="h6" noWrap>
            Wardrobe Contents
          </Typography>
          <LineChart
            width={400}
            height={260}
            margin={{ top: 40, right: 50, bottom: 0, left: 0 }}
            data={data["wardrobe_content_count"]?.x?.map((e, i) => ({
              x: e.substring(4, 11),
              Content: data["wardrobe_content_count"].Y[i],
            }))}
          >
            <Line
              strokeWidth={3}
              type="monotone"
              dataKey="Content"
              stroke="#66bb6a"
            />
            <CartesianGrid stroke="#ddd" strokeDasharray="5 5" />
            <XAxis dataKey="x" stroke="rgb(102, 102, 102)" />
            <YAxis />
            <Tooltip />
          </LineChart>
        </Paper>

        <Paper className={classes.paper}>
          <Typography className={classes.title} variant="h6" noWrap>
            Total Impressions
          </Typography>
          <BarChart
            width={400}
            height={260}
            margin={{ top: 40, right: 50, bottom: 0, left: 0 }}
            data={data["impression_count"]?.x?.map((e, i) => ({
              x: e.substring(4, 11),
              Impressions: data["impression_count"].Y[i],
            }))}
          >
            <XAxis dataKey="x" stroke="rgb(102, 102, 102)" />
            <YAxis />
            <Tooltip />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <Bar dataKey="Impressions" fill="#f46e42" barSize={30} />
          </BarChart>
        </Paper>

        <Paper className={classes.paper}>
          <Typography className={classes.title} variant="h6" noWrap>
            Products Added
          </Typography>
          <BarChart
            width={400}
            height={260}
            margin={{ top: 40, right: 50, bottom: 0, left: 0 }}
            data={data["product_count"]?.x?.map((e, i) => ({
              x: e.substring(4, 11),
              "Products added": data["product_count"].Y[i],
            }))}
          >
            <XAxis dataKey="x" stroke="rgb(102, 102, 102)" />
            <YAxis />
            <Tooltip />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <Bar dataKey="Products added" fill="#ed507a" barSize={30} />
          </BarChart>
        </Paper>

        <Paper className={classes.paper}>
          <Typography className={classes.title} variant="h6" noWrap>
            Content Added
          </Typography>
          <BarChart
            width={400}
            height={260}
            margin={{ top: 40, right: 50, bottom: 0, left: 0 }}
            data={data["content_count"]?.x?.map((e, i) => ({
              x: e.substring(4, 11),
              "Content added": data["content_count"].Y[i],
            }))}
          >
            <XAxis dataKey="x" stroke="rgb(102, 102, 102)" />
            <YAxis />
            <Tooltip />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <Bar dataKey="Content added" fill="#42a5f5" barSize={30} />
          </BarChart>
        </Paper>

        <Paper className={classes.paper}>
          <Typography className={classes.title} variant="h6" noWrap>
            Users Joined
          </Typography>
          <BarChart
            width={400}
            height={260}
            margin={{ top: 40, right: 50, bottom: 0, left: 0 }}
            data={data["user_count"]?.x?.map((e, i) => ({
              x: e.substring(4, 11),
              "New users": data["user_count"].Y[i],
            }))}
          >
            <XAxis dataKey="x" stroke="rgb(102, 102, 102)" />
            <YAxis />
            <Tooltip />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <Bar dataKey="New users" fill="#ef5350" barSize={30} />
          </BarChart>
        </Paper> */}
      </Box>
      <ThemeProvider theme={theme}>
        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
					<Drawer
						anchor='right'
						variant="permanent"
						className={clsx(classes.drawer, classes.drawerOpen)}
						classes={{
							paper: classes.drawerOpen,
						}}
					>
						<Box m={6} />
						<Box m={3}>
							<DateTimePicker
								variant="inline"
								label="Start time"
								value={start}
								onChange={setStart}
							/>
						</Box>
						<Box m={3}>
							<DateTimePicker
								variant="inline"
								label="End time"
								value={end}
								onChange={setEnd}
							/>
						</Box>
						<Box m={3}>
							<Autocomplete
								value={type}
								onChange={(_, v) => {
									setType(v);
								}}
								options={interval}
								style={{ width: '100%' }}
								renderInput={(params) => <TextField {...params} label="Interval" variant="outlined" />}
							/>
						</Box>
						<Box m={3} display='flex'>
							<Button onClick={getData} variant='contained' color='primary' style={{marginLeft:'auto'}}>{loading ? <CircularProgress size={25} /> : 'Go'}</Button>
						</Box>
					</Drawer>
				</MuiPickersUtilsProvider> */}
      </ThemeProvider>
    </>
  );
};
