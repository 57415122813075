import "./App.css";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { pink } from "@mui/material/colors";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import { Authlayout } from "./layout/Authlayout";
import Login from "./pages/authentication/Login";
import { Register } from "./pages/authentication/Register";
import { PrivateLayout } from "./layout/PrivateLayout";
import { Seller } from "./pages/Seller/Seller";
import { Brand } from "./pages/Brand/Brand";
import { Category } from "./pages/ProductCategory/ProductCategory";

import { Tags } from "./pages/Tags/Tags";
import { Products } from "./pages/Product/Products";
import { AddGrapeList } from "./pages/GrapeList/Add";
import { GrapeList } from "./pages/GrapeList/GrapeList";
import { Playoff } from "./pages/Playoff/Playoff";
import { AddPlayoff } from "./pages/Playoff/Add";
import { Users } from "./pages/User/User";
import { Dashboard } from "./pages/Dashboard";
import { Test } from "./pages/test";
import { EditPlayoff } from "./pages/Playoff/Edit";
import Curations from "./pages/Curation/Curations";
import { AddCuration } from "./pages/Curation/AddCuration";
import { EditCuration } from "./pages/Curation/EditCuration";
import { EditGrapeList } from "./pages/GrapeList/Edit";
import { PersistentList } from "./pages/PersistentList/PersistentList";
import { AddPersistentList } from "./pages/PersistentList/Add";
import { EditPersistentList } from "./pages/PersistentList/Edit";
import { UserDetail } from "./pages/User/Details";
import "semantic-ui-css/semantic.min.css";
import { DarkTheme } from "./themes/darkTheme";
import { PlayoffDetails } from "./pages/Playoff/PlayoffDetails";

import ProductViewTableDetails from "./pages/GraphDetailsScreen/ProductViewTableDetails";
import { PrivateLayoutForRestrictedUser } from "./layout/PrivateLayoutForRestrictedUser";
import UserViewTableDetails from "./pages/GraphDetailsScreen/UserViewTableDetails";
import PlayoffViewTableDetails from "./pages/GraphDetailsScreen/PlayoffViewTableDetails";
import CurationViewTableDetails from "./pages/GraphDetailsScreen/CurationViewTableDetails";
import { Waitlist } from "./pages/Waitlist/Waitlist";

function App() {
  // const theme = createTheme({
  // 	type: 'light',
  // 	typography: {
  // 		fontFamily: "'Poppins', sans-serif",
  // 	},
  // 	palette: {
  // 		primary: {
  // 			main: "#003B6D",
  // 			contrastText: "#fff",
  // 		},
  // 		secondary: pink,
  // 	},

  // });

  return (
    <ThemeProvider theme={DarkTheme}>
      <CssBaseline enableColorScheme />
      <BrowserRouter>
        <Routes>
          <Route path="/test" element={<Test />} />
          <Route path="/auth" element={<Authlayout />}>
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route index element={<Navigate to="/auth/login" replace />} />
            {/* <Route
							path="*"
							element={<Navigate to="/auth/login" replace />}
						/> */}

            {/* <Redirect from="*" to="/auth/login" /> */}
            {/* <Route path="new" element={<NewBook />} /> */}
          </Route>
          <Route path="/w86zjruk6q" element={<PrivateLayout />}>
            <Route index element={<Dashboard></Dashboard>}></Route>
            <Route path="seller" element={<Seller />} />
			<Route path="waitlist" element={<Waitlist/>} />
            <Route path="brand" element={<Brand />} />
            <Route path="product-category" element={<Category />} />
            <Route path="listcategories" element={<Tags />} />
            <Route path="product" element={<Products />}></Route>
            <Route path="grapelist" element={<GrapeList />} />
            <Route
              path="/w86zjruk6q/grapelist/add"
              element={<AddGrapeList />}
            />
            <Route
              path="/w86zjruk6q/grapelist/:id/edit"
              element={<EditGrapeList />}
            />
            <Route path="persistent-list" element={<PersistentList />} />
            <Route
              path="/w86zjruk6q/persistent-list/add"
              element={<AddPersistentList />}
            />
            <Route
              path="/w86zjruk6q/persistent-list/:id/edit"
              element={<EditPersistentList />}
            />
            <Route path="/w86zjruk6q/playoff" element={<Playoff />} />
            <Route path="/w86zjruk6q/details" element={<PlayoffDetails />} />
            <Route path="/w86zjruk6q/playoff/add" element={<AddPlayoff />} />
            <Route
              path="/w86zjruk6q/playoff/:id/edit"
              element={<EditPlayoff />}
            />
            <Route path="/w86zjruk6q/user" element={<Users />} />
            <Route path="/w86zjruk6q/user/:slug" element={<UserDetail />} />
            <Route path="/w86zjruk6q/curation" element={<Curations />} />
            <Route path="/w86zjruk6q/curation/add" element={<AddCuration />} />
            <Route
              path="/w86zjruk6q/curation/:id/edit"
              element={<EditCuration />}
            />
            <Route
              path="/w86zjruk6q/product-view-details"
              element={<ProductViewTableDetails />}
            />
            <Route
              path="/w86zjruk6q/user-graph-share-details"
              element={<UserViewTableDetails />}
            />
            <Route
              path="/w86zjruk6q/playoff-share-graph-details"
              element={<PlayoffViewTableDetails />}
            />
            <Route
              path="/w86zjruk6q/curation-share-details"
              element={<CurationViewTableDetails />}
            />
          </Route>

          <Route
            path="/upload-playoff"
            element={<PrivateLayoutForRestrictedUser />}
          >
            <Route index element={<Playoff />}></Route>
            <Route
              path="/upload-playoff/product"
              element={<Products />}
            ></Route>
            <Route path="/upload-playoff/playoff" element={<Playoff />} />
            <Route
              path="/upload-playoff/details"
              element={<PlayoffDetails />}
            />
            <Route
              path="/upload-playoff/playoff/add"
              element={<AddPlayoff />}
            />
            <Route
              path="/upload-playoff/playoff/:id/edit"
              element={<EditPlayoff />}
            />
			
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
