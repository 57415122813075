import { styled } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Link } from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    fontWeight: 600,

    userSelect: "none",
    borderBottom: `1px solid ${theme.palette.background.divider}`,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    userSelect: "none",
    textAlign:"center",
    borderBottom: `1px solid ${theme.palette.background.divider}`,
  },
}));

const useStyles = makeStyles()({
  table: {
    minWidth: 1280,
  },
  tableHead: {
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
});

export default function WaitlistTable({
  rows,
  loading,
  handlePage,
  handleRowsCount,
  count,
  page,
  rowsCount,
  handleEdit,
}) {
  const { classes } = useStyles();

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead className={classes.tableHead}>
            <TableRow>
              {[ "S No.", "Uid","Email Id","Name", "Passcode", "Status", "Action"].map(
                (header, i) => (
                  <StyledTableCell key={`header-${i}`}>
                    {header}
                  </StyledTableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, i) => (
                            <TableRow key={i + 1}>
                                <StyledTableCell >
                                    {page * rowsCount + i + 1}
                                </StyledTableCell>
                                {/* <StyledTableCell
                                    style={{ minWidth: 50, maxWidth: 100 }}
                                >
                                   {row.user}
                                </StyledTableCell> */}
                                <StyledTableCell
                                    style={{ minWidth: 100, maxWidth: 150 , textAlign:"center"}}
                                >
                                   {row.uid}
                                </StyledTableCell>
                                <StyledTableCell
                                    style={{ minWidth: 100, maxWidth: 150 }}
                                >
                                   {row.email}
                                </StyledTableCell>
                                <StyledTableCell
                                    style={{ minWidth: 100, maxWidth: 150 }}
                                >
                                   {row.user?.name}
                                </StyledTableCell>
                                <StyledTableCell
                                    style={{ minWidth: 60, maxWidth: 100 }}
                                >
                                    {row.passcode}
                                </StyledTableCell>
                                <StyledTableCell
                                    style={{ minWidth: 60, maxWidth: 100 }}
                                >
                                    {row.status}
                                </StyledTableCell>
                                <StyledTableCell>
                                    <IconButton
                                        color="primary"
                                        onClick={() => handleEdit(row.id)}
                                    >
                                        <EditOutlined />
                                    </IconButton>
                                    
                                </StyledTableCell>

                               
                            </TableRow>
                        ))}
          
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
               sx={{
                "& p": {
                    m: 0,
                },
            }}
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={count}
                rowsPerPage={rowsCount}
                page={page}
                onPageChange={handlePage}
                onRowsPerPageChange={(e) => handleRowsCount(e.target.value)}
            />
      {loading && (
                <Box display="flex" width="100%" justifyContent="center" p={3}>
                    <CircularProgress />
                </Box>
            )}
    </>
  );
}
