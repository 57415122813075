import { LOGIN_FAIL, LOGIN_SUCCESS, LOGIN_USER, REGISTER_START, SETTOKEN } from "./constants";

const INITIAL_STATE = {
	isLoading: false,user:null,token:'',error:{}
};

const AuthenticationReducer=(
	state = INITIAL_STATE,
	action
) =>{
	const { type, playload, response,error } = action
    console.log(action);
	switch (type) {
		case REGISTER_START:
			break;
        case SETTOKEN:
            return {...state,response}
		case LOGIN_USER:
			return {...state, isLoading:true}
		case LOGIN_SUCCESS:
			// let {token,user}= state
			let token=response.data.access
			let user=response.data.user
			return { ...state, isLoading:false,user,token };
		case LOGIN_FAIL:
			return { ...state,isLoading:false,error:error.response.response };
		default:
			return state;
	}
}

export default AuthenticationReducer