import { useEffect, useState } from "react";

import { makeStyles } from "tss-react/mui";
import { Add } from "@mui/icons-material";


import { Box, Button, Drawer, Toolbar, Typography } from "@mui/material";
import Table from "../../components/PersistentList/GrapeListTable";


import { ToastContainer, toast } from "react-toastify";

import _ from "lodash";
import { EditListDrawer } from "../../components/GrapeList/EditListDrawer";

import { useNavigate } from "react-router-dom";
import { getPersistentList } from "../../apis/PersistentList";

const useStyles = makeStyles()((theme) => ({
	title: {
		flexGrow: 1,
		display: "none",
		[theme.breakpoints.up("sm")]: {
			display: "block",
		},
	},
}));

export const PersistentList = ({ searchText }) => {
	const { classes } = useStyles();
	const navigate = useNavigate();
	const [editOpen, setEditOpen] = useState(false);
	const [title, setTitle] = useState("Add");
	const [rows, setRows] = useState([]);
	const [seller, setSeller] = useState({});
	
	
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsCount, setRowsCount] = useState(10);
	const [count, setCount] = useState(0);

	useEffect(() => {
		setLoading(true);
		_.debounce(async () => {
			const data = await getPersistentList(rowsCount);
			setRows(data.results);
			setCount(data.count);
		}, 500)();
		setLoading(false);
	}, []);

	const notify = () =>
		toast.error(`Some unexpected error occured`, {
			position: "bottom-right",
			autoClose: 2500,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});

	

	

	const handleDeleteProduct = async (i) => {
		
	};

	const handlePage = async (_, i) => {
		const data = await getPersistentList(rowsCount, i + 1);
		setRows(data.results);
		setPage(i);
	};

	const handleRowsCount = async (i) => {
		setRowsCount(i);
		setLoading(true);
		const data = await getPersistentList(i);
		setRows(data.results);
		setLoading(false);
	};
	
	return (
		<>
			<Toolbar>
				<Typography className={classes.title} variant="h4" noWrap />
				{/* <Button
					onClick={refreshHandler}
					startIcon={<LoopSharpIcon />}
					variant="contained"
					color="primary"
					sx={{
						marginRight:2
					}}
				>
					Refresh Persistent Lists 
				</Button> */}
				<Button
					onClick={() => {
						navigate("/w86zjruk6q/persistent-list/add");
					}}
					startIcon={<Add />}
					variant="contained"
					color="primary"
				>
					Add List
				</Button>
			</Toolbar>
			<ToastContainer />
			<Box sx={{ px: 5 }}>
				<Table
					handleDelete={handleDeleteProduct}
					page={page}
					rowsCount={rowsCount}
					handlePage={handlePage}
					handleRowsCount={handleRowsCount}
					count={count}
					rows={rows}
					loading={loading}
					setRows={setRows}
				/>
				<Drawer
					anchor="right"
					open={editOpen}
					onClose={() => setEditOpen(false)}
				>
					<EditListDrawer
						title={title}
						setEditOpen={setEditOpen}
						seller={seller}
						setRows={setRows}
					/>
				</Drawer>
			</Box>
		</>
	);
};
