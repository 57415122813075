import React, { Component, useEffect, useState } from "react";
import { render } from "react-dom";
// import Hello from './Hello';
import "./styles.css";

import { Accordion } from "semantic-ui-react";
import { getMenuCategory } from "../../apis/ProductCategory";
import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Stack,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";

export const ProductCategoryAccordion = ({ categories, open,setOpen,setCategory }) => {
	
	const handleClose = () => {
		setOpen(false);
	};

	const SubAccordionContent = ({ category, sub_menu }) => {
		
		const handleChecked = (id) => {
			handleClose()
			
			console.log('====================================');
			console.log(category);
			console.log('====================================');
			setCategory(category)
			
		};

		return (
			<div className="indent">
				<FormControl fullWidth>
					<Stack
						direction={"row"}
						justifyContent="space-between"
						// sx={{ border: "1px solid rgb(34 36 38 / 15%)" }}
						marginBottom={1}
						padding={1}
						component={"div"}
					>
						<Typography p={1} variant="h6">
							{category.name}
						</Typography>
						<Button onClick={()=>{handleChecked(category.id)}} variant="text">Select</Button>
					</Stack>

					{/* <FormControlLabel
					sx={{ paddingX: 2 }}
					control={<Checkbox value={category.id} checked={checked}  onChange={handleChecked}/>}
					label={category.name}
				/> */}
				</FormControl>
				{sub_menu.length > 0 ? (
					<Accordion.Accordion
						exclusive={false}
						style={{ marginLeft: "20px" }}
						className="no-padding"
						panels={sub_menu}
						// onTitleClick={(e,data)=>{console.log(data);}}
						// children={<h1>Hello</h1>}
					/>
				) : (
					<></>
				)}
			</div>
		);
	};

	const setCategoryList = (category) => {
		// const [categoryid, setCategoryId] = useState("");
		let sub_menu = category.sub_category.map((sub) => {
			let co = setCategoryList(sub);
			// console.log(co);
			return co;
		});

		const AccordionPanels = {
			title: category.name,
			key: "testing-key-" + category.id,
			content: {
				content: (
					<SubAccordionContent
						category={category}
						sub_menu={sub_menu}
					/>
				),
				key: "sub-" + category.id + "accordions",
			},
		};
		return AccordionPanels;
	};

	let accord = categories.map((m) => setCategoryList(m));

	return (
		<>
			<Dialog
				fullWidth={true}
				maxWidth={"md"}
				open={open}
				onClose={handleClose}
			>
				<DialogTitle>Product Categories</DialogTitle>
				<DialogContent>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							m: "auto",
							width: "fit-content",
						}}
					>
						<Accordion panels={accord} styled />
					</Box>
					
				</DialogContent>
				<DialogActions>
				<Button onClick={handleClose} variant="contained">Close</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
