import { axiosInstance } from "../config/axios";

const endpoint ="group/";

export const Create = async (params) => {
	try {
		const res = await axiosInstance.post(endpoint, params);
		console.log("====================================");
		console.log(res);
		console.log("====================================");
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};

export const getAllSeller = async () => {
	try {
		const res = await axiosInstance.get(endpoint);

		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};

export const getStylistUsers = async () => {
	try {
		
		const res = await axiosInstance.get("stylist/");

		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};

export const UpdateGroup = async (params,id) => {
	try {
		const res = await axiosInstance.put(`${endpoint}${id}/`, params);
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};
