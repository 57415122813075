import { Favorite, FavoriteBorder, Star } from "@mui/icons-material";
import {
	Avatar,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Divider,
	IconButton,
	Rating,
	Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import dayjs from "dayjs";

export const RatingCard = ({ rating }) => {
	return (
		<Card sx={{ m: 5 }}>
			<CardHeader
				avatar={
					<Avatar
						src={rating.rate_by.image?.file}
						alt={rating.rate_by.name}
					/>
				}
				action={<IconButton aria-label="settings"></IconButton>}
				title={rating.rate_by.name}
				subheader={dayjs(rating.created_at).format("MMMM D, YYYY")}
			/>

			<CardContent>
				<Typography variant="body2">{rating.feedback}</Typography>
				<Stack
					direction="row"
					justifyContent="flex-end"
					alignItems="center"
					spacing={1}
				>
					<Rating
						sx={{
							"& .MuiRating-iconFilled": {
								color: "#9650FA",
							},
							  '& .MuiRating-iconHover': {
							    color: '#ff3d47',
							  },
						}}
						name="text-feedback"
						value={rating.rating / 2}
						readOnly
						// max={10}
						// size="large"
						precision={0.1}
						icon={<Favorite fontSize="inherit" />}
						emptyIcon={<FavoriteBorder fontSize="inherit" />}
					/>
					<Box>
						{rating.rating / 2} ({rating.rating}/10)
					</Box>
				</Stack>
			</CardContent>
		</Card>
	);
};
