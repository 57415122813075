import {
  Box,
  Card,
  IconButton,
  ImageListItem,
  ImageListItemBar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

function ProductCard(props) {
  const { product, setProducts, products,quantity_limit } = props;
  const [button, setButton] = useState(products.includes(product.id)?<RemoveCircleIcon />:<ControlPointIcon />);
  const [added, setAdded] = useState(products.includes(product.id));

  const notify = (m) =>
  toast.error(m, {
    position: "bottom-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });


  const handelClick = () => {
    if(quantity_limit>0){
        if (!added) {
          if(products.length<quantity_limit){
            setButton(<RemoveCircleIcon />);
            setProducts((values) => [...values, product.id]);

          }else{
            console.log("Limit Exceeded for group")
            notify("Limit Exceeded");
            return;
          }
         
        } else {
          setButton(<ControlPointIcon />);
          let arr = [...products];
          var index = arr.indexOf(product.id);
          if (index !== -1) {
            arr.splice(index, 1);
            setProducts(arr);
          }
        }
        setAdded(!added);
    }
    else{
        if (!added) {
          setButton(<RemoveCircleIcon />);
          setProducts((values) => [...values, product.id]);
        } else {
          setButton(<ControlPointIcon />);
          let arr = [...products];
          var index = arr.indexOf(product.id);
          if (index !== -1) {
            arr.splice(index, 1);
            setProducts(arr);
          }
        }
        setAdded(!added);
    }
  };


  return (
    <Box onClick={handelClick} sx={{ height: "100%" }}>
      <Card sx={{ height: "100%", borderRadius: "8px" }}>
        <ImageListItem
          sx={{
            "& .MuiImageListItem-img": {
              height: 216,
              borderRadius: "8px 8px 0 0",
            },
          }}
        >
          <img src={product.image.file} alt={"Product image"} loading="lazy" />
          <ImageListItemBar
            sx={{
              background: "transparent",
            }}
            position="top"
            actionIcon={
              <IconButton
                size="small"
                sx={{
                  "& .MuiIconButton-sizeSmall": {},
                  backgroundColor: "white",
                  padding: "6px",
                  margin: 1,
                  display: props.displayButton,
                }}
              >
                {button}
              </IconButton>
            }
          />
        </ImageListItem>

        <Typography sx={{ padding: 2, marginTop: "3px" }}>
          {product.name}
        </Typography>
      </Card>
    </Box>
  );
}

export default ProductCard;
