import axios from "axios";

import { axiosInstance } from '../../config/axios'
const handlerEnabled = true

export const loginService = async (details) => {
	return await axiosInstance.post("api/token/", details.data,{handlerEnabled})
};

export const signup = async ({name, email, password}) => {
	
	try {
		const res= await axiosInstance.post("create/", {
			name,
			email,
			password,
		});
		return res.data;
	} catch (e) {
		return false;
	}
};

export const logout = () => {
	localStorage.removeItem("user");
};

export const getCurrentUser = () => {
	return JSON.parse(localStorage.getItem("user"));
};
export const getToken = () => {
	return localStorage.getItem("token");
};


export const setToken=(json)=>{
	console.log('====================================');
	console.log(json);
	console.log('====================================');
	localStorage.setItem('token', json.access);
	localStorage.setItem('refresh_token', json.refresh);
	localStorage.setItem('user', JSON.stringify(json.user));
}


