import {
	Box,
	Button,
	CircularProgress,
	FormControl,
	FormHelperText,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
// import { login } from "../../services/Auth.service";

import validator from "validator";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { login } from "../../store/authentication/actions";
import { Navigate, useOutletContext } from "react-router";
import { getAdSpaceId, getAdmitadToken } from "../../apis/Admited api/Api";


const Login = (props) => {
	const {user,token,loading,error}=props
	const dispatch = useDispatch();
	const [showPassword, setShowPassword] = useState(false);
	const [notify] = useOutletContext()
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	
	const onSubmit = async (data) => {
		console.log(data);
		
		props.loginUser(data)
		if (error){
			notify(error?.data?.detail);
			return
		}
		// // if (user) {
		// // 	console.log(user);
			
		// } else {
		// 	notify();
			
		// }
		// setLoading(false);
		// let formData = new FormData();
		// formData.append("name", data.name);
		// formData.append("file", data.file[0]);
		// let res = await Create(formData);
		// if (res.success) {
		// 	let newSellers = await getAllSeller();
		// 	setRows(newSellers.results);
		// 	setEditOpen(false);
		// }
		// form= new FormData()
	};	
	
	if (user){
		getAdmitadToken()
		getAdSpaceId()
		if(user?.user_type == 4){
			return <Navigate to ="/upload-playoff"></Navigate>
		}
		else{
			return <Navigate to="/w86zjruk6q/" />;
		}		
	}
	
	
	return (
		<Box>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Box pb={3}>
					<TextField
						// required
						fullWidth
						label="Email"
						variant="outlined"
						error={Boolean(errors.email)}
						helperText={errors.email?.message}
						{...register("email", {
							required: "Enter your email id",
							validate: {
								isEmail: (value) =>
									validator.isEmail(value) ||
									"Not a valid email",
							},
						})}
					/>
				</Box>
				<Box pb={5}>
					<FormControl
						error={Boolean(errors.password)}
						fullWidth
						variant="outlined"
					>
						<InputLabel htmlFor="outlined-adornment-password">
							Password*
						</InputLabel>
						<OutlinedInput
							id="outlined-adornment-password"
							type={showPassword ? "text" : "password"}
							{...register("password", {
								required: "Enter Password",
								minLength: {
									value: 8,
									message: "Must be 8 characters",
								},
							})}
							// value={values.password}
							// onChange={handleChange("password")}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={() =>
											setShowPassword((prev) => !prev)
										}
										edge="end"
									>
										{showPassword ? (
											<VisibilityOff />
										) : (
											<Visibility />
										)}
									</IconButton>
								</InputAdornment>
							}
							label="Password*"
						/>
						<FormHelperText>
							{errors.password?.message}
						</FormHelperText>
					</FormControl>
				</Box>
				<Button
					style={{ width: "100%", marginBottom: 20 }}
					type="submit"
					size="large"
					color="primary"
					variant="contained"
					// onClick={login ? handleLogin : handleSignUp}
				>
					{loading ? (
						<CircularProgress size={26} />
					) : 
						"Login"}
				</Button>
			</form>
		</Box>
	);
};
const mapStateToProps = (state) => {
	
	return {loading:state.AuthenticationReducer.isLoading,
		user:state.AuthenticationReducer.user,
		token:state.AuthenticationReducer.token,
		error:state.AuthenticationReducer.error}
		
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
	  {
		loginUser:login,		
	  },
	  dispatch,
	);
  };

export default connect(mapStateToProps,mapDispatchToProps)(Login);