import { Box, Button, Link, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {getCurationShareCountDetails } from "../../../apis/Api";
import { useLocation } from 'react-router-dom';

function CurationCustomeCellData({ row, filter}) {
  const [displaying, setDisplaying] = useState(false); 
    const [name, setName] = useState([]);
     
  
  
  const  getProductDetails = async () => {   
    // console.log(row)
 let data = await getCurationShareCountDetails(filter, row.m_list.id)
 console.log('====================================');
 console.log(data);
 console.log('====================================');
  setDisplaying(true)
  setName(data)
};
 
  return (
    <>
      <Stack direction="row" justifyContent="center">
        <Box sx={{paddingX:2}}>
          <Typography>Share count</Typography>
         
            <Typography >
            {row.total_share}
            </Typography>
         
        </Box>
        
      </Stack>
      <Button onClick={getProductDetails} variant="text">View details</Button>

      
       <Box sx={{display:displaying? "block":"none", marginTop:"15px"}}>				
									
                  {name?.map((p) => {
                   
                    return (         
                        <Box sx={{textAlign:"center"}}>
                         
                          <Typography variant="body2" sx={{ textAlign: "center" }}>
                         {p.slug}
                          </Typography>          
                      </Box>
                    );      
                    })}  
                  </Box>
    </>
  );
}

export default  CurationCustomeCellData;
