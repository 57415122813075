import {

	Avatar,
	Box,
	Button,
	Card,
	CardMedia,
	Divider,
	Drawer,
	Grid,
	Stack,
	styled,
	Tab,
	Toolbar,
	Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { ToastContainer } from "react-toastify";
import { getUserDetails } from "../../apis/user";
import { EditUserDrawer } from "../../components/User/EditUserDrawer";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Profile from "../../components/User/Details/Tabs/Profile";

const StyledCard = styled(Card)(() => ({
	position: "relative",
	borderTopLeftRadius: 0,
	borderTopRightRadius: 0,
}));

const StyledTab = styled(Tab)(({ theme }) => ({
	fontSize: 13,
	color: theme.palette.text.primary,
}));

const StyledTabList = styled(TabList)(({ theme }) => ({
	[theme.breakpoints.down(780)]: {
		width: "100%",
		"& .MuiTabs-flexContainer": {
			justifyContent: "space-between",
		},
		marginBottom: 20,
	},
	[theme.breakpoints.up("sm")]: {
		"& .MuiTabs-flexContainer": {
			minWidth: 400,
			justifyContent: "space-between",
		},
	},
}));
const StyledTabPanel = styled(TabPanel)(() => ({
	padding: 0,
}));

export const UserDetail = () => {
	const { slug } = useParams();
	console.log(slug);
	const [user, setUser] = useState(null);
	const [editOpen, setEditOpen] = useState(false);

	const [loading, setLoading] = useState(false);
	const [value, setValue] = useState("1");
	useEffect(() => {
		getUserDetails(slug).then((res) => {
			console.log("====================================");
			console.log(res);
			console.log("====================================");
			setUser(res);
		});
	}, []);

	const handleChange = (_, newValue) => {
		setValue(newValue);
	};

	return (
		<>
			<Box pt={2} pb={4} mx={5}>
				<TabContext value={value}>
					<StyledCard>
						<Grid container justifyContent={"center"}>
							<Grid item xs={12}>
								<Box
									sx={{
										height: 200,
										width: "100%",
										overflow: "hidden",
									}}
								>
									<img
										src="https://images.unsplash.com/photo-1504805572947-34fad45aed93?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80"
										alt="User Cover"
										height="100%"
										width="100%"
										style={{ objectFit: "cover" }}
									/>
								</Box>
							</Grid>
							<Grid item xs={12} md={8}>
								<Box
									display="flex"
									flexWrap="wrap"
									padding="0 2rem"
									alignItems="center"
									justifyContent="space-between"
								>
									<Box
										display="flex"
										flexWrap="wrap"
										// padding="0 2rem"
										alignItems="center"
										justifyContent="space-between"
										sx={{
											// top: -20,
											alignItems: "center",
											position: "relative",
										}}
									>
										<Avatar
											src={
												user?.image?.file ||
												"/static/avatar/001-man.svg"
											}
											sx={{
												top: -20,
												border: 4,
												width: 100,
												height: 100,
												borderColor:
													"rgb(226, 232, 240)",
											}}
										></Avatar>
										<Stack
											direction="row"
											marginLeft={3}
											marginTop={3}
											divider={
												<Divider
													orientation="vertical"
													flexItem
												/>
											}
											spacing={5}
										>
											<Box>
												<Typography
													variant="h5"
													lineHeight={1.2}
												>
													{user?.name}
												</Typography>
												<Typography
													variant="small"
													color="text.disabled"
												>
													{user?.role}
												</Typography>
											</Box>

											<Box>
												<Typography
													variant="h5"
													lineHeight={1.2}
												>
													{user?.name}
												</Typography>
												<Typography
													variant="small"
													color="text.disabled"
												>
													{user?.role}
												</Typography>
											</Box>
										</Stack>
									</Box>
									<Box>
										
									</Box>
									<StyledTabList onChange={handleChange}>
										<StyledTab label="About" value="1" />
										<StyledTab
											label="Curations"
											value="2"
										/>
										<StyledTab label="Ratings" value="3" />
										{/* <StyledTab label="Gallery" value="4" /> */}
									</StyledTabList>
								</Box>
							</Grid>
						</Grid>
					</StyledCard>
					<Box marginTop={3} marginX={3}>
						<Grid container justifyContent={"center"}>
							<Grid item xs={12} md={8}>
								<StyledTabPanel value="1">
									<Profile />
								</StyledTabPanel>

								<StyledTabPanel value="2">
									<Grid container spacing={3}>
										{/* {followers.map((item, index) => (
                <Grid item lg={4} sm={6} xs={12} key={index}>
                  <FollowerCard follower={item} />
                </Grid>
              ))} */}
									</Grid>
								</StyledTabPanel>

								<StyledTabPanel value="3">
									{/* <H3>Friends</H3>
            <SearchInput placeholder="Search Friends..." sx={{ my: 2 }} />

            <Grid container spacing={3}>
              {friends.map((friend, index) => (
                <Grid item lg={4} sm={6} xs={12} key={index}>
                  <FriendCard friend={friend} />
                </Grid>
              ))}
            </Grid> */}
								</StyledTabPanel>
							</Grid>
						</Grid>

						{/* <StyledTabPanel value="4">
							{/* <Gallery /> *
						</StyledTabPanel> */}
					</Box>
				</TabContext>
			</Box>

			<Drawer
				anchor="right"
				open={editOpen}
				onClose={() => setEditOpen(false)}
			>
				<EditUserDrawer
					edit={true}
					setEditOpen={setEditOpen}
					user={user}
					setUser={setUser}
					setRows={() => {}}
					fromDetails={true}
					loading={loading}
				/>
			</Drawer>
		</>
	);
};
