import { Fragment, useEffect, useState } from "react";
import _ from "lodash";
import { ToastContainer, toast } from "react-toastify";

import { Controller, useForm } from "react-hook-form";
import {
	Box,
	Button,
	Drawer,
	Typography,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
	Grid,
	Avatar,
	IconButton,
	Card,
	CardMedia,
	CardContent,
	CardActions,
	CardHeader,
	Collapse,
	AvatarGroup,
	Stack,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Switch,
	createFilterOptions,
} from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import { getAllBrand } from "../../apis/Brand";
import { AddProductDrawer } from "../../components/GrapeList/AddProductDrawer";
import { AddGroup } from "../../components/Playoff/AddGroup";
import { AddPrizeDrawer } from "../../components/Playoff/AddPrizeDrawer";
import EditIcon from "@mui/icons-material/Edit";
import { getTags } from "../../apis/Tags";
import { Delete, Edit, ExpandMore } from "@mui/icons-material";
import { styled } from "@mui/system";
import { getPlayoffDetails, UpdatePlayoff } from "../../apis/Playoff";
import Loader from "../../components/Loader/Loader";
import { ImageCropper } from "../../components/CommonFunctions/ImageCropper";
import { getCurrentUser } from "../../store/authentication/service";
import { AestheticInput } from "./AestheticInput";

const filter = createFilterOptions();

export const EditPlayoff = ({ searchText }) => {
	const navigate = useNavigate();

	const [addProduct, setAddProduct] = useState(false);
	const [addGroup, setAddGroup] = useState(false);
	const [addPrize, setAddPrize] = useState(false);
	const [playoff, setPlayoff] = useState();

	const [prize, setPrize] = useState(null);
	const [loading, setLoading] = useState(false);

	const [isGrouped, setIsGrouped] = useState(false);
	const [list, setList] = useState([]);
	const [products, setProducts] = useState([]);
	const [groups, setGroups] = useState([]);
	const [group, setGroup] = useState([]);
	const [brand, setBrand] = useState([]);
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState(null);
	const [deletingProduct, setDeletingProduct] = useState({});
	const [expandedId, setExpandedId] = useState(-1);
	const [open, setOpen] = useState(false);
	const [groupOpen, setGroupOpen] = useState(false);
	const [active, setActive] = useState(true);
	const [isPlayoff, setIsPlayoff] = useState(true);
	const [deletingGroup, setDeletingGroup] = useState({});
	const [groupEdit, setGroupEdit] = useState();
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [alias, setAlias] = useState("");
	const [pinToTop, setPinToTop] = useState(false);
	const [aesthetic, setAesthetic] = useState(null);
	const [filename, setFilename] = useState("");
	const [curationImage, setCurationImage] = useState(null);
	const [crop, setCrop] = useState(false);

	const { id } = useParams();
	const [selectedBrand, setSelectedBrand] = useState("");
	const [user, setUser] = useState();

	const handleOpen = (item) => {
		setOpen(true);
		setDeletingProduct(item);
	};
	const handleClose = () => setOpen(false);

	const handleGroupOpen = (item) => {
		setGroupOpen(true);
		setDeletingGroup(item);
	};
	const handleGroupClose = () => setGroupOpen(false);
	const handleOnChange = (event) => {
		const newImage = event.target?.files?.[0];
		console.log(newImage);
		if (newImage) {
			// console.log(newImage);
			setFilename(newImage.name);
			setCurationImage(URL.createObjectURL(newImage));
			setCrop(true);
		}
	};

	const ExpandMoreDiv = styled((props) => {
		const { expand, ...other } = props;
		return <IconButton {...other} />;
	})(({ theme, expand }) => ({
		transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
		marginLeft: "auto",
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.shortest,
		}),
	}));
	const handleExpandClick = (i) => {
		setExpandedId(expandedId === i ? -1 : i);
	};
	useEffect(() => {
		let user = getCurrentUser();
		setUser(user);
	}, []);

	useEffect(() => {
		setLoading(true);
		_.debounce(async () => {
			let playoff = await getPlayoffDetails(id);
			console.log(playoff);
			if (playoff.brand.length) {
				setSelectedBrand(playoff.brand[0].id);
				setValue("brand", playoff.brand[0].id);
			}
			setStartDate(dayjs(playoff.play_off_start_date_time));
			setEndDate(dayjs(playoff.play_off_end_date_time));
			setPrize(playoff.prize);
			setGroups(playoff.groups);
			setPlayoff(playoff);
			setProducts(playoff.products);
			setIsGrouped(playoff.grouped_list);
			setPinToTop(playoff.pin_to_top);
			setAesthetic(playoff.aesthetic)
			let tags = await getTags();

			let lis = tags.results.map((tag) => {
				let index = playoff.list_category.findIndex(
					(lis) => lis.id === tag.id
				);
				tag.checked = index !== -1;

				return tag;
			});
			setValue(
				"list_category",
				playoff.list_category.map((lc) => lc.id)
			);
			setList(lis);
			let brands = await getAllBrand();
			setBrand(brands.results);
			setActive(playoff.active);
			setIsPlayoff(playoff.is_playoff);
			setTitle(playoff?.title);
			setValue("title", playoff?.title);
			setDescription(playoff?.description);
			setAlias(playoff?.alias);
			setValue("alias", playoff?.alias);
			setValue("description", playoff?.description);
			setValue(
				"play_off_end_date_time",
				dayjs(playoff.play_off_end_date_time)
			);
			setValue(
				"play_off_start_date_time",
				dayjs(playoff.play_off_start_date_time)
			);
			setValue("active", !playoff.active);
			setCurationImage(playoff.image.file);
		}, 5)();
		setLoading(false);
	}, [id]);

	const handleAddProduct = () => {
		setAddProduct(true);
	};
	const handleAddGroup = () => {
		setAddGroup(true);
		setGroup({ name: "", description: "", qty_limit: "", products: [] });
		setGroupEdit(false);
	};
	const handleAddPrize = () => {
		setAddPrize(true);
	};

	const handleGroupEdit = (group_id) => {
		let group = groups.find(({ id }) => id === group_id);
		setGroup(group);
		setAddGroup(true);
		setGroupEdit(true);
	};
	// const handleEdit = (i) => {
	// 	setTitle("Edit");

	// 	setEditOpen(true);
	// };

	const {
		register,
		handleSubmit,
		setValue,
		control,
		unregister,
		formState: { errors },
	} = useForm();
	const notify = (m) =>
		toast.error(m, {
			position: "bottom-right",
			autoClose: 2500,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});

	const onSubmit = async (data) => {
		data.active = !data.active;
		
		// if (!prize) {
		// 	notify("Add Giveway for the playoff");
		// 	setAddPrize(true);
		// 	return;
		// }
		if (isGrouped && !groups.length) {
			notify("Add atleast one Group");
			setAddGroup(true);
			return;
		}

		if (!isGrouped && !products.length) {
			notify("Please Add products");
			return;
		}
		if (prize) data["prize"] = prize.id;
		data["grouped_list"] = isGrouped;

		// if (!data.products) {
		// 	setAddProduct(true);
		// 	notify();
		// 	return;
		// }

		// let formData = new FormData();
		let formData = new FormData();
		let pproducts;
		let pgroups;
		for (let i in data) {
			if (i === "list_category") {
				if (data[i].length) {
					console.log(data[i]);
					formData.append(i, data[i]);
				}
			} else if (i === "file") {
				console.log(i, data[i]);
				if (data[i].length) {
					let blob = await fetch(curationImage).then((r) => r.blob());
					formData.append("file", blob, filename);
				}
			} else if (i === "brand") {
				formData.append(i, data[i]);
			}else if (i==="aesthetic" && data[i]) {
				formData.append(i,data[i].id)
			} 
			else formData.append(i, data[i]);
		}
		formData.append("play_off_start_date_time", startDate.format());
		formData.append("play_off_end_date_time", endDate.format());
		formData.append("voting_start_date_time", endDate.format());
		formData.append("voting_end_date_time", endDate.add(1, "day").format());
		formData.append("pin_to_top", pinToTop);
		if (isGrouped) {
			data["groups"] = groups.map((g) => g.id);
			console.log(groups);
			pgroups = groups.map((p) => parseInt(p.id));
			console.log(pgroups);
			formData.append("groups", pgroups);
		} else {
			data["products"] = products.map((g) => g.id);
			pproducts = products.map((p) => parseInt(p.id));
			formData.append("products", pproducts);
		}
		// let list_category =list.filter((item)=>item.checked)
		// if(data["list_category"].length){
		// 	formData["list_category"] = data["list_category"];
		// }

		let res = await UpdatePlayoff(formData, playoff.id);
		console.log("====================================");
		console.log(res);
		console.log("====================================");
		if (res.success) {
			if (user?.user_type === 4) {
				navigate("/upload-playoff/playoff");
			} else {
				navigate("/w86zjruk6q/playoff");
			}

			// 	let newSellers = await getAllSeller();
			// 	setRows(newSellers.results);
			// 	setEditOpen(false);
		}
	};

	return (
		<Grid container justifyContent="center" spacing={2}>
			<ToastContainer />
			{playoff ? (
				<Grid item xs={12} md={8}>
					<Box sx={{ px: 5 }}>
						<div style={{ padding: "45px 55px 0px" }}>
							<form onSubmit={handleSubmit(onSubmit)}>
								<Box
									display="flex"
									justifyContent="space-between"
								>
									<Typography variant="h6" gutterBottom>
										Edit Playoff
									</Typography>
								</Box>

								<Box my={3}>
									<TextField
										fullWidth
										name="title"
										// style={{ width: "100%" }}
										label="Enter playoff title"
										variant="outlined"
										value={title}
										onChange={(e) => {
											setValue(e.target.value);
										}}
										error={Boolean(errors.title)}
										helperText={errors.title?.message}
										{...register("title", {
											required: "Enter Playoff name",
											onChange: (e) => {
												setTitle(e.target.value);
											},
										})}
									/>
								</Box>

								<Box my={3}>
									<TextField
										fullWidth
										name="description"
										multiline
										rows={4}
										value={description}
										label="Enter description"
										variant="outlined"
										{...register("description", {
											required: "Enter description",
											onChange: (e) => {
												setDescription(e.target.value);
											},
										})}
										error={Boolean(errors.description)}
										helperText={errors.description?.message}
									/>
								</Box>
								<Box my={3}>
									<TextField
										fullWidth
										name="alias"
										label="Enter alias"
										variant="outlined"
										value={alias || ""}
										error={Boolean(errors.alias)}
										helperText={errors.alias?.message}
										{...register("alias", {
											required: "Enter alias",
											onChange: (e) => {
												setAlias(e.target.value);
											},
										})}
									/>
								</Box>

								<Box my={3}>
									<FormControl
										required
										error={Boolean(errors.list_category)}
										// component="outlined"
										// sx={{ m: 3 }}
										variant="outlined"
									>
										<FormLabel>List Category</FormLabel>
										<FormGroup row>
											{list.map((inputlist, i) => {
												return (
													<FormControlLabel
														key={`listt-${i}`}
														control={
															<Checkbox
																{...register(
																	"list_category",
																	{
																		// required:
																		// 	"Please Pick atleast one",
																	}
																)}
																value={
																	inputlist.id
																}
																onChange={(
																	e
																) => {
																	setList(
																		list.map(
																			(
																				l
																			) => {
																				// console.log('====================================');
																				// console.log(l.id===inputlist.id);
																				// console.log('====================================');
																				if (
																					l.id ===
																					inputlist.id
																				)
																					l.checked =
																						!l.checked;
																				return l;
																			}
																		)
																	);
																	setValue(
																		"list_category",
																		e.target
																			.value
																	);
																}}
																checked={
																	inputlist.checked
																}
																name="list_category"
															/>
														}
														label={inputlist.name}
													/>
												);
											})}
										</FormGroup>
										<FormHelperText>
											{errors.list_category?.message}
										</FormHelperText>
									</FormControl>
								</Box>
								<Box my={3}>
									<Grid container spacing={2}>
										<Grid item md={4}>
											<FormControl
												fullWidth
												error={Boolean(errors.brand)}
											>
												<InputLabel htmlFor="brand">
													Brand (optional)
												</InputLabel>
												<Select
													{...register("brand", {
														// required: "select Brand",
													})}
													value={selectedBrand}
													input={
														<OutlinedInput label="Brand (optional)" />
													}
													defaultValue={selectedBrand}
													onChange={(e) => {
														setSelectedBrand(
															e.target.value
														);
													}}
												>
													{brand.map((product, i) => {
														return (
															<MenuItem
																key={`brand-${i}`}
																value={
																	product.id
																}
															>
																{product.name}
															</MenuItem>
														);
													})}
												</Select>

												<FormHelperText>
													{errors.brand &&
														errors.brand?.message}
												</FormHelperText>
											</FormControl>
										</Grid>
										<Grid item md={4}>
											<TextField
												fullWidth
												type="number"
												InputProps={{
													inputProps: { min: 1 },
												}}
												id="outlined-basic"
												label={"Max Entries Allowed"}
												variant="outlined"
												value={
													playoff.max_entries_allowed ||
													""
												}
												{...register(
													"max_entries_allowed",
													{
														// required:
														// 	"Enter No of Entries Allowed",
													}
												)}
											/>
										</Grid>
										<Grid item md={4}>
											<TextField
												{...register("price_limit", {
													// required:
													// 	"Enter Price Limit",
												})}
												type="number"
												InputProps={{
													inputProps: { min: 0 },
												}}
												fullWidth
												value={
													playoff.price_limit || ""
												}
												id="outlined-basic"
												label={"Price Limit"}
												variant="outlined"
											/>
										</Grid>
									</Grid>
								</Box>
								<Box my={3}>
									<Grid container spacing={2}>
										<Grid item md={6}>
											<LocalizationProvider
												dateAdapter={AdapterDayjs}
											>
												<Controller
													control={control}
													name="play_off_start_date_time"
													rules={{
														required:
															"please select the start date",
														validate: {
															// min: (date) => {
															// 	return (
															// 		date.isAfter(
															// 			dayjs()
															// 		) ||
															// 		"Please, enter a future date"
															// 	);
															// },
														},
													}}
													render={({
														field: {
															ref,
															onBlur,
															onChange,
															name,
															value,
															...field
														},
														fieldState,
													}) => (
														<DateTimePicker
															{...field}
															value={startDate}
															onChange={(
																newValue
															) => {
																setStartDate(
																	newValue
																);

																let addday =
																	newValue.add(
																		1,
																		"day"
																	);
																setValue(
																	"play_off_end_date_time",
																	addday
																);
																setEndDate(
																	addday
																);

																onChange(
																	newValue
																);
															}}
															inputRef={ref}
															label="Start date & time"
															minDateTime={dayjs()}
															renderInput={(
																inputProps
															) => (
																<TextField
																	fullWidth
																	{...inputProps}
																	onBlur={
																		onBlur
																	}
																	name={name}
																	error={
																		!!fieldState.error
																	}
																	helperText={
																		fieldState
																			.error
																			?.message
																	}
																/>
															)}
														/>
													)}
												/>
											</LocalizationProvider>
										</Grid>
										<Grid item md={6}>
											<LocalizationProvider
												dateAdapter={AdapterDayjs}
											>
												<Controller
													control={control}
													name="play_off_end_date_time"
													rules={{
														required:
															"please select the end date",
														validate: {
															min: (date) => {
																console.log(
																	startDate.format()
																);
																return (
																	date.isAfter(
																		startDate
																	) ||
																	"End date should be greatern then start date"
																);
															},
														},
													}}
													render={({
														field: {
															ref,
															onBlur,
															onChange,
															name,
															...field
														},
														fieldState,
													}) => (
														<DateTimePicker
															{...field}
															value={endDate}
															minDateTime={
																startDate
																	? startDate
																	: dayjs()
															}
															onChange={(
																newValue
															) => {
																setEndDate(
																	newValue
																);
																onChange(
																	newValue
																);
															}}
															inputRef={ref}
															label="End date & time"
															renderInput={(
																inputProps
															) => (
																<TextField
																	fullWidth
																	{...inputProps}
																	onBlur={
																		onBlur
																	}
																	name={name}
																	error={
																		!!fieldState.error
																	}
																	helperText={
																		fieldState
																			.error
																			?.message
																	}
																/>
															)}
														/>
													)}
												/>
											</LocalizationProvider>
										</Grid>
									</Grid>
								</Box>
								<Box my={3}>
									<Grid container>
										<Grid item xs={6}>
											<Box
												mb={3}
												display="flex"
												justifyContent="space-between"
											>
												<Typography
													variant="h6"
													gutterBottom
												>
													Give Away
												</Typography>
												{!prize ? (
													<Button
														type="button"
														onClick={handleAddPrize}
														color="primary"
														variant="contained"
													>
														{"Add Give Away"}
													</Button>
												) : (
													<Box>
														<Card
															sx={{
																maxWidth: 345,
															}}
														>
															<CardHeader
																action={
																	<IconButton
																		aria-label="settings"
																		onClick={
																			handleAddPrize
																		}
																	>
																		<EditIcon />
																	</IconButton>
																}
															/>
															<CardMedia
																sx={{
																	objectFit:
																		"cover",
																	width: "100%",
																}}
																component="img"
																// height="240"
																image={
																	prize.image
																}
																alt="green iguana"
															/>
															<CardContent>
																<Typography
																	gutterBottom
																	variant="h6"
																	component="div"
																>
																	{
																		prize.title
																	}
																</Typography>
																<Typography
																	variant="body2"
																	color="text.secondary"
																>
																	{
																		prize.description
																	}
																</Typography>
															</CardContent>
														</Card>
													</Box>
												)}
											</Box>
										</Grid>
										<Grid item xs={6}>
											{curationImage && (
												<>
													<Box
														sx={{
															textAlign: "center",
														}}
													>
														<FormControl
															error={Boolean(
																errors.file
															)}
														>
															{curationImage && (
																<Avatar
																	variant="square"
																	src={
																		curationImage
																	}
																	sx={{
																		width: 150,
																		height: 150,
																		objectFit:
																			"fill",
																		marginBottom: 2,
																		marginX:
																			"auto",
																	}}
																/>
															)}
															<Button
																variant="contained"
																component="label"
																onChange={
																	handleOnChange
																}
															>
																{!curationImage
																	? "Upload "
																	: "Change "}
																image
																<input
																	hidden
																	accept="image/*"
																	id="file-upload"
																	type="file"
																	{...register(
																		"file",
																		{
																			// required: image == null,
																		}
																	)}
																	error={
																		errors.file
																	}
																/>
															</Button>
														</FormControl>
													</Box>
													<ImageCropper
														image={curationImage}
														setImage={
															setCurationImage
														}
														open={crop}
														setOpen={setCrop}
														aspect={3 / 4}
													/>
												</>
											)}
										</Grid>
									</Grid>

									{/* {prize && (
										<Box>
											<Card sx={{ maxWidth: 345 }}>
												<CardHeader
													action={
														<IconButton aria-label="settings">
															<EditIcon
																onClick={
																	handleAddPrize
																}
															/>
														</IconButton>
													}
												/>
												<CardMedia
													sx={{
														objectFit: "cover",
														width: "100%",
													}}
													component="img"
													// height="240"
													image={prize.image}
													alt="green iguana"
												/>
												<CardContent>
													<Typography
														gutterBottom
														variant="h6"
														component="div"
													>
														{prize.title}
													</Typography>
													<Typography
														variant="body2"
														color="text.secondary"
													>
														{prize.description}
													</Typography>
												</CardContent>
											</Card>
										</Box>
									)} */}
								</Box>

								<Box my={3}>
									<FormGroup>
										<FormControlLabel
											control={
												<Checkbox
													checked={isGrouped}
													onChange={(e) => {
														setValue(
															"price_limit",
															""
														);
														setValue(
															"qty_limit",
															""
														);
														if (e.target.checked) {
															unregister(
																"qty_limit"
															);
														} else {
															// register("price_limit");
															register(
																"qty_limit"
															);
														}
														setIsGrouped(
															e.target.checked
														);
													}}
												/>
											}
											label="Grouped List"
										/>
									</FormGroup>
								</Box>
								<Box my={3}>
									{isGrouped ? (
										<>
											<Box
												mb={3}
												display="flex"
												justifyContent="space-between"
											>
												<Typography
													variant="h6"
													gutterBottom
												>
													Groups
												</Typography>
												<Button
													type="button"
													onClick={handleAddGroup}
													color="primary"
													variant="contained"
												>
													{"Add Groups"}
												</Button>
											</Box>
											{groups.length > 0 && (
												<Grid container spacing={2}>
													{groups.map((group, i) => {
														group.expand = false;
														return (
															<Grid
																item
																md={3}
																key={`gp-grid-${i}`}
															>
																<Card>
																	<CardHeader
																		title={`name:${group.name}`}
																		subheader={`Group Category:${group.product_category[0]?.name}`}
																	/>
																	<CardContent>
																		<Typography
																			variant="h6"
																			component="div"
																		>
																			Product:
																		</Typography>
																		<Typography component="div">
																			<AvatarGroup
																				sx={{
																					justifyContent:
																						"center",
																				}}
																				total={
																					group
																						.products
																						?.length
																				}
																			>
																				{group.products
																					.slice(
																						0,
																						4
																					)
																					.map(
																						(
																							product,
																							i
																						) => (
																							<Avatar
																								key={`group-product-${i}`}
																								alt={
																									product.name
																								}
																								src={
																									product
																										.image
																										.file
																								}
																							/>
																						)
																					)}
																			</AvatarGroup>
																		</Typography>
																		<Typography
																			variant="h6"
																			component="div"
																		>
																			Qty
																			Limit:
																			{
																				group.qty_limit
																			}
																		</Typography>
																	</CardContent>
																	<CardActions
																		disableSpacing
																	>
																		<IconButton
																			aria-label="add to favorites"
																			onClick={() => {
																				handleGroupEdit(
																					group.id
																				);
																			}}
																		>
																			<Edit />
																		</IconButton>
																		<IconButton
																			aria-label="delete product"
																			onClick={() => {
																				handleGroupOpen(
																					group
																				);
																				// setProducts(new_products)
																			}}
																		>
																			<Delete color="secondary" />
																		</IconButton>
																		<ExpandMoreDiv
																			expand={
																				expandedId ===
																				i
																			}
																			onClick={() =>
																				handleExpandClick(
																					i
																				)
																			}
																			aria-expanded={
																				expandedId ===
																				i
																			}
																			aria-label="show more"
																		>
																			<ExpandMore />
																		</ExpandMoreDiv>
																	</CardActions>
																	<Collapse
																		in={
																			expandedId ===
																			i
																		}
																		timeout="auto"
																		unmountOnExit
																	>
																		<CardContent>
																			<Typography
																				paragraph
																			>
																				Products:
																			</Typography>
																			<Box>
																				{group.products.map(
																					(
																						product,
																						i
																					) => {
																						return (
																							<Stack
																								key={`product-stack-${i}`}
																								direction="row"
																								alignItems="center"
																								gap={
																									1
																								}
																							>
																								<Avatar
																									alt="Remy Sharp"
																									src={
																										product
																											.image
																											.file
																									}
																								/>
																								<Typography
																									paragraph
																									sx={{
																										marginBottom: 0,
																									}}
																								>
																									{
																										product.name
																									}
																								</Typography>
																							</Stack>
																						);
																					}
																				)}
																			</Box>
																		</CardContent>
																	</Collapse>
																</Card>
															</Grid>
														);
													})}
												</Grid>
												// <Box my={3}>
												// 	<FormControl
												// 		required
												// 		error={Boolean(
												// 			errors.products
												// 		)}
												// 		variant="outlined"
												// 	>
												// 		<FormLabel>
												// 			Product List
												// 		</FormLabel>
												// 		<FormGroup row>
												// 			{groups.map(group=>
												// 				group.name

												// 			)}
												// 		</FormGroup>
												// 		<FormHelperText>
												// 			{
												// 				errors.products
												// 					?.message
												// 			}
												// 		</FormHelperText>
												// 	</FormControl>
												// </Box>
											)}
										</>
									) : (
										<>
											<Box
												mb={3}
												display="flex"
												justifyContent="space-between"
											>
												<Typography
													variant="h6"
													gutterBottom
												>
													Products
												</Typography>
												<Button
													type="button"
													onClick={handleAddProduct}
													color="primary"
													variant="contained"
												>
													{"Add Products"}
												</Button>
											</Box>
											{products && (
												<Box my={3}>
													<Grid container spacing={2}>
														{products.map(
															(item, i) => (
																<Grid
																	key={`product-grid-${i}`}
																	item
																	xs={12}
																	md={3}
																>
																	<Card
																		sx={{
																			height: "100%",
																			display:
																				"flex",
																			justifyContent:
																				"space-between",
																			flexDirection:
																				"column",
																		}}
																	>
																		<CardMedia
																			component="img"
																			// height="194"
																			image={
																				item
																					.image
																					.file
																			}
																			alt="Paella dish"
																		/>
																		<CardContent>
																			<Typography
																				variant="body2"
																				color="text.secondary"
																			>
																				{
																					item.name
																				}
																			</Typography>
																		</CardContent>
																		<CardActions
																			disableSpacing
																			sx={{
																				display:
																					"flex",
																				alignItems:
																					"end",
																				justifyContent:
																					"flex-end",
																			}}
																		>
																			<IconButton
																				aria-label="delete product"
																				onClick={() => {
																					handleOpen(
																						item
																					);
																					// setProducts(new_products)
																				}}
																			>
																				<Delete color="secondary" />
																			</IconButton>
																		</CardActions>
																	</Card>
																</Grid>
															)
														)}
													</Grid>
												</Box>
											)}
										</>
									)}
								</Box>

								<Box my={3}>
									<Grid container spacing={2}>
										{!isGrouped && (
											<Grid item md={4}>
												<TextField
													fullWidth
													id="outlined-basic"
													label={"Qty Limit"}
													type="number"
													value={playoff.qty_limit}
													InputProps={{
														inputProps: { min: 0 },
													}}
													{...register("qty_limit", {
														required:
															"Enter Qty Limit",
													})}
													variant="outlined"
												/>
											</Grid>
										)}
										<Grid item md={4}>
											<Box
												sx={{
													paddingX: 2,
													paddingY: 0.75,
													border: "1px solid rgba(0, 0, 0, 0.23)",
													borderRadius: "4px",
												}}
											>
												<Stack
													direction={"row"}
													justifyContent="space-between"
													alignItems={"center"}
												>
													<FormLabel>
														Save as Draft
													</FormLabel>
													<Switch
														// size="small"

														type="checkbox"
														{...register(
															"active",
															{}
														)}
														onChange={(e) => {
															setActive(
																!e.target
																	.checked
															);
														}}
														checked={!active}
													/>
												</Stack>
											</Box>
										</Grid>
										<Grid item md={4}>
											<Box
												sx={{
													paddingX: 2,
													paddingY: 0.75,
													border: "1px solid rgba(0, 0, 0, 0.23)",
													borderRadius: "4px",
												}}
											>
												<Stack
													direction={"row"}
													justifyContent="space-between"
													alignItems={"center"}
												>
													<FormLabel>
														For PlayOff
													</FormLabel>
													<Switch
														// size="small"

														type="checkbox"
														{...register(
															"is_playoff",
															{}
														)}
														onChange={(e) => {
															setIsPlayoff(
																e.target.checked
															);
														}}
														checked={isPlayoff}
													/>
												</Stack>
											</Box>
										</Grid>
										<Grid item md={4}>
											<Controller
												name="aesthetic"
												control={control}
												defaultValue={aesthetic}
												render={({ field }) => (
													<AestheticInput
														field={field}
													/>
												)}
											/>

											
										</Grid>
									</Grid>
								</Box>

								<Box my={3}></Box>

								<Box mb={3}>
									<Button
										type="submit"
										fullWidth
										// onClick={handleSave}
										color="primary"
										variant="contained"
									>
										{"Update"}
									</Button>
								</Box>
							</form>
						</div>
						<Drawer
							anchor="right"
							open={addProduct}
							onClose={() => setAddProduct(false)}
						>
							<AddProductDrawer
								fromGroup={false}
								setEditOpen={setAddProduct}
								products={products}
								setProducts={setProducts}
							/>
						</Drawer>
						<Drawer
							anchor="right"
							open={addGroup}
							onClose={() => setAddGroup(false)}
						>
							<AddGroup
								title={"Add"}
								setEditOpen={setAddGroup}
								setGroups={setGroups}
								edit={groupEdit}
								group={group}
								setGroup={setGroup}
								groups={groups}
							/>
						</Drawer>
						<Drawer
							anchor="right"
							open={addPrize}
							onClose={() => setAddPrize(false)}
						>
							<AddPrizeDrawer
								title={"Add"}
								prize={prize}
								setPrize={setPrize}
								setEditOpen={setAddPrize}
								products={products}
								setProducts={setProducts}
							/>
						</Drawer>
					</Box>
				</Grid>
			) : (
				<Loader />
			)}
			<Dialog
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={open}
				onClose={handleClose}
				closeAfterTransition
				// BackdropComponent={Backdrop}
				// BackdropProps={{
				// 	timeout: 500,
				// }}
			>
				<DialogTitle id="alert-dialog-title">
					{"Are you sure?"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Do you want to remove product
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>No</Button>
					<Button
						onClick={() => {
							let new_products = products.filter((product) => {
								return product !== deletingProduct;
							});
							setDeletingProduct({});
							setProducts(new_products);
							handleClose();
						}}
						autoFocus
						color="secondary"
					>
						Yes
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={groupOpen}
				onClose={handleGroupClose}
				closeAfterTransition
				// BackdropComponent={Backdrop}
				// BackdropProps={{
				// 	timeout: 500,
				// }}
			>
				<DialogTitle id="alert-dialog-title">
					{"Are you sure?"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Do you want to remove Group
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleGroupClose}>No</Button>
					<Button
						onClick={() => {
							let new_groups = groups.filter((product) => {
								return product !== deletingGroup;
							});
							setDeletingGroup(null);
							setGroups(new_groups);
							handleGroupClose();
						}}
						autoFocus
						color="secondary"
					>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		</Grid>
	);
};
