import { axiosInstance } from "../config/axios";
import { BaseURLwithEndPoint } from "./config";
import axios from "axios";

let base = BaseURLwithEndPoint();

const endpoint = base + "product/";

export const Create = async (params) => {
	try {
		const res = await axios.post(endpoint, params);
		console.log("====================================");
		console.log(res);
		console.log("====================================");
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};

export const Update = async (id, params) => {
	try {
		let url = endpoint + id + "/";
		const res = await axios.put(url, params);
		console.log("====================================");
		console.log(res);
		console.log("====================================");
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};

const handlerEnabled = true
export const getAllProduct = async (row,page=1,filters={},order_by='-created_at') => {
	console.log('====================================');
	console.log(row);
	console.log('====================================');
	try {
		const res = await axios.get(endpoint,{params: { page_size: row, page: page,order_by,...filters },handlerEnabled});

		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};
export const checkProductExistAPI = async (url) => {
	try {
		let endpoint = base + "product/checkexist";
		const res = await axios.get(endpoint, { params: { url: url } });

		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};


export const getProductPreview = async (url) => {
	const postData = { product_url: url };
	const api = `${base}product-parser`;
	const res = await axios
		.post(api, postData)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			return [];
		});
	//const data = await res.json()
	return res.data;
};

export  const getColors = async () =>{
	try {
		const res = await axiosInstance.get(base+"colors");
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
}