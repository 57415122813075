import React, { useState } from "react";
import { Close } from "@mui/icons-material";
import {
	Avatar,
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	IconButton,
	OutlinedInput,
	TextField,
	Typography,
} from "@mui/material";

import { Controller, useForm } from "react-hook-form";
import { addTag, editTag, getTags } from "../../apis/Tags";
import { ImageCropper } from "../CommonFunctions/ImageCropper";

export const EditTags = ({
	title,
	setEditOpen,
	row,
	setRows,
	handleClose,
	edit,
	setRow,
}) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const [active, setActive] = useState(row.active);
	const [filename, setFilename] = useState("");
	const [crop, setCrop] = useState(false);
	const [image, setImage] = useState(
		row.logo ? row.logo.file : null
	);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setRow({ ...row, [name]: value });
	};

	const handleOnChange = (event) => {
		const newImage = event.target?.files?.[0];
		console.log(newImage);
		if (newImage) {
			// console.log(newImage);
			setFilename(newImage.name);
			setImage(URL.createObjectURL(newImage));
			setCrop(true);
		}
	};

	const onSubmit = async (data) => {
		console.log(data);
		let formData = new FormData();
		for (let i in data) {
			if (i === "file") {
				console.log(i, data[i]);
				if (data[i].length) {
					let blob = await fetch(image).then((r) => r.blob());
					formData.append("file", blob, filename);
					// formData.append("file", data[i][0]);
				}
			} else {
				
				formData.append(i, data[i]);
				
			}
		}
		formData.append("active", active);
		
		edit? await editTag(formData,row.id):await addTag(formData);
		
		setRows();
		setEditOpen(false);
	};

	return (
		<div
			style={{
				width: 600,
				padding: 5,
				height: "100vh",
				overflowY: "scroll",
			}}
		>
			<IconButton style={{ position: "fixed" }} onClick={handleClose}>
				<Close />
			</IconButton>
			<div style={{ padding: "45px 55px 0px" }}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Box display="flex" justifyContent="space-between">
						<Typography variant="h6" gutterBottom>{`${
							edit ? "Edit" : "Add"
						} List category`}</Typography>
						<Button
							// onClick={handleSave}
							type="submit"
							color="primary"
							variant="contained"
						>
							{edit ? "Update" : "Save"}
						</Button>
					</Box>

					<Box my={3}>
						<TextField
							{...register("name", {
								required: "Enter List Category name",
							})}
							fullWidth
							label="Name"
							variant="outlined"
							value={row.name}
							onChange={handleInputChange}
							error={Boolean(errors.name)}
							helperText={errors.name?.message}
						/>
					</Box>
					<Box my={3} style={{ position: "relative" }}>
						<FormControl variant="outlined">
							<FormLabel component="legend">
								Active/InActive
							</FormLabel>
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											checked={active}
											onChange={(e) => {
												setActive((prev) => !prev);
											}}
											name=""
										/>
									}
									label="Active"
								/>
							</FormGroup>
						</FormControl>
					</Box>
					<Box my={3}>
						<FormControl error={Boolean(errors.file)}>
							{image && (
								<Avatar
									variant="square"
									src={image}
									sx={{
										width: 150,
										height: 150,
										objectFit: "fill",
										marginBottom: 2,
									}}
								/>
							)}
							<Button
								variant="contained"
								component="label"
								onChange={handleOnChange}
							>
								{!image ? "Upload " : "Change "}image
								<input
									hidden
									accept="image/*"
									id="file-upload"
									type="file"
									{...register("file", {
										// required: image == null,
									})}
									error={errors.file}
								/>
							</Button>

							{Boolean(errors.file) && (
								<FormHelperText>Image required</FormHelperText>
							)}
						</FormControl>
					</Box>

				</form>
				<ImageCropper
				image={image}
				setImage={setImage}
				open={crop}
				setOpen={setCrop}
				aspect={1}
			/>
			</div>
		</div>
	);
};
