import { Box, Button, Link, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getUserShareDetails } from "../../../apis/Api";


function UserCustomeCellData({ row, filter}) {
  const [displaying, setDisplaying] = useState(false);  
  

  const getProductDetails = async () => {   
    setDisplaying(true)
    console.log("hello")
  };
  return (
    <>
      <Stack direction="row" justifyContent="center">
        <Box sx={{paddingX:2}}>       
            <Typography >
            {row.total_share}           
            </Typography>         
        </Box>        
      </Stack>
      <Button onClick={getProductDetails} variant="text">View details</Button>
 
			<Box sx={{display:displaying? "block":"none", marginTop:"15px"}}>				
									
      {row.shared_by?.map((p) => {
       
        return (         
            <Box sx={{textAlign:"center"}}>
             
              <Typography variant="body2" sx={{ textAlign: "center" }}>
             {p}
              </Typography>          
          </Box>
        );      
        })}  
      </Box>
    </>
  );
}

export default UserCustomeCellData;
