import { createTheme } from "@mui/material";
import { pink } from "@mui/material/colors";

export const DarkTheme=createTheme({
    
    palette: {
        // mode: 'dark',
        primary: {
            main: "#003B6D",
            contrastText: "#fff",
        },
        
        secondary: pink,
       
    },
    typography: {
		fontFamily: "'DM Sans',sans-serif",
		h1: {
			fontWeight: 700,
			fontSize: "26px",
			lineHeight: "28px",
			marginY:1
		},
		h2: {
			fontWeight: 700,
			fontSize: "24px",
			lineHeight: "26px",
			marginY:1
		},
		h2_new:{
			fontWeight: 700,
			fontSize: "22px",
			lineHeight: "26px",
		},
		h3: {
			fontWeight: 700,
			fontSize: "20px",
			lineHeight: "24px",
			marginY:1
		},
		h4: {
			fontWeight: 700,
			fontSize: "18px",
			lineHeight: "24px",
			marginY:1
		},
        h6:{
            marginBottom:1
        },
		body1: {
			fontWeight: 700,
			fontSize: "16px",
			lineHeight: "22px",
		},
		body2: {
			fontWeight: 500,
			fontSize: "14px",
			lineHeight: "18px",
		},
		smallText: {
			fontWeight: 500,
			fontSize: "12px",
			lineHeight: "16px",
			fontFamily: "'DM Sans',sans-serif",
		},
		smallText2: {
			fontWeight: 700,
			fontSize: "10px",
			lineHeight: "12px",
			letterSpacing: "1px",
		},
		button: {
			fontWeight: 700,
			fontSize: 12,
			lineHeight: 1.88,
			letterSpacing: "0.5px",
			textTransform: "uppercase",
			borderRadius: "8px",
		},
		atag: {
			fontFamily: "Inter",
			fontStyle: "normal",
			fontHight: 700,
			fontSize: "14px",
			lineHeight: "18px",
			color: "#9650FA",
		},
		newHeading: {
			fontSize: "22px",
			lineHeight: "26px",
			fontWeight: "700",
			textTransform: "capitalize",
			letterSpacing: "-0.3px",
		},
	},

    components: {
		MuiTypography: {
			defaultProps: {
			  variantMapping: {
				h1: 'h1',
				h2: 'h2',
				h3: 'h3',
				h4: 'h4',
				h5: 'h5',
				h6: 'h6',
				
			  },
			},
		  },
        MUIDataTablePagination: {
            styleOverrides: {
                root: {
                    "& p": {
                        margin: 0,
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    "& span": {
                        justifyContent: "center",
                        fontWeight: 600,
                        width: "100%",
                    },
                    "& span button": {
                        justifyContent: "center",
                        fontWeight: 600,
                    },
                    fontWeight: 600,
                    backgroundColor: "rgba(0, 0, 0, 0.04) !important",
                    borderBottom: `2px solid rgba(224, 224, 224, 1)`,
                    textAlign: "center",
                },
                body: {
                    fontSize: 14,
                },
            },
        },
    },

})