import React, { useEffect, useState } from "react";
import { Category, Close } from "@mui/icons-material";

import {
	Autocomplete,
	Avatar,
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	IconButton,
	OutlinedInput,
	TextField,
	Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";

import { ToastContainer, toast } from "react-toastify";
import {
	Create,
	getAllCategory,
	getMasterCategory,
	UpdateCategoty,
} from "../../apis/ProductCategory";
import { ProductCategoryAccordion } from "../Menu/SubmenuItem";
import { Stack } from "@mui/system";
import { ImageCropper } from "../CommonFunctions/ImageCropper";

export const AddCategoryDrawer = ({
	rows,
	setAddOpen,
	setRows,
	category,
	setCategory,
	edit,
	reloadTable
}) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	

	const [showMasterCategory, setShowMasterCategory] = useState(
		edit ? category.master !== null : true
	);
	const [showCategory, setShowCategory] = useState(false);
	const [crop, setCrop] = useState(false);
	const [image, setImage] = useState(
		category.logo ? category.logo.file : null
	);
	const [filename, setFilename] = useState("");
	const [masterCategory, setMasterCategory] = useState(category.master);
	const [categories, setCategories] = useState([]);
	const handleChange = (event) => {
		setShowMasterCategory(!event.target.checked);
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setCategory({ ...category, [name]: value });
	};

	const handleOnChange = (event) => {
		const newImage = event.target?.files?.[0];
		console.log(newImage);
		if (newImage) {
			// console.log(newImage);
			setFilename(newImage.name);
			setImage(URL.createObjectURL(newImage));
			setCrop(true);
		}
	};
	useEffect(() => {
		let get_categories = async () => {
			let categories = await getMasterCategory(1000, 1);
			setCategories(categories.results);
		};
		get_categories();
	}, []);

	const onSubmit = async (data) => {
		console.log(data);
		let formData = new FormData();
		for (let i in data) {
			if (i === "file") {
				console.log(i, data[i]);
				if (data[i].length) {
					let blob = await fetch(image).then((r) => r.blob());
					formData.append("file", blob, filename);
					// formData.append("file", data[i][0]);
				}
			} else {
				formData.append(i, data[i]);
			}
		}
		// if (formData.file === undefined) formData.delete("file");

		if (showMasterCategory) {
			if (masterCategory == null) {
				setShowCategory(true);
				notify("Please select Master Product Category");
				return;
			} else {
				formData.append("master", masterCategory.id);
				data.master = masterCategory.id;
			}
		}
		// if (masterCategory !== null) {
		// 	console.log("yes");
		// 	formData.append("master", masterCategory.id);
		// 	data.master = masterCategory.id;
		// }

		console.log(data,formData);

		let res = edit
			? await UpdateCategoty(formData, category.id)
			: await Create(formData);
		if (res.success) {
			reloadTable()
			setAddOpen(false);
		}
	};

	const notify = (m) =>
		toast.error(m, {
			position: "top-right",
			autoClose: 2500,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});

	// const handleURL = async (e) => {

	// 	setUrl(e.target.value);
	// 	if(e.target.value){
	// 	const data = await getProductPreview(e.target.value);
	// 	setProductPreview(data.data);
	// 	}
	// }

	return (
		<div
			style={{
				width: 600,
				padding: 5,
				height: "100vh",
				overflowY: "scroll",
			}}
		>
			<IconButton
				style={{ position: "fixed" }}
				onClick={() => setAddOpen(false)}
			>
				<Close />
			</IconButton>
			<ToastContainer />
			<div style={{ padding: "45px 55px 0px" }}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Box display="flex" justifyContent="space-between">
						<Typography variant="h6" gutterBottom>
							{edit ? "Edit" : "Add"} Category
						</Typography>
						<Button
							type="submit"
							// onClick={handleSave}
							color="primary"
							variant="contained"
						>
							{"Save"}
						</Button>
					</Box>

					<Box my={3}>
						<TextField
							fullWidth
							name="name"
							{...register("name", {
								required: "Enter Category name",
							})}
							// style={{ width: "100%" }}
							label="Enter Category Name"
							variant="outlined"
							value={category ? category.name : ""}
							onChange={handleInputChange}
							error={Boolean(errors.name)}
							helperText={errors.name?.message}
						/>
					</Box>
					<Box my={2}>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={!showMasterCategory}
										inputProps={{
											"aria-label": "controlled",
										}}
										size="small"
										onChange={handleChange}
									/>
								}
								label="Master Product Category"
							/>
						</FormGroup>
					</Box>
					{showMasterCategory && (
						<Box sx={{ p: 1, border: '1px solid rgba(0, 0, 0, 0.23)',borderRadius:"4px" }}>
							<Stack
								direction={"row"}
								justifyContent="space-between"
								alignItems={"center"}
							>
								<Typography
									variant="h6"
									component={"h4"}
									onClick={() => {
										setShowCategory(true);
									}}
								>
									{masterCategory?.name}
								</Typography>
								<Button
									variant="text"
									onClick={() => {
										setShowCategory(true);
									}}
								>
									{masterCategory ? "Change" : "Select"}{" "}
									Master Category
								</Button>
							</Stack>

							{/* <Controller
								control={control}
								name="master"
								rules={{ required: true }}
								render={({ field: { onChange, value } }) => (
									<Autocomplete
										isOptionEqualToValue={(option, value) =>
											option.id === value.id
										}
										options={options.sort(
											(a, b) =>
												-b.groupBy.localeCompare(
													a.groupBy
												)
										)}
										onChange={(event, item) => {
											onChange(item);
										}}
										value={value || null}
										getOptionLabel={(item) =>
											item.name ? item.name : ""
										}
										groupBy={(option) => option.groupBy}
										renderOption={(props, option) => {
											return (
												<li
													{...props}
													key={`cat-${option.id}`}
												>
													{option.name}
												</li>
											);
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												// {...register("master", {
												// 	required:
												// 		"please select Master Product Category",
												// })}
												label="Master Product Category"
												// variant="outlined"
												error={errors.master}
												helperText={
													errors.master &&
													"please select Master Product Category"
												}
											/>
										)}
									/>
								)}
							/> */}
						</Box>
					)}
					<Box my={3}>
						<FormControl error={Boolean(errors.file)}>
							{image && (
								<Avatar
									variant="square"
									src={image}
									sx={{
										width: 150,
										height: 150,
										objectFit: "fill",
										marginBottom: 2,
									}}
								/>
							)}
							<Button
								variant="contained"
								component="label"
								onChange={handleOnChange}
							>
								{!image ? "Upload " : "Change "}image
								<input
									hidden
									accept="image/*"
									id="file-upload"
									type="file"
									{...register("file", {
										// required: image == null,
									})}
									error={errors.file}
								/>
							</Button>

							{Boolean(errors.file) && (
								<FormHelperText>Image required</FormHelperText>
							)}
						</FormControl>
					</Box>
				</form>
			</div>
			<ProductCategoryAccordion
				open={showCategory}
				setOpen={setShowCategory}
				categories={categories}
				setCategory={setMasterCategory}
			></ProductCategoryAccordion>
			<ImageCropper
				image={image}
				setImage={setImage}
				open={crop}
				setOpen={setCrop}
				aspect={1}
			/>
		</div>
	);
};
