export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_START = "REGISTER_START";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const SETTOKEN = "SETTOKEN";

export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";