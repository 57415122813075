import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "tss-react/mui";
import {
	ChevronLeft,
	ChevronRight,
	DashboardOutlined,
	LocalMallOutlined,
	Loyalty,
	Store,
	Category,
	ShoppingBasket,
	PeopleAlt,
} from "@mui/icons-material";

import { alpha, CssBaseline, Drawer, IconButton, List } from "@mui/material";

import { ThemeProvider, createTheme } from "@mui/material/styles";

import { Logo } from "./Logo";
import { Navbar } from "./Navbar";

import { pink } from "@mui/material/colors";

import AppMenuItem from "./AppMenuItem";
import { getCurrentUser, getToken } from "../store/authentication/service";
import { Navigate, Outlet } from "react-router";
import {  appMenuItemsForRestrictedUser } from "../utils/routes";

import { DarkTheme } from "../themes/darkTheme"

const theme = createTheme({
	...DarkTheme,
	
	palette: {
		
		background: {
			default: "#141d35",
			paper: "#161c2e",
			divider: "#1a2b4c",
		},
		mode: "dark",
	},
});

const drawerWidth = 280;

const useStyles = makeStyles()((theme) => ({
	root: {
		display: "flex",
		fontFamily: "inherit",
	},
	hide: {
		display: "none",
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: "nowrap",
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: "hidden",
		width: theme.spacing(8),
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing(10),
		},
	},
	toolbar: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	selected: {
		backgroundColor: alpha(theme.palette.primary.light, 0.25),
	},
	content: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		height: "100vh",
	},
}));

export const PrivateLayoutForRestrictedUser = ({ children }) => {
	const token = getToken();
	const currentUser = getCurrentUser()
	const { classes } = useStyles();
	const [open, setOpen] = useState(false);

	const [searchText, setSearchText] = useState("");

	const handleDrawerToggle = () => {
		setOpen((prev) => !prev);
	};	
	
	if (!token) {
		return <Navigate to="/auth/login" />;
	}
	
	
	

	return (
		<div className={classes.root}>
			<CssBaseline />

			<ThemeProvider theme={theme}>
				<Drawer
					variant="permanent"
					className={clsx(classes.drawer, {
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					})}
					classes={{
						paper: clsx({
							[classes.drawerOpen]: open,
							[classes.drawerClose]: !open,
						}),
					}}
				>
					<Logo />
					<div className={classes.toolbar}>
						<IconButton
							color="primary"
							onClick={handleDrawerToggle}
						>
							{open ? <ChevronLeft /> : <ChevronRight />}
						</IconButton>
					</div>
					<List
						component="nav"
						className={classes.appMenu}
						disablePadding
					>
						{appMenuItemsForRestrictedUser.map((item, index) => (
							<AppMenuItem {...item} key={index} />
						))}
					</List>
				</Drawer>
			</ThemeProvider>
			<main className={classes.content}>
				<Navbar
					user={null}
					searchText={searchText}
					setSearchText={setSearchText}
				/>

				<Outlet />
			</main>
		</div>
	);
};
