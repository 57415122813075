import { useEffect, useState } from "react";

import { makeStyles } from "tss-react/mui";
import _ from "lodash";
import { ToastContainer, toast } from "react-toastify";

import { Controller, useForm } from "react-hook-form";
import {
	Box,
	Button,
	Typography,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
	Grid,
	IconButton,
	Stack,
	Switch,
	FormLabel,
} from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { getAllPlayoffs, getPlayoffDetails } from "../../apis/Playoff";
import { Create, getAllActivePlayoffs, getAllUser } from "../../apis/Curation";
import Grouplist from "../../components/Curation/Grouplist";
import SingleList from "../../components/Curation/SingleList";

const useStyles = makeStyles()((theme) => ({
	title: {
		flexGrow: 1,
		display: "none",
		[theme.breakpoints.up("sm")]: {
			display: "block",
		},
	},
}));

export const AddCuration = ({ searchText }) => {
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [isGrouped, setIsGrouped] = useState(false);
	const [products, setProducts] = useState([]);
	const [group, setGroup] = useState([]);
	const [startDate, setStartDate] = useState();
	const [playoffs, setPlayoffs] = useState([]);
	const [selectedPlayoff, setSelectedPlayoff] = useState(null);
	const [users, setUsers] = useState([]);
	const [singleList, setSingleList] = useState([]);
	const [singleListQuantity, setSingleListQuantity] = useState(0);
	const [groupList, setGroupList] = useState([]);

	useEffect(() => {
		setLoading(true);
		_.debounce(async () => {
			let users = await getAllUser();
			setUsers(users);
			let playoffs = await getAllActivePlayoffs();
			setPlayoffs(playoffs);
		}, 500)();
		setLoading(false);
	}, []);

	const {
		register,
		handleSubmit,
		setValue,
		control,

		formState: { errors },
	} = useForm();

	const notify = (m) =>
		toast.error(m, {
			position: "bottom-right",
			autoClose: 2500,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});

	const onSubmit = async (data) => {
		if (!products.length) {
			notify("Please Add products");
			return;
		}
		data["grouped_list"] = isGrouped;

		data["products"] = products.map((g) => g);
		console.log(data);
		let res = await Create(data);
		console.log("====================================");
		console.log(res);
		console.log("====================================");
		if (res.success) {
			navigate("/w86zjruk6q/curation");
		}
	};

	const handlePlayOffChange = async(e) => {

		let selectedPlayoff = await getPlayoffDetails(e.target.value);
		setSelectedPlayoff(selectedPlayoff);
		setValue(
			"creation_date_time",
			selectedPlayoff.play_off_start_date_time
		);
		setStartDate(selectedPlayoff.play_off_start_date_time);
		setIsGrouped(selectedPlayoff.grouped_list);
		setSingleList(selectedPlayoff.products);
		setSingleListQuantity(selectedPlayoff.qty_limit);
		setGroupList(selectedPlayoff.groups);
		setProducts([]);
	};

	return (
		<Grid container justifyContent="center" spacing={2}>
			<ToastContainer />
			<Grid item xs={12} md={8}>
				<Box sx={{ px: 5 }}>
					<div style={{ padding: "45px 55px 0px" }}>
						<form onSubmit={handleSubmit(onSubmit)}>
							<Box display="flex" justifyContent="space-between">
								<Typography variant="h6" gutterBottom>
									Add Curation
								</Typography>
							</Box>

							<Box my={3}>
								<TextField
									fullWidth
									name="title"
									label="Enter Curation title"
									variant="outlined"
									error={Boolean(errors.title)}
									helperText={errors.title?.message}
									{...register("title", {
										required: "Enter List name",
									})}
								/>
							</Box>

							<Box my={3}>
								<Grid container spacing={2}>
									<Grid item md={6} xs={12}>
										<FormControl
											fullWidth
											error={Boolean(errors.owner_id)}
										>
											<InputLabel htmlFor="user">
												User
											</InputLabel>
											<Select
												{...register("owner_id", {
													required: "Select User",
												})}
												input={
													<OutlinedInput label="User" />
												}
												defaultValue={""}
											>
												{users.map((user, i) => {
													return (
														<MenuItem
															key={`user-${i}`}
															value={user.id}
														>
															{user.name}
														</MenuItem>
													);
												})}
											</Select>

											<FormHelperText>
												{errors.owner_id &&
													errors.owner_id?.message}
											</FormHelperText>
										</FormControl>
									</Grid>
									<Grid item md={6} xs={12}>
										<FormControl
											fullWidth
											error={Boolean(errors.playoff)}
										>
											<InputLabel htmlFor="playoff">
												Playoff
											</InputLabel>
											<Select
												{...register("playoff", {
													required: "Select Playoff",
												})}
												input={
													<OutlinedInput label="Playoff" />
												}
												defaultValue={""}
												onChange={handlePlayOffChange}
											>
												{playoffs.map((playoff, i) => {
													return (
														<MenuItem
															key={`playoff-${i}`}
															value={playoff.id}
														>
															{playoff.title}
														</MenuItem>
													);
												})}
											</Select>

											<FormHelperText>
												{errors.playoff &&
													errors.playoff?.message}
											</FormHelperText>
										</FormControl>
									</Grid>
								</Grid>
							</Box>
							<Box my={3}>
								<Grid container spacing={2}>
									<Grid item md={6}>
										<LocalizationProvider
											dateAdapter={AdapterDayjs}
										>
											<Controller
												control={control}
												name="creation_date_time"
												defaultValue={null}
												rules={{
													required:
														"please select the creation date and time",
													validate: {
														// min: (date) => {
														//   return (
														//     date.isAfter(dayjs()) ||
														//     "Please, enter a future date"
														//   );
														// },
													},
												}}
												render={({
													field: {
														ref,
														onBlur,
														onChange,
														name,
														...field
													},
													fieldState,
												}) => (
													<DateTimePicker
														{...field}
														onChange={(
															newValue
														) => {
															setStartDate(
																newValue
															);
															console.log(
																newValue.format()
															);
															onChange(newValue);
														}}
														inputRef={ref}
														label="Creation date & time"
														minDateTime={dayjs()}
														renderInput={(
															inputProps
														) => (
															<TextField
																fullWidth
																{...inputProps}
																onBlur={onBlur}
																name={name}
																error={
																	!!fieldState.error
																}
																helperText={
																	fieldState
																		.error
																		?.message
																}
															/>
														)}
													/>
												)}
											/>
										</LocalizationProvider>
									</Grid>
									<Grid item md={6}>
										<Box
											sx={{
												paddingX: 2,
												paddingY: 0.75,
												border: "1px solid rgba(0, 0, 0, 0.23)",
												borderRadius: "4px",
											}}
										>
											<Stack
												direction={"row"}
												justifyContent="space-between"
												alignItems={"center"}
											>
												<FormLabel>Active</FormLabel>
												<Switch
													type="checkbox"
													{...register("active", {})}
													defaultChecked
												/>
											</Stack>
										</Box>
									</Grid>
								</Grid>
							</Box>
							{!isGrouped && (
								<Box my={3}>
									<SingleList
										singleList={singleList}
										setProducts={setProducts}
										products={products}
										quantity_limit={singleListQuantity}
									/>
								</Box>
							)}
							{isGrouped && (
								<Box my={3}>
									<Grouplist
										groupList={groupList}
										setGroup={setGroup}
										setProducts={setProducts}
										products={products}
									></Grouplist>
								</Box>
							)}

							<Box mb={3}>
								<Button
									type="submit"
									fullWidth
									// onClick={handleSave}
									color="primary"
									variant="contained"
								>
									{"Save"}
								</Button>
							</Box>
						</form>
					</div>
				</Box>
			</Grid>
		</Grid>
	);
};
