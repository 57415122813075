import { useEffect, useState } from "react";

import { makeStyles } from "tss-react/mui";
import { Add } from "@mui/icons-material";




import _ from "lodash";
import { ToastContainer, toast } from "react-toastify";
import { Create, getAllSeller, getStylistUsers } from "../../apis/GrapeList";
import { Controller, useForm } from "react-hook-form";
import {
	Box,
	Button,
	Drawer,
	Toolbar,
	Typography,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Radio,
	RadioGroup,
	Select,
	TextField,
	Grid,
	Avatar,
} from "@mui/material";


import { AddProductDrawer } from "../../components/GrapeList/AddProductDrawer";
import { useNavigate } from "react-router-dom";
import { getTags } from "../../apis/Tags";

const useStyles = makeStyles()((theme) => ({
	title: {
		flexGrow: 1,
		display: "none",
		[theme.breakpoints.up("sm")]: {
			display: "block",
		},
	},
}));

export const AddGrapeList = ({ searchText }) => {
	const navigate = useNavigate();
	const { classes } = useStyles();

	const [editOpen, setEditOpen] = useState(false);
	const [title, setTitle] = useState("Add");

	const [loading, setLoading] = useState(false);

	const [listOptions, setListOptions] = useState([
		{
			value: "grape_list",
			title: "Grape List",
		},
		{
			value: "stylist_list",
			title: "Stylist List",
		},
	]);
	const [showStylelist, setShowStylelist] = useState(false);
	const [stylelist, setStylelist] = useState([]);
	const [list, setList] = useState([]);
	const [products, setProducts] = useState([]);

	useEffect(() => {
		setLoading(true);
		_.debounce(async () => {
			let users = await getStylistUsers();
			setStylelist(users);
			let tags = await getTags();
			console.log("====================================");
			console.log(tags);
			console.log("====================================");
			setList(tags.results);
		}, 500)();
		setLoading(false);
	}, []);

	const handleAdd = () => {
		setTitle("Add");

		setEditOpen(true);
	};

	const handleEdit = (i) => {
		setTitle("Edit");

		setEditOpen(true);
	};

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm();
	const notify = () =>
		toast.error(`Please select Products`, {
			position: "bottom-right",
			autoClose: 2500,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	const onSubmit = async (data) => {
		console.log(data);
		if (!data.products) {
			setEditOpen(true);
			notify("Please select Products");
			return;
		}

		// let formData = new FormData();
		let formData = new FormData();
		let pproducts;
		if (Array.isArray(data["products"])) {
			pproducts = data["products"].map((p) => parseInt(p));
		} else {
			pproducts = parseInt(data["products"]);
		}
		if (!data.owner) {
			const user = JSON.parse(localStorage.getItem("user"));
			data.owner = user.id;
		}
		for (let i in data) {
			if (i === "image") {
				formData.append(i, data[i][0]);
			} else formData.append(i, data[i]);
		}
		formData["products"] = [pproducts];
		formData["list_category"] = [data["list_category"]];
		let res = await Create(formData);
		if (res.success) {
			navigate("/w86zjruk6q/grapelist");
			// 	let newSellers = await getAllSeller();
			// 	setRows(newSellers.results);
			// 	setEditOpen(false);
		}
	};

	return (
		<>
			<Grid container justifyContent="center" spacing={2}>
				<ToastContainer />
				<Grid item xs={12} md={8}>
					<Box sx={{ px: 5 }}>
						<div style={{ padding: "45px 55px 0px" }}>
							<form onSubmit={handleSubmit(onSubmit)}>
								<Box
									display="flex"
									justifyContent="space-between"
								>
									<Typography variant="h6" gutterBottom>
										Add GrapeList
									</Typography>
								</Box>

								<Box my={3}>
									<TextField
										fullWidth
										name="name"
										// style={{ width: "100%" }}
										label="Enter List Name"
										variant="outlined"
										// value={seller ? seller.name : ""}
										error={Boolean(errors.title)}
										helperText={errors.title?.message}
										{...register("title", {
											required: "Enter List name",
										})}
									/>
								</Box>

								<Box my={3}>
									<TextField
										fullWidth
										name="description"
										multiline
										rows={4}
										// style={{ width: "100%" }}
										label="Enter description"
										variant="outlined"
										{...register("description", {
											required: "Enter description",
										})}
										error={Boolean(errors.description)}
										helperText={errors.description?.message}
									/>
								</Box>
								{/* <Box my={3}>
									<FormControl
										error={Boolean(errors.image)}
										fullWidth
									>
										<OutlinedInput
											type="file"
											id="file-upload"
											name="image"
											fullWidth
											accept="image/*"
											{...register("image", {
												required: "Image required",
											})}
											error={Boolean(errors.image)}
										/>
										{errors.image && (
											<FormHelperText>
												{errors.image?.message}
											</FormHelperText>
										)}
									</FormControl>
								</Box> */}
								<Box my={3}>
									<FormControl
										required
										error={Boolean(errors.list_category)}
										// component="outlined"
										// sx={{ m: 3 }}
										variant="outlined"
									>
										<FormLabel>List Category</FormLabel>
										<FormGroup row>
											{list.map((list) => {
												return (
													<FormControlLabel
														control={
															<Checkbox
																{...register(
																	"list_category",
																	{
																		required:
																			"Please Pick atleast one",
																	}
																)}
																value={list.id}
																// onChange={handleChange}
																name="list_category"
															/>
														}
														label={list.name}
													/>
												);
											})}
										</FormGroup>
										<FormHelperText>
											{errors.list_category?.message}
										</FormHelperText>
									</FormControl>
								</Box>

								<Box my={3}>
									<FormControl
										error={Boolean(errors.list_type)}
									>
										<FormLabel id="demo-row-radio-buttons-group-label">
											List Type
										</FormLabel>
										<RadioGroup
											row
											aria-labelledby="demo-row-radio-buttons-group-label"
											name="list_type"
										>
											{listOptions.map((option) => (
												<FormControlLabel
													value={option.value}
													control={
														<Radio
															{...register(
																"list_type",
																{
																	required:
																		"Please select list",
																}
															)}
															onChange={(e) => {
																if (
																	e.target
																		.value ==
																	"stylist_list"
																) {
																	setShowStylelist(
																		true
																	);
																} else {
																	setShowStylelist(
																		false
																	);
																}
															}}
														/>
													}
													label={option.title}
												/>
											))}
										</RadioGroup>
										<FormHelperText>
											{errors.list_type &&
												errors.list_type?.message}
										</FormHelperText>
									</FormControl>
								</Box>
								<Box my={3}>
									{showStylelist && (
										<FormControl
											fullWidth
											error={Boolean(errors.owner)}
										>
											<InputLabel htmlFor="owner">
												Stylist
											</InputLabel>
											<Select
												{...register("owner", {
													required: "Select stylist",
												})}
												displayEmpty
												input={
													<OutlinedInput label="Stylist" />
												}
												label="Stylist"
												// onChange={(e) => {
												// 	//setSelectedSeller(e.target.value);
												// }}
											>
												{stylelist.map((product, i) => {
													return (
														<MenuItem
															key={`seller-${i}`}
															value={product.id}
														>
															{product.name}
														</MenuItem>
													);
												})}
											</Select>
											<FormHelperText>
												{errors.owner &&
													errors.owner?.message}
											</FormHelperText>
										</FormControl>
									)}
								</Box>
								<Box
									mb={3}
									display="flex"
									justifyContent="space-between"
								>
									<Typography variant="h6" gutterBottom>
										Products
									</Typography>
									<Button
										type="button"
										onClick={handleAdd}
										color="primary"
										variant="contained"
									>
										{"Add Products"}
									</Button>
								</Box>
								{products.length > 0 && (
									<Box my={3}>
										<FormControl
											required
											error={Boolean(errors.products)}
											variant="outlined"
										>
											<FormLabel>Product List</FormLabel>
											<FormGroup row>
												{products.map((list) => {
													list.checked = true;
													return (
														<FormControlLabel
															checked={
																list.checked
															}
															control={
																<Checkbox
																	{...register(
																		"products",
																		{
																			required:
																				"Please Pick atleast one",
																		}
																	)}
																	value={
																		list.id
																	}
																	onChange={(
																		e
																	) => {
																		let tempProducts =
																			products.filter(
																				(
																					p
																				) =>
																					!p.id ===
																					parseInt(
																						e
																							.target
																							.value
																					)
																			);

																		setProducts(
																			tempProducts
																		);
																	}}
																	name="products"
																/>
															}
															label={
																<Box
																	sx={{
																		display:
																			"inline-flex",
																	}}
																>
																	<Avatar
																		sx={{
																			mx: 2,
																		}}
																		alt="Seller Logo"
																		src={
																			list
																				.image
																				.file
																		}
																	>
																		S
																	</Avatar>
																	{list.name}
																</Box>
															}
														/>
													);
												})}
											</FormGroup>
											<FormHelperText>
												{errors.products?.message}
											</FormHelperText>
										</FormControl>
									</Box>
								)}
								<Box mb={3}>
									<Button
										type="submit"
										fullWidth
										// onClick={handleSave}
										color="primary"
										variant="contained"
									>
										{title === "Edit" ? "Update" : "Save"}
									</Button>
								</Box>
							</form>
						</div>
						<Drawer
							anchor="right"
							open={editOpen}
							onClose={() => setEditOpen(false)}
						>
							<AddProductDrawer
								setEditOpen={setEditOpen}
								products={products}
								setProducts={setProducts}
							/>
						</Drawer>
					</Box>
				</Grid>
			</Grid>
		</>
	);
};
