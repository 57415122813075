import axios from "axios";
import { BaseURLwithEndPoint } from "./config";
import { axiosInstance } from "../config/axios";

export const base = process.env.REACT_APP_BASE_URL
const handlerEnabled = true

const ex = "/web/";
// console.log("ex", ex)

// const userToken = localStorage.getItem('token');
// if (userToken) {
//     axios.defaults.headers.common['Authorization'] = "Bearer " + userToken
// } else {
//     axios.defaults.headers.common['Authorization'] = null;
// }
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
export const setAxiosAuthorization = (token) => {
	axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
	axios.defaults.xsrfCookieName = "csrftoken";
	axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

axios.interceptors.response.use(null, function (error) {
	if (error.response && error.response.status === 401) {
		console.log("Error", error);
		localStorage.removeItem("token");
		localStorage.removeItem("user");
		window.location.href = window.location.origin + "/w86zjruk6q";
	}
});

export const getAnalytics = async (input) => {
	const api = "analytics/";
	const query = input
		? `start_time=${new Date(input.start).getTime()}&end_time=${new Date(
				input.end
		  ).getTime()}&query_type=${input.type.toLowerCase()}`
		: "start_time=" + (new Date().getTime() - 1000 * 60 * 60 * 24 * 14);
	// const res = await fetch(base + ex + api + '?' + query, { headers: { 'Authorization': "Bearer " + access_token }});
	// const data = await res.json()
	// console.log(data)
	// return data
	const data = await axios
		.get(base + ex + api + "?" + query)
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			return [];
		});
	return data;
};

export const getRoles = async () => {
	const api = "get_web_roles/";
	// const res = await fetch(base + ex + api, { headers: { 'Authorization': "Bearer " + userToken }});
	// const data = await res.json()
	// return data
	const data = await axios
		.get(base + ex + api)
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			return [];
		});
	return data;
};

export const getProductPreview = async (url) => {
	const postData = { product_url: url };
	const api = "product-parser";
	const res = await axios
		.post(BaseURLwithEndPoint()+ api, postData)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			return [];
		});
	//const data = await res.json()
	return res.data;
};

export const getContentItem = async (id) => {
	const api = "get_content/";
	// const res = await fetch(base + ex + api + `?id=${id}`);
	// const data = await res.json()
	// return data[0];
	const data = await axios
		.get(base + ex + api + `?id=${id}`)
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			return [];
		});
	return data[0];
};

export const getContent = async (q, page, ts, id) => {
	const api = "get_content/";
	const query = `page_size=${page ?? 10}`;
	const marker = `&marker_ts=${ts}`;
	const marker2 = `&marker_id=${id}`;
	const search = `&search_key=${q}`;
	// const res = await fetch(base + ex + api + '?' + query + (ts ? marker : '') + (id ? marker2 : '') + (q ? search : ''));
	// const data = await res.json()
	// return data;
	const data = await axios
		.get(
			base +
				ex +
				api +
				"?" +
				query +
				(ts ? marker : "") +
				(id ? marker2 : "") +
				(q ? search : "")
		)
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			return [];
		});
	return data;
};

export const getCount = async (s, r) => {
	const api = "analytics_count/";
	const query = `start_time=0&search_key=${s}&resource=${r}`;
	// const res = await fetch(base + ex + api + '?' + query);
	// const data = await res.json()
	// return data;
	const data = await axios
		.get(base + ex + api + "?" + query)
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			return [];
		});
	return data;
};

export const getUsers = async (q) => {
	const api = "users/";
	const query = "mode=light";
	const search = `&search_key=${q}`;
	// const res = await fetch(base + ex + api + '?' + query + (q ? search : ''));
	// const data = await res.json()
	// return data;
	const data = await axios
		.get(base + ex + api + "?" + query + (q ? search : ""))
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			return [];
		});
	return data;
};

export const getWebUsers = async () => {
	const api = "get_web_users/";
	// const res = await fetch(base + ex + api);
	// const data = await res.json()
	// return data;
	const data = await axios
		.get(base + ex + api)
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			return [];
		});
	return data;
};

export const getFullUsers = async () => {
	const api = "createstylist/";
	const query = "mode=full";
	// const res = await fetch(base + ex + api + '?' + query);
	// const data = await res.json()
	// return data;
	const data = await axios
		.get(base + ex + api + "?" + query)
		.then((res) => {
			return res.data.results;
		})
		.catch((err) => {
			return [];
		});
	return data;
};

export const getProdTags = async () => {
	const api = "get_prod_tags/";
	// const res = await fetch(base + ex + api);
	// const data = await res.json()
	// return data;
	const data = await axios
		.get(base + ex + api)
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			return [];
		});
	return data;
};

export const getUserTypes = async () => {
	const api = "user_types";
	// const res = await fetch(base + ex + api);
	// const data = await res.json()
	// return data?.map(e => e["type_name"]);
	const data = await axios
		.get(base + ex + api)
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			return [];
		});
	return data?.map((e) => e["type_name"]);
};

export const getAllProducts = async (q, page, ts, id) => {
	const api = "get_product";
	const query = `page_size=${page ?? 10}`;
	const marker = `&marker_ts=${ts}`;
	const marker2 = `&marker_id=${id}`;
	const search = `&search_key=${q}`;
	// const res = await fetch(base + ex + api + '?' + query + (ts ? marker : '') + (id ? marker2 : '') + (q ? search : ''));
	// const data = await res.json()
	// return data;
	const data = await axios
		.get(
			base +
				ex +
				api +
				"?" +
				query +
				(ts ? marker : "") +
				(id ? marker2 : "") +
				(q ? search : "")
		)
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			return [];
		});
	return data;
};

export const getProducts = async (ids) => {
	if (ids) {
		const api = "get_product";
		const query = "product_id=" + ids.join(",");
		// const res = await fetch(base + ex + api + '?' + query);
		// const data = await res.json()
		// return data;
		const data = await axios
			.get(base + ex + api + "?" + query)
			.then((res) => {
				return res.data;
			})
			.catch((err) => {
				return [];
			});
		return data;
	}
};

export const login = async (details) => {
	const api = "api/token/";
	let url = base + ex + api;
	try {
		const res = await axios.post(url, details);
		return res.data;
	} catch (e) {
		return false;
	}
};

export const signup = async (details) => {
	const api = "create/";
	let url = base + ex + api;
	try {
		const res = await axios.post(url, details);
		return res.data;
	} catch (e) {
		return false;
	}
};

export const deleteContent = async (text) => {
	const api = "delete_content/";
	const query = "content_id=" + text;
	await axios.delete(base + ex + api + "?" + query);
};

export const addContent = async (data) => {
	const api = "post_content/";
	await axios.post(base + ex + api, data);
};

export const addProduct = async (data) => {
	const api = "post_product";
	const res = await axios.post(base + ex + api, data);
	return res.data;
};

export const addUser = async (data) => {
	const api = "createstylist/";
	const res = await axios.post(base + ex + api, data);
	return res.data;
};

export const addRole = async (data) => {
	const api = "create_web_roles/";
	const res = await axios.post(base + ex + api, data);
	return res.data;
};

export const deleteProduct = async (text) => {
	const api = "delete_product";
	const query = "product_id=" + text;
	const res = await axios.delete(base + ex + api + "?" + query);
	return res;
};

export const updateProduct = async (data) => {
	const api = "update_product";
	await axios.put(base + ex + api, data);
};

export const updateContent = async (data) => {
	const api = "post_content/";
	await axios.put(base + ex + api, data);
};

export const updateUser = async (data) => {
	const api = "register/";
	await axios.put(base + ex + api, data);
};

export const updateRole = async (data) => {
	const api = "update_web_roles/";
	await axios.put(base + ex + api, data);
};

export const uploadImages = async (images) => {
	let userToken = localStorage.getItem("token");
	let api = "upload_content_s3/";
	if (images[0].type[0] === "v") {
		api += "?file_type=video";
	} else {
		api += "?file_type=image";
	}
	const formData = new FormData();
	images.forEach((image) => formData.append("file", image));
	const res = await axios.post(base + ex + api, formData, {
		headers: {
			"Content-Type": "multipart/form-data",
			Authorization: "Bearer " + userToken,
		},
	});
	console.log(res);
	return res.data.map((e) => {
		return {
			url: e.url,
			url_m: e.url_m,
			url_s: e.url_s,
		};
	});
};

export const approveContent = async (data) => {
	const api = "approve_content/";
	await axios.post(base + ex + api, data);
};

export const filterContent = async (status) => {
	const api = "get_content/";
	const query = `page_size=10&approval_status=${status}`;
	// const res = await fetch(base + ex + api + '?' + query);
	// const data = await res.json()
	// return data;
	const data = await axios
		.get(base + ex + api + "?" + query)
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			return [];
		});
	return data;
};



export const getPrompt = async (a, page) => {
	const api = "prompt_lifecycle/";
	const pageSize = `&page_size=${page ?? 10}`;
	const query = `created_time=0&active_status=${a}` + pageSize;
	// const res = await fetch(base + ex + api + '?' + query);
	// const data = await res.json()
	// return data;
	const data = await axios
		.get(base + ex + api + "?" + query)
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			return [];
		});
	return data;
};

export const addUpdatePrompt = async (data) => {
	const api = "prompt_lifecycle/";
	const res = await axios.post(base + ex + api, data);
	return res.data;
};

export const checkUserHandle = async (userHandele) => {
	
	if (userHandele) {
		const api = `check_handle_availability/?user_handle=${userHandele}`;
		
		const res = await axios
			.get(base + ex + api)
			.then((res) => res)
			.catch((err) => err.response);
		// console.log("res", res)
		return !res.data.users_exist
	}
};

export const getBanners = async () => {
	const api = "get_banners/";
	// const res = await fetch(base + ex + api);
	// const data = await res.json()
	// return data;
	const data = await axios
		.get(base + ex + api)
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			return [];
		});
	return data;
};

export const addBanner = async (data) => {
	const api = "create_banner/";
	const res = await axios.post(base + ex + api, data);
	return res.data;
};
export const updateBanner = async (id, data) => {
	const api = "create_banner/?id=" + id;
	const res = await axios.post(base + ex + api, data);
	return res.data;
};

export const getReports = async (type, status, pageSize) => {
	const api = `report_user/?type=${type}&status=${status}&page_size=${pageSize}&query_type=details`;
	// const res = await fetch(base + ex + api);
	// const data = await res.json()
	// return data;
	const data = await axios
		.get(base + ex + api)
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			return [];
		});
	return data;
};

export const getReportsCounts = async (type, status) => {
	const api = `report_user/?type=${type}&status=${status}&query_type=count`;
	// const res = await fetch(base + ex + api);
	// const data = await res.json()
	// return data;
	const data = await axios
		.get(base + ex + api)
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			return [];
		});
	return data;
};

export const updateReport = async (data) => {
	const api = "report_user/";
	const res = await axios.post(base + ex + api, data);
	return res.data;
};

export const updateUserStatus = async (data) => {
	const api = "unblock_user/";
	const res = await axios.post(base + ex + api, data);
	return res.data;
};

export const getProductViewCount = async (filter,date) => {
	try {
		const res = await axiosInstance.get(`product-view-count/?filter=${filter}&date=${date}`);
		console.log("====================================");
		console.log(res);
		console.log("====================================");
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};
export const getCommunityFavouritesGraph = async (id,filter,date) => {
	try {
		const res = await axiosInstance.get(`community-favourites-graph/?filter=${filter}&date=${date}&id=${id}`);
		console.log("====================================");
		console.log(res);
		console.log("====================================");
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};
export const getUserShareGraph = async (filter,date) => {
	try {
		const res = await axiosInstance.get(`user-share-graph/?filter=${filter}&date=${date}`);
		console.log("====================================");
		console.log(res);
		console.log("====================================");
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};
export const getUserShareDetails  = async (filter,row,page=1) => {
	try {
		const res = await axiosInstance.get(`user-graph-share-details/`,{params:{filter:filter,page_size:row,page:page},handlerEnabled});
		console.log("====================================");
		console.log(res);
		console.log("====================================");
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};
export const getPlayoffShareGraph = async (filter,date) => {
	try {
		const res = await axiosInstance.get(`playoff-share-graph/?filter=${filter}&date=${date}`);
		console.log("====================================");
		console.log(res);
		console.log("====================================");
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};
export const getPlayoffShareGraphDetails  = async (filter,row,page=1) => {
	try {
		const res = await axiosInstance.get(`playoff-share-graph-details/`,{params:{filter:filter,page_size:row,page:page},handlerEnabled});
		console.log("====================================");
		console.log(res);
		console.log("====================================");
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};
export const getCurationShareGraph = async (filter,date) => {
	try {
		const res = await axiosInstance.get(`curation-share-graph/?filter=${filter}&date=${date}`);
		console.log("====================================");
		console.log(res);
		console.log("====================================");
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};
export const getCurationShareGraphDetails  = async (filter,row,page=1) => {
	try {
		const res = await axiosInstance.get(`curation-share-details/`,{params:{filter:filter,page_size:row,page:page},handlerEnabled});
		console.log("====================================");
		console.log(res);
		console.log("====================================");
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};

export const getCurationShareCountDetails  = async (filter,id) => {
	try {
		const res = await axiosInstance.get(`curation-share-count-details/`,{params:{filter:filter,id:id},handlerEnabled});

		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
			
		};
		
		return data;
	}
};
export const getGrapeListGraph = async (filter,date) => {
	try {
		const res = await axiosInstance.get(`grape-list-graph/?filter=${filter}&date=${date}`);
		console.log("====================================");
		console.log(res);
		console.log("====================================");
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};
export const getProductViewCountDetails = async (filter,row,page=1) => {
	try {
		const res = await axiosInstance.get(`product-view-count-details/`,{params:{filter:filter,page_size:row,page:page},handlerEnabled});
		console.log("====================================");
		console.log(res);
		console.log("====================================");
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};
export const getProductViewCountDetailsPL = async (id,filter,row,page=1) => {
	try {
		const res = await axiosInstance.get(`community-favourites-details/`,{params:{filter:filter,page_size:row,page:page, id:id},handlerEnabled});
		console.log("====================================");
		console.log(res);
		console.log("====================================");
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};
export const getGrapeListDetails = async (filter,row,page=1) => {
	try {
		const res = await axiosInstance.get(`grape-list-details/`,{params:{filter:filter,page_size:row,page:page},handlerEnabled});
		console.log("====================================");
		console.log(res);
		console.log("====================================");
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};

export const getPlayoffProductDetails = async (filter,id) => {
	try {
		const res = await axiosInstance.get(`playoffs-products-view-count/`,{params:{filter:filter,id:id},handlerEnabled});

		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};
export const getPersistentListProductDetails = async (filter,id) => {
	try {
		const res = await axiosInstance.get(`pl-products-view-count/`,{params:{filter:filter,id:id},handlerEnabled});

		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};
export const getGrapeListProductsCount = async (filter,id) => {
	try {
		const res = await axiosInstance.get(`grape-list-product-count/`,{params:{filter:filter,id:id},handlerEnabled});

		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};

export const getPlayoffShareCountDetails = async (filter,id) => {
	console.log('====================================');
	console.log(filter);
	console.log(id);
	console.log('====================================');
	try {
		const res = await axiosInstance.get(`playoff-share-count-details/`,{params:{filter:filter,id:id},handlerEnabled});

		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};