import { Grid } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { getUserCountDetails } from "../../apis/Playoff";

export const PlayoffDetails = () => {
    const [rowsCount, setRowsCount] = useState(10);
    const [data, setData] = useState([]);
	const [page, setPage] = useState(0);
	const [filters, setFilters] = useState({});
	const [sortingValues, setSortingValues] = useState(null);
	const [count, setCount] = useState(0);
    useEffect(() => {
		
		getUserCountDetails(rowsCount, page + 1,filters, sortingValues).then(
			(res) => {
				console.log('====================================');
				console.log(res);
				console.log('====================================');
				setData(res.results);
				setCount(res.count);
			}
		);
	}, [page, rowsCount, filters, sortingValues]);
    const columns = [
        { name: "curations__owner__name",
        label: "Curator", options: {filter: false,} },
        { label: "Playoff",name: 'title', options: {filter: false} },
        { label: "No of Curation",name: 'count', options: {filter: false} }
      ];
    const options = {
		jumpToPage: true,
		selectableRows: "none",
		// download: false,
		filterType: "multiselect",
		serverSide: true,
		print: false,
		count: count,
		page: page,
		rowsPerPage: rowsCount,
		rowsPerPageOptions: [10, 25, 100],
		onChangePage(currentPage) {
			setPage(currentPage);
		},
		onChangeRowsPerPage(numberOfRows) {
			setRowsCount(numberOfRows);
		},
		onSearchChange: (searchText) => {
			setFilters({ ...filters, title: searchText });
		},
		onColumnSortChange: (changedColumn, direction) => {
			setSortingValues(
				direction === "desc" ? `-${changedColumn}` : `${changedColumn}`
			);
			// setPage(0)
			// setSortFields(temp);
		},
		onFilterChange: (columnChanged, filterList, type, index) => {
			setPage(0);
			let value = filterList[index];
			let filterdata = {};
			let newar = [];
			// if (columnChanged === "brand") {
			// 	newar = brand
			// 		.filter(({ name }) => value.includes(name))
			// 		.map((play) => play.id);
			// 	console.log("====================================");
			// 	console.log(newar, value);
			// 	console.log("====================================");
			// 	filterdata[columnChanged] = newar;
			// 	setFilters({ ...filters, ...filterdata });
			// } else if (columnChanged === "seller") {
			// 	newar = seller
			// 		.filter(({ name }) => value.includes(name))
			// 		.map((play) => play.id);

			// 	filterdata[columnChanged] = newar;
			// 	setFilters({ ...filters, ...filterdata });
			// }
		},

		// onTableChange: (action, tableState) => {
		// 	switch (action) {
		// 		case "sort":
		// 			sortTable(tableState.page, tableState.sortOrder);
		// 			break;
		// 		default:
		// 			console.log("action not handled.");
		// 	}
		// },
	};
	return (
		<Grid container justifyContent="center" spacing={2} pt={5}>
			<ToastContainer />
			<Grid item xs={12} md={8}>
            <MUIDataTable
				title={"Playoff"}
				data={data}
				columns={columns}
				options={options}
			/>
            </Grid>
		</Grid>
	);
};
